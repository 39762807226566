import {
  formatError,
  getLocalStorageItem,
  setLocalStorageItem,
  removeLocalStorageItem,
  Logger,
} from '../../../../packages/logger/src';
import { WEBEX_STORAGE_KEYS, ERROR_RESPONSE_TEXT } from '../../../adapter-interfaces/src';

const webexInstance = require('../../../../packages/widgets/src/webex-js-sdk/webex');
let retryRegisterCount: number = 0;
const MAX_RETRY_COUNT = 5;
let initInprogress: boolean = false;
declare global {
  interface Window {
    webex: any;
    isWebexInitalized: boolean;
  }
}

window.isWebexInitalized = false;

const webexConfig = {
  config: {
    services: {
      discovery: {
        u2c: process.env.WEBEX_U2C_BASE_URL,
        hydra: process.env.HYDRA_SERVICE_URL,
      },
    },
    // Any other sdk config we need
  },
  credentials: {
    access_token: getLocalStorageItem<string>("webex_auth_token"),
  },
  logger: {
    level: process.env.WEBEX_LOG_LEVEL
  }
};

export function initWebex(accessToken: string) {
  Logger.info(
    'Authentication#initWebex()'
  );
  if (initInprogress) {
    Logger.info(
      'Authentication#initWebex() :: webex init already in-progress'
    );
    return;
  }

  initInprogress = true;

  if (accessToken) {
    webexConfig.credentials.access_token = accessToken;
  }
  window.webex = webexInstance?.init(webexConfig);
  window.webex?.once('ready', async () => {
    Logger.info(
      'Authentication#initWebex() :: Webex Ready'
    );
    await deviceRegister();
    window.isWebexInitalized = true;
  });
  initInprogress = false;
}

async function deviceRegister() {
  Logger.info(
    'Authentication#deviceRegister() :: initializing start.'
  );

  if (retryRegisterCount >= MAX_RETRY_COUNT) {
    setLocalStorageItem(WEBEX_STORAGE_KEYS.WEBEX_INSTANCE_ERROR, ERROR_RESPONSE_TEXT.WEBEX_INST_ERROR);
    window.dispatchEvent(new Event("storage"));
    Logger.error(`Authentication#deviceRegister() :: Max retry exceeded.`);
    return;
  }
  if (window.webex?.internal?.device?.registered) {
    //TODO:: do we need to reconnect mercury
    Logger.info(
      'Authentication#deviceRegister() :: already registered.'
    );
    return;
    //unregister()
  }
  retryRegisterCount++;
  await window.webex?.internal?.device?.register()
    .then(async () => {
      Logger.info(
        'Authentication#deviceRegister() :: successfully registered.'
      );
      await mercuryConnect();
    })
    .catch((error: any) => {
      Logger.error(`Authentication#deviceRegister() :: error registering: '${formatError(error)}'.`);
      if (window.webex?.internal?.device?.registered) {
        deviceRefresh();
      } else {
        deviceUnregister();
      }
    })
    .finally(() => {
      if (!(window.webex?.internal?.device?.registered)) {
        deviceRegister()
      } else {
        //SUCCESS :: removing the error warning on webex initalization
        removeLocalStorageItem(WEBEX_STORAGE_KEYS.WEBEX_INSTANCE_ERROR);
        window.dispatchEvent(new Event("storage"));
        retryRegisterCount = 0;
      }

    });
}

async function mercuryConnect() {
  if (window.webex?.internal?.mercury?.connected) {
    Logger.info(
      'Authentication#mercuryConnect() :: May need to disconnect'
    );
    return;
    //TODO:: do we need to reconnect
  }

  await window.webex?.internal?.mercury?.connect()
    .then(() => {
      Logger.info(
        'Authentication#mercuryConnect() :: successfully registered'
      );

    })
    .catch((error: any) => {
      Logger.error(`Authentication#mercuryConnect() :: error registering: '${formatError(error)}'.`);
      if (window.webex?.internal?.mercury?.connected) {
        Logger.error(`Authentication#mercuryConnect() :: may need to reconnect: `);
        //unregister()
      }
    })
    .finally(() => {
      if (!(window.webex?.internal?.mercury?.connected)) {
        Logger.error(`Authentication#mercuryConnect() :: may need to retry finally:`);
        //unregister()
      }

    });
}

async function deviceUnregister() {
  Logger.info(
    'Authentication#deviceUnregister()'
  );
  await window.webex?.internal?.device?.unregister()
    .then(() => {
      Logger.info(
        'Authentication#device#deviceUnregister() :: successfully unregistered'
      );

    })
    .catch((error: any) => {
      Logger.error(`Authentication#device#deviceUnregister() :: error unregistering: '${formatError(error)}'.`);
    })
    .finally(() => {
      Logger.info(
        `Authentication#deviceUnregister() :: is device registered ${window.webex?.internal?.device?.registered}`
      );
    });
}

async function deviceRefresh() {
  Logger.info(
    'Authentication#deviceRefresh()'
  );
  if (window.webex?.internal?.device?.registered) {
    //register()
    await window.webex?.internal?.device?.refresh()
      .then(() => {
        Logger.info(
          `Authentication#device#deviceRefresh() :: successfully device refreshed`
        );

      })
      .catch((error: any) => {
        Logger.error(`Authentication#device#deviceRefresh() :: device refresh failed: '${formatError(error)}'.`);
      })
      .finally(() => {

      });
  }
}