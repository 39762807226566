import React, { useEffect } from 'react';
import { LoadingSpinner } from '@momentum-ui/react-collaboration';
import { authStart } from '../ms-teams-sdk/service/MsTeamsAuthenticationService';
import './Auth.scss';

/**
 *
 */
export const AuthStart = () => {
  useEffect(() => {
    authStart();
  }, []);

  return (
    <div className="authorization-page">
      <LoadingSpinner scale={64} />
    </div>
  );
};
