import { app, HostClientType } from '@microsoft/teams-js';
import { useEffect, useState } from 'react';
import { getMSTeamsContext, getMSTeamsTheme } from '../ms-teams-sdk/util/MsTeamsContextUtil';
import { Logger } from '../packages/logger/src/Logger';
import registerOnThemeChangeHandler = app.registerOnThemeChangeHandler;

type WebexThemes = 'darkWebex' | 'lightWebex';

// TODO: What do we do for the high contrast theme?
//TODO: Waiting for figma for the Dark Theme UI, now by deafult showing lighttheme
const teamsToWebexTheme: { [key: string]: WebexThemes } = {
  default: 'lightWebex',
  dark: 'darkWebex',  
};

export const useMsTeamsTheme = () => {
  const [theme, setTheme] = useState<WebexThemes>('lightWebex');

  const setWebexTheme = (teamsTheme: string) => {
    Logger.debug(`Registered Ms Teams theme change to: ${teamsTheme}`);
    const webexTheme = teamsToWebexTheme[teamsTheme] || 'lightWebex';
    setTheme(webexTheme);
    if(teamsTheme === 'contrast') {
      const webexTheme = 'darkWebex';
      setTheme(webexTheme);
    }
    fethclientType();
  };
//Will be removing this fethclientType funtion once the theme changes support to the mobile UI
  const fethclientType = async () => {
    const context = await getMSTeamsContext().catch((error) => {
      Logger.error(error);
    });
    const clientType = context?.app?.host?.clientType;
    Logger.info(`clientType: ${clientType}`);
    if(clientType !== HostClientType.desktop && clientType !== HostClientType.web) {
      Logger.info(`Mobile client type`);
      const webexTheme = 'lightWebex';
      setTheme(webexTheme);
    }
  };

  useEffect(() => {
    getMSTeamsTheme().then((teamsTheme) => {
      setWebexTheme(teamsTheme);
    });
    registerOnThemeChangeHandler((newTheme) => setWebexTheme(newTheme));
  }, []);

  return [theme];
};
