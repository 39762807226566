import { Flex, Text } from '@momentum-ui/react-collaboration';
import React from 'react';
import useWebexClasses from '../hooks/useWebexClasses';

type Props = {
    noVoicemailMessage: string | undefined;
    style?: React.CSSProperties;
};

/**
 * Banner for displaying empty voice mails.
 *
 * @param {string} noVoicemailMessage The message to display to the user
 * @returns {React.Component} React component
 */
export const EmptyVoiceMails = ({ noVoicemailMessage, style = undefined, }: Props) => {
    const [cssClasses, sc] = useWebexClasses('voice-mails-empty');
    return (
        <Flex
            className={cssClasses}
            alignItems="center"
            justifyContent="center"
            direction="column"
            style={style}
        >
            <div className={sc('icon')}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="168"
                    height="156"
                    viewBox="0 0 170 170"
                    fill="none">
                    <path
                        d="M148.4 42.325L157.4 33.325C159.1 31.625 161.7 31.625 163.4 33.325C165.1 35.025 165.1 37.625 163.4 39.325L156.4 46.325L164.4 54.325C166.6 56.525 166.6 60.125 164.4 62.325C162.2 64.525 158.6 64.525 156.4 62.325L148.4 54.325L140.4 59.925C139.4 60.625 139.3 62.125 140.1 63.025L140.4 63.325C141.5 64.425 141.5 66.225 140.4 67.325C139.3 68.425 137.5 68.425 136.4 67.325L129.4 60.325C128.3 59.225 128.3 57.425 129.4 56.325C130.5 55.225 132.3 55.225 133.4 56.325L133.7 56.625C134.6 57.525 136 57.425 136.8 56.325L142.4 48.325L134.4 40.325C132.2 38.125 132.2 34.525 134.4 32.325C136.6 30.125 140.2 30.125 142.4 32.325L146.1 36.025M50 158.125C50 144.825 39.3 134.125 26 134.125C26 120.825 15.3 110.125 2 110.125M170 114.125C170 105.325 162.8 98.125 154 98.125C154 89.325 146.8 82.125 138 82.125M106 50.125C106 41.325 98.8 34.125 90 34.125C90 25.325 82.8 18.125 74 18.125M126.6 69.625L88.1 108.125C80.1 116.125 84.1 125.125 91.1 125.125C100.1 125.125 102.1 110.125 87.1 110.125C78.1 110.125 65.1 103.125 55.1 93.125C47.9 85.925 42.9 77.325 39.7 68.325C37.9 63.125 39.2 57.425 43.1 53.525L50.5 46.125C57.6 39.025 57.6 27.425 50.5 20.325L37.5 7.325C30.4 0.225 18.8 0.225 11.7 7.325L7.5 11.525C4.1 14.925 2.2 19.625 2.1 24.525C1.9 55.825 13.7 87.025 37.5 110.725C60.4 133.625 90.3 145.425 120.3 146.125C125.2 146.225 129.9 144.225 133.4 140.725L139.2 134.925C146.3 127.825 146.3 116.225 139.2 109.125L125.1 95.025"
                        stroke="url(#paint0_linear_729_58699)"
                        strokeWidth="2.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <defs>
                        <linearGradient
                            id="paint0_linear_729_58699"
                            x1="86"
                            y1="2"
                            x2="86"
                            y2="158.125"
                            gradientUnits="userSpaceOnUse">
                            <stop stopColor="#279BE8" />
                            <stop offset="1" stopColor="#875AE0" />
                        </linearGradient>
                    </defs>
                </svg>
            </div>
            <Text type="body-primary" data-color="secondary" className={sc('text')} style={{ marginTop: '44px', textAlign:'center', width:'44%' }}>
                {noVoicemailMessage}
            </Text>
        </Flex>
    );
};
