import { Logger, formatError } from '../../packages/logger/src/Logger';
import { ClientBuilderFactory } from '../remote/ClientBuilderFactory';

export interface IWebexUserSessionPerson {
  id: string;
  ownerId?: string;
  name: string;
  incomingCallProtocols: string[];
  callbackInfo: {
    callbackAddress: string;
    callbackType: string;
  };
  lookupInfo: {
    lookupLink: string;
    type: string;
  };
  sipUrl?: string;
  primaryDisplayString?: string;
  secondaryDisplayString?: string;
  isPrivate?: boolean;
  callbackAddress?: string;
}

export interface IWebexUserSession {
  id: string;
  durationSecs: number;
  self: IWebexUserSessionPerson;
  url: string;
  sessionId: string;
  sessionType: string;
  startTime: string;
  endTime: string;
  direction: string;
  disposition: string;
  other: IWebexUserSessionPerson;
  durationSeconds: number;
  joinedDurationSeconds: number;
  participantCount: number;
  links: {
    displayMeetingUrl: string;
    locusUrl: string;
    callbackAddress: string;
  };
  isDeleted: boolean;
  isPMR: boolean;
  correlationIds: string[];
}

export interface IWebexRecentCallResponse {
  userSessions: IWebexUserSession[];
  statusCode: number;
}

/**
 *
 */
export async function listRecentCallEvents(): Promise<IWebexRecentCallResponse> {
  const url = `/recentCallEvents`;
  try {
    const webexClient = await ClientBuilderFactory.getWebexClient();
    const response = await webexClient.get(url);
    Logger.info(`WebexRecentCallService:recentCallEvents(): done.`);
    return response.data as IWebexRecentCallResponse;
  } catch (error) {
    Logger.error(
      `WebexClient.recentCallEvents(): got error: '${formatError(error)}'.`
    );
    // TODO: How to handle errors and status code to be returned
    return { userSessions: [], statusCode: 500 };
  }
}
