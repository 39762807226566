import {
  IWebexIntCallableEntity,
  IWebexIntContact,
} from '@webex/component-adapter-interfaces/dist/esm/src';
import { IGraphUser } from '../service/MsTeamsUserService';

export enum CallableType {
  EMAIL = 'mail',
  MOBILE = 'mobile',
  WORK = 'work',
  HOME = 'home',
}

export const transformGraphUser = (
  graphUser: IGraphUser,
  fetchAvatarUrl?: () => Promise<string | undefined>
): IWebexIntContact => {
  const emails: IWebexIntCallableEntity[] = [
    ...(graphUser.emailAddresses || [])
      .map((emailObj) => emailObj.address)
      .concat([graphUser.mail] || [])
      .map((email) => ({
        type: CallableType.EMAIL,
        address: email,
      })),
  ];

  const phoneNumbers: IWebexIntCallableEntity[] = [
    ...(graphUser.businessPhones || []).map((number) => ({
      type: CallableType.WORK,
      address: number,
    })),
  ];

  if (graphUser.mobilePhone) {
    phoneNumbers.push({
      type: CallableType.MOBILE,
      address: graphUser.mobilePhone,
    });
  }

  return {
    id: graphUser.id,
    phoneNumbers,
    emailAddresses: emails,
    name: graphUser.displayName,
    fetchAvatarUrl,
  };
};
