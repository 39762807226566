import { AxiosError } from 'axios';
import axiosRetry, { exponentialDelay } from 'axios-retry';
import { Logger } from '../../packages/logger/src/Logger';
import { removeLocalStorageItem } from '../util/LocalStorageUtil';
import { isTokenExpired } from '../util/TokenUtils';
import { AClientBuilder } from './ClientBuilder';
import { addGraphAuthorizationInterceptor } from './Interceptors';

/**
 *
 * @param error
 */
function retryCondition(error: AxiosError): boolean {
  /**
   * This normally won't happen see that setAuthorizationHeader() could detect expired access token and renew it.
   * This is to handle rare case when request take too long to finish and hence access token become expired after
   * it passed check in setAuthorizationHeader() or client clock is inaccurate
   * or access token become invalid because of other reasons e.g. Azure portal configuration change etc.
   */
  if (isTokenExpired(error)) {
    Logger.info(
      `GraphClient.retryCondition(): access token used to access '${error.config.url}' is invalid. Need to retry.`
    );
    removeLocalStorageItem('accessTokenExpiryDate');
    return true;
  }
  // ! linter was causing this function to be <any>, wrapped in inline if
  const needRetry = !!(
    error.code !== 'ECONNABORTED' &&
    error.response &&
    error.response.status === 429
  );
  if (needRetry) {
    Logger.info('GraphClient.retryCondition(): need to retry.');
  }
  return needRetry;
}

export default class GraphClientBuilder extends AClientBuilder {
  constructor() {
    // Pointing to GRAPHI API
    super(`${process.env.GRAPH_INTEGRATION_URL}`);
  }

  addRetryConfiguration(): void {
    axiosRetry(this.client, {
      retries: 3,
      retryDelay: exponentialDelay,
      retryCondition,
    });
  }

  addAuthorizationInterceptor(): void {
    this.client.interceptors.request.use(addGraphAuthorizationInterceptor);
  }
}
