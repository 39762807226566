import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useCallForwardSetting from "../../packages/widgets/src/hooks/useCallForwardSettings";

type Props = {
  style?: React.CSSProperties;
  shouldPollApi: boolean,
  setShouldPollApi:React.Dispatch<React.SetStateAction<boolean>>;
};

/**
 * @description The summary of this component.
 * @param {string} name The name of the property
 */
export const CallForward = ({shouldPollApi, setShouldPollApi}: Props) => {
  const { t } = useTranslation();
  const { callForwardSettings, refreshCallForward } = useCallForwardSetting();
  const [settingsValue, setsettingsValue] = useState<string>();
  const [showIcon, setIcon] = useState<any>();
  const donNotForwardIcon = <svg width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M3.90004 5.26664C3.93436 5.39471 4.01815 5.50391 4.13298 5.57021C4.24781 5.6365 4.38427 5.65447 4.51235 5.62015C4.64042 5.58583 4.74962 5.50205 4.81592 5.38722C4.88221 5.27239 4.90018 5.13592 4.86586 5.00785L4.54317 3.80192L7.63832 5.57035C7.69532 5.60294 7.75819 5.62398 7.82332 5.63227C7.88845 5.64056 7.95458 5.63593 8.01793 5.61866C8.08128 5.60138 8.1406 5.57179 8.19251 5.53158C8.24441 5.49137 8.28789 5.44133 8.32045 5.38431L10.6842 1.24808C10.7497 1.13298 10.7668 0.996602 10.7318 0.868887C10.6969 0.741171 10.6126 0.632547 10.4977 0.566849C10.3827 0.501152 10.2464 0.483749 10.1186 0.518459C9.99079 0.553169 9.88199 0.637157 9.81606 0.751992L7.70033 4.45414L5.05309 2.94164L6.24135 2.62356C6.36943 2.58924 6.47863 2.50545 6.54492 2.39062C6.61122 2.27579 6.62918 2.13933 6.59487 2.01125C6.56055 1.88318 6.47676 1.77398 6.36193 1.70768C6.2471 1.64139 6.11064 1.62342 5.98256 1.65774L3.62075 2.29006C3.55733 2.30705 3.49788 2.33636 3.44579 2.37633C3.3937 2.4163 3.35 2.46613 3.31717 2.52299C3.28435 2.57985 3.26304 2.64262 3.25448 2.70771C3.24591 2.7728 3.25025 2.83895 3.26725 2.90236L3.90004 5.26664Z" fill="var(--mds-color-theme-text-primary-normal)" fillOpacity="0.95"/>
  <path d="M7.00014 7.50006C2.05336 7.50006 0.000137329 9.87896 0.000137329 11.1524V11.8858C0.000611118 12.3138 0.170837 12.7241 0.473467 13.0267C0.776096 13.3294 1.18641 13.4996 1.6144 13.5001H3.39907C3.82464 13.4984 4.23219 13.3281 4.53234 13.0264C4.83248 12.7247 5.00072 12.3163 5.00014 11.8907V11.0723C5.59333 10.6831 6.29086 10.4835 7.00014 10.5C7.70908 10.4834 8.40641 10.6821 9.00014 11.0699V11.8951C9.01404 12.3269 9.19592 12.7363 9.50707 13.036C9.81823 13.3358 10.2341 13.5023 10.6661 13.5001H12.3859C12.8138 13.4996 13.2242 13.3294 13.5268 13.0267C13.8294 12.7241 13.9996 12.3138 14.0001 11.8858V11.1524C14.0001 9.87894 11.9469 7.50006 7.00014 7.50006ZM13.0001 11.8858C13 12.0487 12.9353 12.2048 12.8201 12.32C12.7049 12.4352 12.5488 12.4999 12.3859 12.5001H10.6662C10.4988 12.5035 10.3365 12.4427 10.2127 12.3302C10.0888 12.2177 10.0127 12.0619 10.0002 11.8951V10.9185C10.0002 10.1543 8.35075 9.50004 7.00016 9.50004C5.51432 9.50004 4.00016 10.2173 4.00016 10.9214V11.8907C4.00108 12.0511 3.93831 12.2054 3.82562 12.3197C3.71294 12.4339 3.55953 12.4988 3.39907 12.5001H1.6144C1.45152 12.4999 1.29536 12.4352 1.18019 12.32C1.06502 12.2048 1.00027 12.0487 1.00014 11.8858V11.1524C1.00014 10.3975 2.78481 8.50004 7.00014 8.50004C11.2155 8.50004 13.0001 10.3975 13.0001 11.1524V11.8858Z" fill="var(--mds-color-theme-text-primary-normal)" fillOpacity="0.95"/>
  </svg>;
  const voicemailIcon = <svg width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M3.90003 5.26664C3.93435 5.39471 4.01813 5.50391 4.13296 5.57021C4.24779 5.6365 4.38426 5.65447 4.51233 5.62015C4.64041 5.58583 4.74961 5.50205 4.8159 5.38722C4.8822 5.27239 4.90016 5.13592 4.86585 5.00785L4.54315 3.80192L7.63831 5.57035C7.69531 5.60294 7.75817 5.62398 7.8233 5.63227C7.88844 5.64056 7.95457 5.63593 8.01792 5.61866C8.08126 5.60138 8.14058 5.57179 8.19249 5.53158C8.2444 5.49137 8.28787 5.44133 8.32044 5.38431L10.6842 1.24808C10.7497 1.13298 10.7668 0.996602 10.7318 0.868887C10.6968 0.741171 10.6126 0.632547 10.4977 0.566849C10.3827 0.501152 10.2464 0.483749 10.1186 0.518459C9.99078 0.553169 9.88198 0.637157 9.81604 0.751992L7.70032 4.45414L5.05307 2.94164L6.24134 2.62356C6.36941 2.58924 6.47861 2.50545 6.54491 2.39062C6.6112 2.27579 6.62917 2.13933 6.59485 2.01125C6.56053 1.88318 6.47674 1.77398 6.36191 1.70768C6.24709 1.64139 6.11062 1.62342 5.98255 1.65774L3.62073 2.29006C3.55731 2.30705 3.49786 2.33636 3.44578 2.37633C3.39369 2.4163 3.34998 2.46613 3.31716 2.52299C3.28433 2.57985 3.26303 2.64262 3.25446 2.70771C3.2459 2.7728 3.25023 2.83895 3.26723 2.90236L3.90003 5.26664Z" fill="var(--mds-color-theme-text-success-normal)"/>
  <path d="M7.00012 7.50006C2.05335 7.50006 0.00012207 9.87896 0.00012207 11.1524V11.8858C0.000595859 12.3138 0.170821 12.7241 0.473451 13.0267C0.776081 13.3294 1.1864 13.4996 1.61438 13.5001H3.39905C3.82462 13.4984 4.23217 13.3281 4.53232 13.0264C4.83247 12.7247 5.0007 12.3163 5.00012 11.8907V11.0723C5.59331 10.6831 6.29084 10.4835 7.00012 10.5C7.70907 10.4834 8.4064 10.6821 9.00012 11.0699V11.8951C9.01402 12.3269 9.1959 12.7363 9.50706 13.036C9.81821 13.3358 10.2341 13.5023 10.6661 13.5001H12.3858C12.8138 13.4996 13.2241 13.3294 13.5268 13.0267C13.8294 12.7241 13.9996 12.3138 14.0001 11.8858V11.1524C14.0001 9.87894 11.9469 7.50006 7.00012 7.50006ZM13.0001 11.8858C13 12.0487 12.9352 12.2048 12.8201 12.32C12.7049 12.4352 12.5487 12.4999 12.3859 12.5001H10.6661C10.4988 12.5035 10.3365 12.4427 10.2127 12.3302C10.0888 12.2177 10.0127 12.0619 10.0001 11.8951V10.9185C10.0001 10.1543 8.35073 9.50004 7.00014 9.50004C5.5143 9.50004 4.00014 10.2173 4.00014 10.9214V11.8907C4.00106 12.0511 3.9383 12.2054 3.82561 12.3197C3.71292 12.4339 3.55952 12.4988 3.39905 12.5001H1.61438C1.45151 12.4999 1.29534 12.4352 1.18018 12.32C1.06501 12.2048 1.00025 12.0487 1.00012 11.8858V11.1524C1.00012 10.3975 2.78479 8.50004 7.00012 8.50004C11.2155 8.50004 13.0001 10.3975 13.0001 11.1524V11.8858Z" fill="var(--mds-color-theme-text-success-normal)"/>
  </svg>;

  const REFRESH_INTERVAL_SHORT = 10000; // 10 seconds
  const POLLING_INTERVAL_2_MINUTES = 120000; // 2 minutes in milliseconds

  const hasDestinationNum = (callForwardSettings: string): boolean => {
    if (callForwardSettings?.match(/^\d|^\+/)) {
      return true
    }
    return false
  } 

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    const refreshData = () => {
      refreshCallForward();
    };
    if (shouldPollApi) {
      intervalId = setInterval(refreshData, REFRESH_INTERVAL_SHORT);
    } 
    const timeoutId = setTimeout(() => {
      clearInterval(intervalId);
      setShouldPollApi(false);
    }, POLLING_INTERVAL_2_MINUTES);
    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
  }, [shouldPollApi, setShouldPollApi]);

  useEffect(() => {
    if (callForwardSettings) {
      if (hasDestinationNum(callForwardSettings)) {
        setsettingsValue(callForwardSettings);
        setIcon(voicemailIcon);
      } else if (callForwardSettings === "VOICEMAIL") {
        setsettingsValue(t("voicemail"));
        setIcon(voicemailIcon);
      } else {
        setsettingsValue(callForwardSettings);
        setIcon(donNotForwardIcon);
      }
    }
  }, [callForwardSettings, setsettingsValue, settingsValue]);

  return (
    <div className="showCall-forward">
      {showIcon}
      <span data-testid="call-forward">{settingsValue}</span>
    </div>
  );
};
