import { Logger, downloadLogs } from '../../packages/logger/src/Logger';
import { INTERNAL_OPERATIONS } from "../../packages/widgets/src/utils/MetricUtils";
import { getCrossLaunchToken, msCrossLaunchToken, voiceMailEnabled, webexEmailCrossLaunchEnabled } from "./MsTeamsAuthenticationService";

/**
 *
 * @param address phone number or email address to call
 * @param isAudio
 */
export async function makeCrossLaunchCall(address: string, isAudio = false) {
  if(address) {
    if(address.toLowerCase() === INTERNAL_OPERATIONS.SAVE.toLowerCase()) {
      downloadLogs();
    }
    else {
      //  # is encoded has %23 and will be decoded back to # once call connection establishes
        address = address.replace(/ /g, "").replace(/#/g, "%23");
        Logger.info( `Address value checking without space ${address}`);
        const url = `webextel://${address}`;
        const webexcrossLaunchCallToken = await getCrossLaunchToken();
        const mscrossLaunchToken = await msCrossLaunchToken();
        const isVoiceMailEnabled = await voiceMailEnabled();
        const isWebexEmailCrossLaunchEnabled = await webexEmailCrossLaunchEnabled();
        const callUrl = new URL(url);
        if(webexcrossLaunchCallToken !== null || mscrossLaunchToken !== null) {
          if(isWebexEmailCrossLaunchEnabled === "true" && isVoiceMailEnabled === "true") {
            callUrl.searchParams.append('jws', webexcrossLaunchCallToken); 
          } else {
            callUrl.searchParams.append('jws', mscrossLaunchToken);
          }
          callUrl.searchParams.append('isAudio', String(isAudio));
        }
        Logger.info(
          `Making ${isAudio ? 'audio' : 'video'} call to address ${address}`
        );
        window.open(callUrl.href);
        }
  }
}
