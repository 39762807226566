import { ClientBuilderFactory } from '../remote/ClientBuilderFactory';
import { getMSTeamsContext } from '../util/MsTeamsContextUtil';
import {GraphURL} from '../util/URLUtils'

export interface IGraphUserEmailAddress {
  name: string;
  address: string;
}

export interface IGraphUser {
  displayName: string;
  id: string;
  mail: string;
  mobilePhone?: string;
  businessPhones?: string[];
  emailAddresses?: IGraphUserEmailAddress[];
  givenName?: string;
  surname?: string;
}

/**
 * Returns an MS Teams user's graph profile
 *
 * @param {string} userId Microsoft Teams user ID
 * @returns {Promise<IGraphUser>} user object returned from the graph API
 */
export async function getUserByID(userId: string): Promise<IGraphUser> {
    const graphClient = await ClientBuilderFactory.getGraphClient();
    const response = await graphClient.get(
        `${GraphURL.Users}/${userId}?$select=id,displayName,mobilePhone,mail,businessPhones`
    );
    if (response.status === 200) {
        return response.data as IGraphUser;
    }
    return Promise.reject(response);
}

/**
 * Returns the current MS Teams user's graph profile
 *
 * @returns {Promise<IGraphUser>} current user object returned from the graph API
 */
export async function getCurrentUser(): Promise<IGraphUser> {
  const context = await getMSTeamsContext();
  if (!context?.user?.id) {
    throw new Error('userId is not defined');
  }
  return getUserByID(context.user.id);
}

/**
 * Returns list of users where their first name, last name, or display name start with the provided string
 *
 * @param {string} query name prefix to search for
 * @param {number} limit maximum number of results
 * @returns {Promise<IGraphUser[]>} users who match the query string
 */
export async function fetchDirectoryUsers(
  query: string,
  limit = 15
): Promise<IGraphUser[]> {
  const escapedQuery = query
    ? encodeURIComponent(query.replace(/'/g, "''").trim())
    : query;
  const url = `${GraphURL.Users}?$select=id,displayName,mobilePhone,mail,businessPhones&$count=true&$top=${limit}&$filter=startswith(givenName,'${escapedQuery}') or startswith(surName,'${escapedQuery}') or startswith(displayName,'${escapedQuery}')`;
  const graphClient = await ClientBuilderFactory.getGraphClient();
  const response = await graphClient.get(url);
  if (response?.status === 200){
    return response.data.value as IGraphUser[];
  } else {
    let error:any = response;
    if (error.response?.status === 404) {
        return [] as IGraphUser[];
    }     
  }
  return Promise.reject(response);
}

/**
 * Fetches a user's avatar using their user ID
 *
 * @param {string} userId user's image to fetch
 * @returns {Promise<string>} image url
 */
export async function fetchDirectoryUserAvatar(
  userId: string
): Promise<string | undefined> {
  const url = `/beta/users/${userId}/photo/$value`;
  const graphClient = await ClientBuilderFactory.getGraphClient();
  const response = await graphClient.get(url, {
    responseType: 'blob',
  });
  if (response.status === 200) return URL.createObjectURL(response.data);
  return undefined;
}

 //This code splits the ids list into batches of 15 and executes a separate query for each batch. 
//The results from each query are concatenated to produce the final result. 
//This approach can help to reduce the number of child clauses in the filter expression and avoid the error you encountered.
export async function getUsersByIds(
    ids:string[],
    limit = 50
  ): Promise<IGraphUser[]> {
    if(ids && ids.length > 0) {
      const chunkSize = 15; // split into batches of 15
      const chunks = [];
      for (let i = 0; i < ids.length; i += chunkSize) {
        chunks.push(ids.slice(i, i + chunkSize));
      }
  
      const promises = chunks.map(async (chunk) => {
        const encodedValues = chunk.map(value => `'${encodeURIComponent(value)}'`);
        const userIds = `${encodedValues.join(',')}`;
        const url = `${GraphURL.Users}?$select=id,displayName,mobilePhone,mail,businessPhones&$count=true&$top=${limit}&$filter=id in (${userIds})`;
        const graphClient = await ClientBuilderFactory.getGraphClient();
        const response = await graphClient.get(url);
        if (response.status === 200) {
          return response.data.value as IGraphUser[];
        }
        return [];
      });
  
      const results = await Promise.all(promises);
      return results.flat();
    } else {
      return []; 
    }
  }
