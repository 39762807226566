import { useEffect, useState } from 'react';
import {
    getLocalStorageItem
} from "../../../../packages/logger/src";

export const useLocalStorageErrorNotification = (storageKey: string): boolean => {
  const [isShown, setIsShown] = useState<boolean>(false);

  useEffect(() => {
    const handleLocalStorageChange = (event: StorageEvent) => {
      if (getLocalStorageItem(storageKey)) {
        setIsShown(true);
        setTimeout(() => {
            setIsShown(false);
          }, 1000);
      } 
    };

    window.addEventListener('storage', handleLocalStorageChange);

    return () => {
      window.removeEventListener('storage', handleLocalStorageChange);
    };
  }, [storageKey]);

  return isShown;
}; 