import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { WebexSignIn } from './components/SignIn/SignIn';
import { useMsTeamsTheme } from './hooks/useMsTeamsTheme';
import { MsTeamsSDKAdapter } from './ms-teams-sdk-adapter/MsTeamsSDKAdapter';
import { getMSTeamsContext } from './ms-teams-sdk/util/MsTeamsContextUtil';
import { Logger } from "./packages/logger/src";
import { WebexCallingDataProvider } from './packages/widgets/src';
import { AuthEnd } from './pages/AuthEnd';
import { AuthStart } from './pages/AuthStart';
import { MessageExtension } from './pages/MessageExtension';
import { WebexAuthPage } from './pages/WebexAuthPage';
import { WebexAuthStartPage } from './pages/WebexAuthStartPage';

/**
 * App component, root for react app.
 *
 * @returns Root component for react app
 */
export const App = () => {
  const [contextLocale, setContextLocale] = useState<string>('');
  
  const [theme] = useMsTeamsTheme();
  // const { i18n } = useTranslation();

  const fetchContextLocale = async () => {
    const context = await getMSTeamsContext().catch((error) => {
      Logger.error(error);
    });
    if(context?.app?.locale) {
      setContextLocale(context.app.locale)
    }
    else {
      setContextLocale("en");
    }
  };

  useEffect(() => {
    fetchContextLocale();
  },[])

  return (
    <WebexCallingDataProvider
      adapter={MsTeamsSDKAdapter}
      theme={theme}
      language={contextLocale}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index element={<WebexSignIn />} />
            <Route path="message-extension" element={<MessageExtension />} />
            <Route
              path={`${process.env.APP_NAME}/auth-start/loader`}
              element={<AuthStart />}
            />
            <Route
              path={`${process.env.APP_NAME}/auth-end/loader`}
              element={<AuthEnd />}
            />
            <Route
              path={`${process.env.APP_NAME}/webex-auth-start/loader`}
              element={<WebexAuthStartPage />}
            />
            <Route
              path={`${process.env.APP_NAME}/webex-auth/loader`}
              element={<WebexAuthPage />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </WebexCallingDataProvider>
  );
};
