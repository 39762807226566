import { app } from '@microsoft/teams-js';
import { getMSTeamsContext } from '../util/MsTeamsContextUtil';
import { ClientBuilderFactory } from '../remote/ClientBuilderFactory';

export interface IGraphUserConversationMember {
  displayName: string;
  email: string;
  id: string;
  roles: string[];
  tenantId: string;
  userId: string;
  visibleHistoryStartDateTime: string;
}

/**
 * Returns the graph URL to get all members in the chat or channel
 *
 * @param {app.Context} context Ms Teams context object
 * @returns {string} url to fetch
 */
function getUrlForChatOrChannel(context: app.Context): string {
  if (context.chat) {
    return `/beta/me/chats/${context.chat.id}/members`;
  }
  if (context.team?.groupId && context.channel?.id) {
    return `/v1.0/teams/${context.team.groupId}/channels/${context.channel.id}/members`;
  }
  throw new Error('Cannot fetch member in chat.');
}

/**
 * Fetches all members in a 1:1 chat or channel
 *
 * @param {boolean} includeSelf flag to include current user in the returned results
 * @returns {Promise<IGraphUserConversationMember[]>} Promise of the members in the chat
 */
export async function getMembersInChat(
  includeSelf?: boolean
): Promise<IGraphUserConversationMember[]> {
  const context = await getMSTeamsContext();
  const url = getUrlForChatOrChannel(context);
  const graphClient = await ClientBuilderFactory.getGraphClient();
  const response = await graphClient.get(url);
  const membersInChat = (response.data?.value ||
    []) as IGraphUserConversationMember[];
  if (includeSelf) {
    return membersInChat;
  }
  return membersInChat.filter((member) => member.userId !== context.user?.id);
}
