import { LanguageDetectorAsyncModule } from 'i18next';
import { getLocalizationLanguage } from '../ms-teams-sdk/util/MsTeamsContextUtil';

export const TeamsLanguageDetector: LanguageDetectorAsyncModule = {
  type: 'languageDetector',
  async: true,
  detect: async (callback: (_: string) => void) => {
    const lang = await getLocalizationLanguage();
    callback(lang);
  },
  init: () => {},
  cacheUserLanguage: () => {},
};
