import {
	ISpeedDialRecord,
	ISpeedDialsAdapter,
} from '@webex/component-adapter-interfaces/dist/esm/src';
import {
	BehaviorSubject,
	Observable,
	ReplaySubject,
	from
} from 'rxjs';

import {
	addSpeedDial,
	getSpeedDials,
	removeSpeedDial,
	reorderSpeedDial,
	updateSpeedDial
} from '../ms-teams-sdk/service/MSTeamsSpeedDialService';


const dataSourceMap: Map < string, ISpeedDialRecord > = new Map();

export enum Contact{
	CONTACT = 'Contacts'
}
export class MsTeamsSpeedDialAdapter implements ISpeedDialsAdapter {
	speedDialSource: {
		[k: string]: ISpeedDialRecord[]
	} = {};
	speedDialID$: {
		[key: string]: ReplaySubject < ISpeedDialRecord[] >
	} = {};

	speeddialObservable$: {
		[key: string]: BehaviorSubject < ISpeedDialRecord >
	} = {};

	constructor(ds = {}) {
		this.speedDialSource = ds;
	}

	add(speedDial: ISpeedDialRecord) {
		addSpeedDial(speedDial);
	}

	update(speedDialList: ISpeedDialRecord[] ) {
		updateSpeedDial(speedDialList)
	}

	remove(speedDial: ISpeedDialRecord) {
		removeSpeedDial(speedDial)
	}
	
	reOrder(speedDial: ISpeedDialRecord): void {
        reorderSpeedDial(speedDial)
    }

	refresh(ID: string) {
		let speedDialID: number
		const speedDialList$ = from(getSpeedDials());
		speedDialList$.subscribe((dialList) => {
			if (dialList) {
				speedDialID = dialList.length
				dialList.forEach((listItem) => {
					dataSourceMap.set(listItem.id, listItem);
				});
				this.speedDialID$[ID].next(dialList as ISpeedDialRecord[]);
			} else {
				this.speedDialID$[ID].error(
					new Error(`Could not find speed dials for ID "${ID}"`)
				);
			}
		});
	}

	getAll(ID: string): Observable < ISpeedDialRecord[] > {
		if (!(ID in this.speedDialID$)) {
			this.speedDialID$[ID] = new ReplaySubject < ISpeedDialRecord[] > ();
			this.refresh(ID);
		}
		return this.speedDialID$[ID];
	}

	getContacts(ID: string): Observable < ISpeedDialRecord[] > {
		const cid = `${ID}-` + Contact.CONTACT;
		if (!(ID in this.speedDialID$)) {
			this.speedDialID$[cid] = new ReplaySubject < ISpeedDialRecord[] > ();

			const speedDialData = this.speedDialSource[cid];
			if (speedDialData) {
				this.speedDialID$[cid].next(speedDialData as ISpeedDialRecord[]);
			} else {
				this.speedDialID$[cid].error(
					new Error(`Could not find contacts for ID "${cid}"`)
				);
			}
		}
		return this.speedDialID$[cid];
	}

	getOne(ID: string): Observable < ISpeedDialRecord > {
		if (!(ID in this.speeddialObservable$)) {
			const speedDialData = dataSourceMap.get(ID);
			if (speedDialData) {
				this.speeddialObservable$[ID] = new BehaviorSubject < ISpeedDialRecord > (
					speedDialData
				);
			}
		}
		return this.speeddialObservable$[ID];
	}
}