import {
  ListNext as List,
  ListHeader,
  ListItemBaseSection,
  LoadingSpinner,
} from "@momentum-ui/react-collaboration";
import { IWebexIntContact } from "@webex/component-adapter-interfaces/dist/esm/src";
import React, { useCallback, useEffect, useState } from "react";
import { SearchContactsItem } from "../components/SearchContacts/SearchContactsItem";
import { SearchContactsListEmpty } from "../components/SearchContacts/SearchContactsListEmpty";
import { useContactSearch } from "../hooks/useContactSearch";
import { useMakeCall } from "../hooks/useMakeCall";
import useWebexClasses from "../hooks/useWebexClasses";
import { THEME_CLASS, WEBEX_CALLING_THEME } from "../utils/ThemeUtils";
import "./WebexSearchContactsList.styles.scss";

type SearchContactsListProps = {
  searchValue: string;
  noContactsFoundMessage: string;
  onUserSelect?: (user: IWebexIntContact | undefined) => void;
  hideSource?: boolean;
  style?: React.CSSProperties;
  label?: string;
  theme?: string;
};

/**
 *
 * @param root0
 * @param root0.style
 * @param root0.searchValue
 * @param root0.noContactsFoundMessage
 * @param root0.onSelectUser
 * @param root0.onUserSelect
 * @param root0.hideSource
 */
export const WebexSearchContactsList = ({
  searchValue,
  noContactsFoundMessage,
  onUserSelect = () => {},
  hideSource = false,
  style = undefined,
  label,
  theme,
}: SearchContactsListProps) => {
  let globalIndex = 0;
  const [sources, filteredContactResponse, isSearching] =
    useContactSearch(searchValue);
  const [selectedUserId, setSelectedUserId] = useState<string>();
  const [cssClasses, sc] = useWebexClasses("search-contacts-list", undefined, {
    loading: isSearching,
  });

  const setSeletedUser = useCallback(
    (user?: IWebexIntContact) => {
      onUserSelect(user);
      setSelectedUserId(user?.id);
    },
    [onUserSelect, setSelectedUserId]
  );

  const [makeCall] = useMakeCall();

  useEffect(() => {
    setSeletedUser(undefined);
  }, [searchValue]);

  if (!isSearching && filteredContactResponse.count === 0) {
    return (
      <SearchContactsListEmpty
        noContactsFoundMessage={noContactsFoundMessage}
      />
    );
  }

  return (
    <List
      shouldFocusOnPress
      listSize={filteredContactResponse.count}
      className={`${cssClasses} ${
        theme === WEBEX_CALLING_THEME.DARK
          ? THEME_CLASS.DARK
          : theme === WEBEX_CALLING_THEME.LIGHT
          ? THEME_CLASS.LIGHT
          : ""
      }`}
      style={style}
    >
      {sources
        .filter(
          (source) =>
            isSearching ||
            (filteredContactResponse.items[source] || []).length > 0
        )
        .map((source) => (
          <React.Fragment key={source}>
            {!hideSource && (
              <ListHeader>
                <ListItemBaseSection
                  position="fill"
                  className={sc("source-header")}
                >
                  {source}
                </ListItemBaseSection>
              </ListHeader>
            )}
            {isSearching ? (
              <LoadingSpinner
                scale={32}
                className={sc("loading")}
                data-testid={`loading-spinner-${source}`}
              />
            ) : (
              filteredContactResponse.items[source].map((user) => {
                const currentIndex = globalIndex; // Assign the current index to a local variable
                globalIndex++; // Increment the global index for the next iteration

                return (
                  <SearchContactsItem
                    key={user.id}
                    user={user}
                    index={currentIndex}
                    isSelected={selectedUserId === user.id}
                    onPress={() => setSeletedUser(user)}
                    makeCall={makeCall}
                    label={label}
                  />
                );
              })
            )}
          </React.Fragment>
        ))}
    </List>
  );
};
