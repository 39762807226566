import { app } from '@microsoft/teams-js';
import memoize from 'memoizee';
import { Logger } from "../../packages/logger/src";

declare global {
    interface Window {
        mSTeamsContext: any;
    }
  }
/**
 * Returns the MS Teams context object, ensuring that the app has been initialized first
 *
 * @returns {Promise<app.Context>} MS Teams context promise
 */
export const getMSTeamsContext = memoize(async () => {
    if ( window.mSTeamsContext ) {
        return window.mSTeamsContext; 
    }
    await app.initialize();
    window.mSTeamsContext = app.getContext();
    return window.mSTeamsContext;
}, {});

/**
 * Loads the localization language from the microsoft teams context
 *
 * @returns {Promise<string>} localization language code
 */
export async function getLocalizationLanguage(): Promise<string> {
  const context = await getMSTeamsContext().catch((error) => {
    Logger.error(error);
  });
  return context.app.locale;
}

/**
 * Loads the theme from the microsoft teams context
 *
 * @returns {Promise<string>} teams theme string
 */
export async function getMSTeamsTheme(): Promise<string> {
  const context = await getMSTeamsContext().catch((error) => {
    Logger.error(error);
  });
  return context.app.theme;
}

export function processArray(originalArray: string[]): [string[], string[]] {
  const array1: string[] = [];
  const array2: string[] = [];

  let foundComma = false;
  let wordsAfterComma: string[] = [];

  for (const element of originalArray) {
    if (element.startsWith(',')) {
      foundComma = true;
      wordsAfterComma = element.substring(1).split(' ');
    } else if (!foundComma) {
      array1.push(element);
    } else {
      wordsAfterComma.push(element);
    }
  }
  return [array1, wordsAfterComma];
}

export function extractTextBetweenDoubleDashes(inputString: any) {
  const regex = /--(.*?)(?=--|$)/g; // Regex to match text between '--' occurrences globally
  const matches = [];
  let match;

  while ((match = regex.exec(inputString)) !== null) {
    matches.push(match[1]); // Group 1 captures the text between '--' occurrences
  }

  if (matches.length > 0) {
      var longest = matches.reduce(
          function (a, b) {
              return a.length > b.length ? a : b;
          }
      );
    return longest;
  }
  return "";
}