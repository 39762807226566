import * as MSTeamsAuthenticationService from './service/MsTeamsAuthenticationService';
import * as MSTeamsContactService from './service/MSTeamsContactService';
import * as CrossLaunchService from './service/CrossLaunchService';
import * as MSTeamsExtensionService from './service/MSTeamsExtensionService';
import * as MsTeamsUserService from './service/MsTeamsUserService';
import * as WebexRecentCallService from './service/WebexRecentCallService';
import * as MSTeamsSpeedDialService from './service/MSTeamsSpeedDialService';

export const MicrosoftTeamsSDK = {
  authentication: {
    ...MSTeamsAuthenticationService,
  },
  contact: {
    ...MSTeamsContactService,
  },
  crossLaunch: {
    ...CrossLaunchService,
  },
  extension: {
    ...MSTeamsExtensionService,
  },
  user: {
    ...MsTeamsUserService,
  },
  userSession: {
    ...WebexRecentCallService,
  },
  speedDial: {
    ...MSTeamsSpeedDialService,
  },
};
