import { useTranslation } from 'react-i18next';
export const RetrySignInImage = () => {
  const { t } = useTranslation();
  return (
    <svg role="img" width="164" height="144" viewBox="0 0 164 144" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>{t('retryImageText')}</title>
<path d="M78.166 98.2L64.266 119.1C63.366 120.4 64.366 122.2 65.966 122.2H77.666C78.366 122.2 78.866 123 78.566 123.6L70.566 141.1C70.066 142.1 71.366 143 72.166 142.2L96.866 117.5C98.166 116.2 97.266 114.1 95.466 114.1H88.166C87.366 114.1 86.866 113.2 87.366 112.5L94.866 101.5C96.366 99.3 98.866 98 101.466 98H135.466C149.666 98 161.566 86.9 161.966 72.7C162.366 58 150.566 46 135.966 46C133.866 46 131.766 46.3 129.866 46.7C128.166 21.7 107.366 2 81.966 2C59.466 2.2 40.6661 17.6 35.3661 38.5C34.2661 38.4 33.066 38.3 31.866 38.3C15.066 38.3 1.36605 52.2 1.86605 69.1C2.26605 85.4 16.166 98.3 32.466 98.3H41.8661M129.766 47C116.866 51.3 109.866 62.3 114.866 67.3C118.866 71.2 129.866 64.2 129.766 47Z" stroke="url(#paint0_linear_3375_4831)" stroke-width="2.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<defs>
  <linearGradient id="paint0_linear_3375_4831" x1="81.9144" y1="2" x2="81.9144" y2="142.508" gradientUnits="userSpaceOnUse">
    <stop stop-color="#F0677E"/>
    <stop offset="1" stop-color="#DB1F2E"/>
  </linearGradient>
</defs>
</svg>
  )
};
