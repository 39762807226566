import { useCallback, useContext, useEffect, useState } from "react";
import { AdapterContext } from "../contexts/AdapterContext";
/**
 * Custom hook that returns callforward data for a given user.
 *
 * @returns {object}
 */
export default function useCallForwardSetting() {
  const [callForwardSettings, setCallForwardSettings] = useState<string>('');

  const ctx = useContext(AdapterContext);

  const onCallForwardUpdate = useCallback((data:string) => {
    setCallForwardSettings(data);
  }, []);

  useEffect(() => {
    const subscription = ctx?.callForwardAdapter
      ?.getCallForwardSettings()
      .subscribe({ next: onCallForwardUpdate });
    return () => {
      subscription?.unsubscribe();
    };
  }, [ctx?.callForwardAdapter, onCallForwardUpdate]);

  const refreshCallForward = useCallback(async () => {
    if (ctx?.callForwardAdapter?.refreshCallForward) {
      console.log("callforward refreshing function");
      return ctx?.callForwardAdapter?.refreshCallForward();
    }
    return Promise.resolve();
  }, [ctx?.callForwardAdapter]);

  return {
    callForwardSettings,
    refreshCallForward,
  } as const;
}
