import {
  ButtonCircle,
  ButtonPill,
  Flex,
  IconNext as Icon,
  MenuNext as Menu,
  MenuTrigger
} from "@momentum-ui/react-collaboration";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Item } from "react-stately";
import { getBusinessNumber } from "../../ms-teams-sdk/service/MsTeamsAuthenticationService";
import { CallSettingsURL } from "../../ms-teams-sdk/util/TobarUtils";
import { IBusinessNumberResponse, UserProfileMenuItemsKeys } from "../../packages/adapter-interfaces/src/";

import { Logger, formatError, removeLocalStorageItem } from "../../packages/logger/src";
import { submitMetrics } from "../../packages/widgets/src/services/WebexMetricsService";
import { FIRST_TIME_DOWNLOAD, WORK_TYPE, callSettingsClickedMetric, signOutMetric } from "../../packages/widgets/src/utils/MetricUtils";
import { CallForward } from '../CallForward/CallForward';
import "./TopBar.scss";

type ITopBarProps = {
  myNumberLabel?: string;
  myNumber?: string;
  settingsLabel?: string;
  onSettingsPress?: () => void;
  onSignOutPress?: () => void;
};

/**
 * @description The summary of this component.
 * @param {string} name The name of the property
 */
export const TopBar = ({}: ITopBarProps) => {
  const [myNumber, setBusinessNumber] = useState<string>("");
  const { t } = useTranslation();
  const [shouldOpenURL, setShouldOpenURL] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isCallsettingsClicked, setIsCallsettingsClicked] = useState(false);

const onSignOutPress = () => {
  submitMetrics(
    {
      metricEvent: signOutMetric
    }
  );
  // Remove all local storage items except FIRST_TIME_DOWNLOAD and SPEED_DIAL_CACHED_DATE key so that user does not see the download page every time he does log in
  // const keysToKeep = [FIRST_TIME_DOWNLOAD, SPEED_DIAL_CACHED_DATE];
  const keysToKeep = [FIRST_TIME_DOWNLOAD];
  const allKeys = Object.keys(localStorage);
  allKeys.forEach((key) => {
    if (!keysToKeep.includes(key)) {
        removeLocalStorageItem(key);
    }
  });
  window.location.reload();
}

const handleAction = useCallback((key: React.Key) => {
  switch (key) {
    case UserProfileMenuItemsKeys.SIGN_OUT:
      if (onSignOutPress) {
        onSignOutPress();
      }
      break;
    
    default:
      throw new Error('Cannot find action');
  }
}, []);

  const signIn = async () => {
    try {
      const response: IBusinessNumberResponse = await getBusinessNumber();
      const primaryNumber = response?.phoneNumbers?.find(phoneNumber => phoneNumber?.type === WORK_TYPE.ITEM_TYPE);
      const result = primaryNumber?.value ? primaryNumber?.value.replace(/-/g, "") : '';
      Logger.info("Getting the Business number");
      setBusinessNumber(result);
    } catch (e) {
      Logger.error(`Business Number: got error: '${formatError(e)}'.`);
    }
  };

  useEffect(() => {
    signIn();
  }, []);

  const url = CallSettingsURL.IcallSettingsURL;
  const onSettingsPress = () => {
    setIsOpen(true);
    setIsCallsettingsClicked(true);
    submitMetrics(
      {
        metricEvent: callSettingsClickedMetric
      }
    );
    //window.open(url);
  };
  useEffect(() => {
    if (shouldOpenURL) {
    window.open(url);
    setShouldOpenURL(false);
    }
    }, [shouldOpenURL]);
    
    useEffect(() => {
    if (isOpen) {
    setShouldOpenURL(true);
    setIsOpen(false);
    }
    }, [isOpen]);

    const formatPhoneNumberForAnnouncement = (phoneNumber: string): string => {
      if (phoneNumber?.match(/^\d|^\+/)) {
        return phoneNumber?.split('').map((digit) => digit).join(' ');
      }
      return phoneNumber;
    } 

  return (
    <div className="wbx-top-bar">
      <div aria-label={t("label.topbar.number") + formatPhoneNumberForAnnouncement(myNumber)}>
        {t("label.topbar.number")} {myNumber}
      </div>
      <div className="settings_item">
      <CallForward shouldPollApi={isCallsettingsClicked} setShouldPollApi={setIsCallsettingsClicked}></CallForward>
      <div className="call-settings">
        <ButtonPill
          ghost
          size={32}
          onPress={onSettingsPress}
          aria-label={t("label.topbar.settings")}
          data-testid="settings-click"
        >
           <svg width="18" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.3922 4.60254C14.6221 4.50456 14.807 4.32408 14.9104 4.09663C15.0138 3.86917 15.0284 3.61123 14.9512 3.37359L14.7473 2.74622C14.6718 2.51329 14.5134 2.31619 14.3022 2.19234C14.091 2.06849 13.8416 2.02652 13.6015 2.0744L13.3637 2.12164L13.3357 2.10242L13.3187 1.91138C13.2966 1.66248 13.1821 1.4309 12.9977 1.26223C12.8134 1.09355 12.5725 1 12.3227 1H11.6629C11.4181 0.999999 11.1818 1.08981 10.9988 1.2524C10.8158 1.415 10.6987 1.63909 10.6699 1.8822L10.6414 2.12293L10.6144 2.14362L10.4275 2.10071C10.1839 2.04465 9.92822 2.08192 9.71079 2.20514C9.49336 2.32837 9.33001 2.52861 9.25296 2.76636L9.04908 3.39374C8.9734 3.62658 8.98578 3.87908 9.08385 4.1034C9.18192 4.32772 9.35888 4.50828 9.58118 4.61084L9.80136 4.7124L9.81269 4.74444L9.71418 4.90895C9.5858 5.12332 9.54232 5.378 9.59232 5.62282C9.64232 5.86765 9.78217 6.08489 9.98432 6.23176L10.518 6.61951C10.7161 6.76341 10.9601 6.82966 11.2037 6.8057C11.4474 6.78174 11.6737 6.66924 11.84 6.48951L12.0046 6.31154L12.0385 6.31063L12.1645 6.45515C12.3287 6.6435 12.5574 6.76354 12.8057 6.79164C13.054 6.81975 13.3038 6.75388 13.506 6.60701L14.0397 6.21925C14.2378 6.07536 14.3762 5.8638 14.4287 5.62467C14.4812 5.38554 14.4441 5.13546 14.3246 4.92183L14.2062 4.71028L14.2158 4.67775L14.3922 4.60254ZM13.3908 3.94232L13.1275 4.8305L13.4519 5.41021L12.9182 5.79797L12.483 5.29876L11.5569 5.32276L11.1058 5.81049L10.5721 5.42274L10.9124 4.8545L10.6034 3.98114L10.0001 3.70282L10.204 3.07544L10.8496 3.22344L11.5847 2.65972L11.6629 2H12.3227L12.3813 2.65973L13.1447 3.18463L13.7963 3.05523L14.0001 3.68262L13.3908 3.94232Z" fill="var(--mds-color-theme-text-primary-normal)"/>
            <path d="M12.0001 4.68561C12.4143 4.68561 12.7501 4.34982 12.7501 3.93561C12.7501 3.5214 12.4143 3.18561 12.0001 3.18561C11.5859 3.18561 11.2501 3.5214 11.2501 3.93561C11.2501 4.34982 11.5859 4.68561 12.0001 4.68561Z" fill="var(--mds-color-theme-text-primary-normal)"/>
            <path d="M4.5538 11.4213C8.0517 14.9192 11.1857 14.6889 12.0861 13.7885L12.6047 13.2699C12.907 12.9669 13.0768 12.5564 13.0768 12.1284C13.0768 11.7005 12.907 11.29 12.6047 10.987L11.3428 9.72503C11.0407 9.42526 10.6321 9.25753 10.2065 9.25863C9.78094 9.25973 9.37317 9.42957 9.07265 9.7309L8.49397 10.3096C7.79932 10.1653 7.16496 9.81324 6.67511 9.30002C6.16212 8.81043 5.80953 8.17688 5.66382 7.48288L6.24767 6.89974C6.5432 6.58456 6.70405 6.16648 6.696 5.7345C6.68794 5.30252 6.51161 4.89073 6.20454 4.58679L4.98816 3.37041C4.6852 3.06812 4.27469 2.89835 3.84671 2.89835C3.41873 2.89835 3.00822 3.06812 2.70526 3.37041L2.18665 3.88902C1.28618 4.78946 1.05589 7.92343 4.5538 11.4213ZM3.41235 4.0775C3.5276 3.96243 3.68382 3.89779 3.84669 3.89779C4.00956 3.89779 4.16578 3.96243 4.28104 4.0775L5.49742 5.29388C5.61813 5.40968 5.68991 5.56733 5.698 5.73441C5.70608 5.9015 5.64987 6.06534 5.5409 6.19226L4.84967 6.88278C4.30933 7.42313 5.01298 9.05209 5.96799 10.0071C7.01864 11.0578 8.59651 11.6212 9.09438 11.1234L9.77974 10.438C9.89256 10.3239 10.046 10.2592 10.2065 10.2581C10.367 10.257 10.5213 10.3196 10.6357 10.4321L11.8976 11.6941C12.0127 11.8094 12.0773 11.9656 12.0773 12.1284C12.0773 12.2913 12.0127 12.4475 11.8976 12.5628L11.379 13.0814C10.8452 13.6152 8.24158 13.6949 5.26089 10.7142C2.2802 7.73355 2.35996 5.12989 2.89374 4.59611L3.41235 4.0775Z" fill="var(--mds-color-theme-text-primary-normal)"/>
          </svg>
         <span>{t("label.topbar.settings")}</span>
        </ButtonPill>
      </div>
      <div className="user-profile">
            <MenuTrigger
              aria-label={t("label.user.profile.menu")}
              triggerComponent={
                <ButtonCircle size={28} ghost key="1" aria-label={t("label.user.profile.menu")} className="press-usermenu">
                  <Icon scale={18} name="more" weight="bold" />
                </ButtonCircle>
              }
            >
              <Menu onAction={handleAction} selectionMode="single" key="2" aria-label={t("label.user.profile.menu")}>
                <Item key={UserProfileMenuItemsKeys.SIGN_OUT} aria-label={t("label.signOut")}>
                  <Flex alignItems="center" xgap="0.5rem">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.49986 10.5C3.59873 10.5 3.69538 10.4707 3.77759 10.4157C3.85979 10.3608 3.92386 10.2827 3.9617 10.1914C3.99953 10.1 4.00943 9.99949 3.99015 9.90251C3.97086 9.80553 3.92326 9.71645 3.85336 9.64653L2.70729 8.50043L7.99986 8.50003C8.13246 8.50003 8.25964 8.44735 8.35341 8.35358C8.44718 8.25981 8.49986 8.13263 8.49986 8.00003C8.49986 7.86742 8.44718 7.74024 8.35341 7.64647C8.25964 7.5527 8.13246 7.50003 7.99986 7.50003L2.70651 7.50043L3.85339 6.35355C3.89982 6.30712 3.93664 6.25201 3.96177 6.19135C3.98689 6.1307 3.99982 6.06568 3.99982 6.00003C3.99982 5.93438 3.98689 5.86937 3.96177 5.80871C3.93664 5.74805 3.89982 5.69294 3.85339 5.64651C3.80697 5.60009 3.75186 5.56326 3.6912 5.53814C3.63054 5.51301 3.56553 5.50008 3.49988 5.50008C3.43422 5.50008 3.36921 5.51301 3.30856 5.53814C3.2479 5.56326 3.19279 5.60009 3.14636 5.64651L1.14636 7.64651C1.09992 7.69293 1.06308 7.74804 1.03795 7.8087C1.01281 7.86935 0.999878 7.93437 0.999878 8.00003C0.999878 8.06569 1.01281 8.1307 1.03795 8.19136C1.06308 8.25202 1.09992 8.30713 1.14636 8.35355L3.14636 10.3535C3.19273 10.4 3.24783 10.4369 3.30849 10.4621C3.36916 10.4872 3.43419 10.5001 3.49986 10.5Z" fill="var(--mds-color-theme-text-primary-normal)" fillOpacity="0.95" />
                      <path d="M7.99986 1.00003C6.93559 0.99712 5.88482 1.23826 4.92831 1.70492C3.97181 2.17158 3.13502 2.85132 2.48228 3.69192C2.40074 3.79644 2.36403 3.92906 2.38021 4.06063C2.39639 4.1922 2.46413 4.31197 2.56856 4.39363C2.67299 4.47528 2.80556 4.51214 2.93716 4.49611C3.06875 4.48008 3.18859 4.41247 3.27037 4.30814C4.03915 3.32325 5.09622 2.60292 6.29407 2.24767C7.49191 1.89242 8.77078 1.91998 9.95222 2.32649C11.1337 2.733 12.1587 3.49819 12.8844 4.51527C13.61 5.53236 14.0001 6.75061 14.0001 8.00003C14.0001 9.24944 13.61 10.4677 12.8844 11.4848C12.1587 12.5019 11.1337 13.2671 9.95222 13.6736C8.77078 14.0801 7.49191 14.1076 6.29407 13.7524C5.09622 13.3971 4.03915 12.6768 3.27037 11.6919C3.22993 11.6401 3.17968 11.5967 3.12248 11.5643C3.06529 11.5319 3.00226 11.5111 2.93702 11.5031C2.87178 11.4951 2.80559 11.5 2.74225 11.5176C2.67891 11.5352 2.61965 11.5651 2.56787 11.6055C2.51609 11.646 2.47279 11.6963 2.44046 11.7536C2.40813 11.8108 2.3874 11.8738 2.37945 11.9391C2.3715 12.0044 2.37649 12.0705 2.39413 12.1339C2.41178 12.1972 2.44173 12.2564 2.48228 12.3081C3.21306 13.2441 4.16993 13.9787 5.26286 14.443C6.35579 14.9073 7.54886 15.0859 8.72984 14.962C9.91082 14.8382 11.0409 14.416 12.0137 13.7351C12.9866 13.0542 13.7702 12.137 14.2909 11.0698C14.8116 10.0026 15.0523 8.82047 14.9903 7.63464C14.9283 6.4488 14.5657 5.29823 13.9365 4.29116C13.3073 3.28409 12.4323 2.45362 11.3938 1.87788C10.3552 1.30213 9.18731 1.00004 7.99986 1.00003Z" fill="var(--mds-color-theme-text-primary-normal)" fillOpacity="0.95" />
                    </svg>
                    <span>{t("label.signOut")}</span>
                  </Flex>
                </Item>
              </Menu>
            </MenuTrigger>
        </div> 
      </div>
    </div>
  );
};
