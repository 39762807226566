import { IWebexIntContact } from '@webex/component-adapter-interfaces/dist/esm/src';
import {useMemo, useState } from 'react';
import {Text, ButtonPill, ListNext as List, ListItemBase, ListItemBaseSection, ListHeader, Flex } from '@momentum-ui/react-collaboration';
import useWebexClasses from '../packages/widgets/src/hooks/useWebexClasses';
import { useTranslation } from 'react-i18next';
import { Logger } from '../packages/logger/src/Logger';
import { submitMetrics} from '../packages/widgets/src/services/WebexMetricsService';
import { audioCall, crossLaunchCallMetric, mobileChatExtension} from '../packages/widgets/src/utils/MetricUtils';
import { IWebexIntCallableEntity } from '../packages/adapter-interfaces/src/';
import { CLIENT_TYPE_IOS, CLIENT_TYPE_IPADOS } from '../packages/widgets/src/utils/commonUtils';
import { pages } from '@microsoft/teams-js';
// import { CLIENT_TYPE_IOS, CLIENT_TYPE_IPADOS } from '../packages/widgets/src/utils/commonUtils';

type Props = {
    user: IWebexIntContact;
    clientType: string;
  };

  export const MobileChatExtension = ({
    user , clientType
  }: Props) => {
    const callables = useMemo(
        () => [...user.phoneNumbers, ...user.emailAddresses],
        [user]
      );
    let userDetails: IWebexIntCallableEntity[] = [];
    callables.map((item) => 
        {
            if(item?.address !== null && item?.address !== undefined && item?.address !== ""){
                userDetails.push(item);
            }
        }
    );
    const [cssClasses, sc] = useWebexClasses('test-card');
    const { t } = useTranslation();

    const makeCrossLaunchCall = (address: string) => {
        if(address) {
            address = address.replace(/ /g, "");
            Logger.info( `Address value checking without space ${address}`);
            // To Be Removed Later once cross launch callback support available for android
            // if (clientType == CLIENT_TYPE_IOS || clientType == CLIENT_TYPE_IPADOS) {
            pages.backStack.navigateBack();
            // }
            window.open(getCallingUrl(address));
            submitMetrics(
                {
                  metricEvent: crossLaunchCallMetric,
                  callMadeSection: `${mobileChatExtension}`,
                  callType: `${audioCall}` 
                }
              );
        }
    };

    const getCallingUrl = (address: string) => {
        const url = 'webextel://login';
        // const userEmail = userDetails?.find((element)=>element.type==='mail')?.address;
        const callUrl = new URL(url);
        callUrl.searchParams.append('telephone', address);
        // TODO: Add cross launch back MS Teams
        // callUrl.searchParams.append('x-callback-url', `msteams://teams.microsoft.com/l/chat/0/0?users=${userEmail}`);
        callUrl.searchParams.append('x-source', "MS Teams Integration");
        // callUrl.searchParams.append('x-success', `msteams://teams.microsoft.com/l/chat/0/0?users=${userEmail}`);
        // callUrl.searchParams.append('x-cancel', `msteams://teams.microsoft.com/l/chat/0/0?users=${userEmail}`);
        callUrl.searchParams.append('autoJoin', "true");
        callUrl.searchParams.append('videoCall', "false");
        callUrl.searchParams.append('mutedCall', "false");
        callUrl.searchParams.append('audioOnlyCall', "false");

        Logger.info(`Calling URL: ${callUrl.href}`);
        return callUrl.href;
    }
    if (clientType == CLIENT_TYPE_IOS || clientType == CLIENT_TYPE_IPADOS) {
        return (
            <div className ={sc('mobile-extension-chat-extension')} >
            <List listSize={userDetails.length}>
                <ButtonPill className={sc('text-heading')} outline={false}  ghost={true} role='heading'  >
                    <Text type="body-secondary" className={sc('text-heading-text')}> { t('chooseNumber') } </Text>
                </ButtonPill>
                {userDetails.map((item, index) => (
                <ListItemBase onPress = {() => makeCrossLaunchCall(item?.address)} key={index} itemIndex={index} className={sc('list-item')}>
                    <ListItemBaseSection position="fill" className={sc('text-check')}>
                        <Flex direction="column" wrap="wrap" className={sc('content-wrap')}>
                            <Text type="body-secondary" className={sc('text-address-type')}> {t('label.address.'+ item?.type) }</Text>
                            <Text type="body-primary" className={sc('text-address')}> {item?.address}</Text>
                        </Flex>
                    </ListItemBaseSection>
                </ListItemBase>
                ))}
            </List>
        </div>
        );
    }
    else {
        return (
            <div className ={sc('mobile-extension-chat-extension-android')} >
            <List listSize={userDetails.length}>
                <ButtonPill className={sc('text-heading')} outline={false}  ghost={true} role='heading'  >
                    <Text type="body-secondary" className={sc('text-heading-text-android')}> { t('chooseNumberandroid') } </Text>
                </ButtonPill>
                {userDetails.map((item, index) => (
                <ListItemBase onPress = {() => makeCrossLaunchCall(item?.address)} key={index} itemIndex={index} className={sc('list-item-android')}>
                    <ListItemBaseSection position="fill" className={sc('text-check')}>
                        <Flex direction="column" wrap="wrap" className={sc('content-wrap')}>
                            <Text type="body-primary" className={sc('text-address')}> {item?.address}</Text>
                            <Text type="body-secondary" className={sc('text-address-type')}> {t('label.address.'+ item?.type) }</Text>
                        </Flex>
                    </ListItemBaseSection>
                </ListItemBase>
                ))}
            </List>
        </div>
        );
    }
};
