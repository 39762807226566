import { EndTimeSessionId, ICallHistoryRecord, UpdateMissedCallsResponse } from '@webex/component-adapter-interfaces/dist/esm/src';
import { useCallback, useContext, useEffect, useState } from 'react';
import { DispositionTypes } from '../../../adapter-interfaces/src/ICallHistoryAdapter';
import { RESPONSE_STATUS } from '../../../adapter-interfaces/src/IVoicemailAdapter';
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '../../../logger/src/LocalStorage';
import { Logger, formatError } from '../../../logger/src/Logger';
import { AdapterContext } from '../contexts/AdapterContext';

/**
 * Custom hook that returns call history data of the given ID.
 *
 * @param {string} userID  ID for user which to return data.
 * @returns {object} Returns {callHistory, lastUpdated} values
 */

const CH_STORAGE_KEY = 'callHistoryCachedData';

export default function useCallHistory(userID?: string) {
  const [callHistory, setCallHistory] = useState<ICallHistoryRecord[]>([]);
  const [lastUpdated, setLastUpdated] = useState<Date>();
  const [loading, setLoading] = useState<boolean>(true);
  const ctx = useContext(AdapterContext);

  const onError = (error: unknown) => {
    console.error('error', error);
    setLoading(false);
  };

  const onUpdate = useCallback((data: ICallHistoryRecord[]) => {
    removeLocalStorageItem(CH_STORAGE_KEY);
    setLastUpdated(new Date(Date.now()));
    setCallHistory(data);
    setLoading(false);
    if (data?.length > 0) {
      setLocalStorageItem(CH_STORAGE_KEY, data)
    }
  }, []);

  const refresh = useCallback(() => {
    if (ctx?.callHistoryAdapter?.refresh) {
      Logger.info(
        `WebexCallHistory.refresh()`
      );
      ctx?.callHistoryAdapter?.refresh(userID);

    }
  }, [userID, ctx?.callHistoryAdapter]);

  const updateMissedCalls = useCallback(() => {
    // Extract sessionId and endTime from call history for calls that are not read and have a disposition of "Missed"
    const endTimeSessionIds: EndTimeSessionId[] = callHistory
      .filter((record) => !record.isRead && (record.disposition === DispositionTypes.MISSED))
      .map((record) => ({
        endTime: record.endTime as string,
        sessionId: record.id as string, // TypeScript should recognize this as string due to the filter condition
      }));
    // Send the data to the adapter layer
    if (ctx?.callHistoryAdapter?.updateMissedCalls && endTimeSessionIds.length > 0) {
      Logger.info(
        `WebexCallHistory.updateMissedCalls(): count of missed calls : ${endTimeSessionIds.length}`
      );
      const subscription = ctx.callHistoryAdapter.updateMissedCalls(endTimeSessionIds).subscribe((res: UpdateMissedCallsResponse) => {
        if (res?.message === RESPONSE_STATUS.SUCCESS) {
          Logger.info(
            `WebexCallHistory.updateMissedCalls(): got status code with message: ${res?.statusCode} - ${res?.message}`
          );
          Logger.info(
            `WebexCallHistory.updateMissedCalls(): ${res?.data?.readStatusMessage}`
          );
          return;
        } else if (res.message === RESPONSE_STATUS.FAILURE) {
          Logger.info(
            `WebexCallHistory.updateMissedCalls(): got status code with message: ${res?.statusCode} - ${res?.message}`
          );
          Logger.error(
            `WebexCallHistory.updateMissedCalls(): got error message: '${formatError(
              res?.data?.error
            )}'.`
          );
          return;
        }
      })
      return () => {
        subscription?.unsubscribe();
      };
      // Assuming you have the webex_access_token available in your conte
      //return ctx.callHistoryAdapter.updateMissedCalls(endTimeSessionIds);
    } else {
      Logger.info(
        `WebexCallHistory.updateMissedCalls(): count of missed calls : ${endTimeSessionIds.length}`
      );
    }

  }, [callHistory, ctx?.callHistoryAdapter]);

  useEffect(() => {
    const subscription = ctx?.callHistoryAdapter
      ?.getAll(userID)
      .subscribe({ next: onUpdate, error: onError });

    return () => {
      subscription?.unsubscribe();
    };
  }, [ctx?.callHistoryAdapter, onUpdate, userID]);

  useEffect(() => {
    const storedData = getLocalStorageItem<any>(CH_STORAGE_KEY);
    if (storedData?.length > 0) {
      setLoading(false);
      setCallHistory(storedData)
    }
  }, []);

  return {
    lastUpdated,
    callHistory,
    refresh,
    loading,
    updateMissedCalls
  } as const;
}
