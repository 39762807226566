/**
 *
 * @param error
 */
export function formatError(error: any) {
    if (!error) {
      return '';
    }
  
    if (error.stack) {
      return `fileName: '${error.fileName}', name: '${error.name}' lineNumber:${error.lineNumber} columnNumber:${error.columnNumber} message:'${error.message}'.`;
    }
  
    if (error.message) {
      return error.message;
    }
    return JSON.stringify(error);
  }
  
  /**
   *
   * @param text
   * @param leadingCharactersToKeep
   */
  export function concise(text = '', leadingCharactersToKeep = 32) {
    if (text.length > leadingCharactersToKeep) {
      return `${text.substring(0, leadingCharactersToKeep - 1)}...`;
    }
    return text;
    // return text ? (text.length > leadingCharactersToKeep ? text.substring(0, leadingCharactersToKeep - 1) + '...' : text) : '';
  }

  export function downloadMobileLogs() {
    Logger.info(`downloadLog(): download log.`);
    let content = localStorage.getItem('log') || '';
    if (!content) {
        content = "";
    }

    const blob = new Blob([content], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = "msteams-integration.log";

    link.click();
} 

  export function downloadLogs() {
    let content = localStorage.getItem("log");
    if (!content) {
        content = "";
    }
    const anchorElement = document.createElement("a");
    anchorElement.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(content));
    anchorElement.setAttribute("download", "webex_calling_integration.log");
    anchorElement.style.display = "none";
    document.body.appendChild(anchorElement);
    anchorElement.click();
    document.body.removeChild(anchorElement);
  }
  
  export class Logger {
    static maxLogSizeInBytes: 500000;
    static isTestEnvironment = process.env.NODE_ENV === 'test';
    static log(level: string, msg: string) {
      const logMsg: string[] = [];
      logMsg.push(new Date().toISOString(), " ", "[", level, "]", " ", msg, "\n");
      if (level === "error") {
          //console.log(getErrorObject());
      }
      if (!this.isTestEnvironment && level !== "debug") {
          let log = localStorage.getItem("log");
          if (!log) {
              log = "";
          }
          if (log.length > Logger.maxLogSizeInBytes) {
              localStorage.removeItem("log");
              log = "";
          }
          localStorage.setItem("log", log + logMsg.join(""));
      }
      console.log(`${new Date().toISOString()} [${level}] ${msg}\n`);
  }
    static debug(msg: string) {
      Logger.log('debug', msg);
    }
  
    static info(msg: string) {
      Logger.log('info', msg);
    }
  
    static error(msg: string) {
      Logger.log('error', msg);
    }
  }
  
  export enum LOGGER {
    ERROR = "error",
    WARN = "warn",
    INFO = "info",
    LOG = "log",
    TRACE = "trace"
}