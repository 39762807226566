import { v4 as uuid } from 'uuid';
import { MsTeamsSDKAdapter } from '../../ms-teams-sdk-adapter/MsTeamsSDKAdapter';
import {
  IWebexIntCallableEntity, IWebexIntContact
} from '../../packages/adapter-interfaces/src';
import { Logger } from '../../packages/logger/src/Logger';
import { submitMetrics } from "../../packages/widgets/src/services/WebexMetricsService";
import {
  ExtensionIDs, fromRaw, getExtensionFirst,
  getExtensionSecond, toRaw, updateCiscoExtensionFirst,
  updateCiscoExtensionSecond
} from "./MSTeamsExtensionService";

export interface ISpeedDial {
  displayName: string;
  currentCallAddress: string;
}

import { ISpeedDialRecord } from "@webex/component-adapter-interfaces/dist/esm/src";
import { setLocalStorageItem } from '../../packages/logger/src/LocalStorage';
import { SD_ERROR_LIST_MSG, SD_NOTIFICATION, addSpeedDialMetric, editSpeedDialMetric, moveSpeedDialMetric, removeSpeedDialMetric } from "../../packages/widgets/src/utils/MetricUtils";

const Crypto = require("crypto-js");

declare global {
  interface Window {
    speedDailHeadVersion: any;
  }
}

function calculateChecksum(list: ISpeedDialRecord[]) {
  // const sortedList = [...list].sort(); // Sort the list to ensure consistent checksum calculation
  const serializedList = JSON.stringify(list);
  const checksum = Crypto.MD5(serializedList).toString(Crypto.enc.Base64);
  return checksum;
}

async function getCiscoExtensionSpeedDials(
  extensionId: string
): Promise<ISpeedDialRecord[]> {
  let getMethod = getExtensionFirst;
  if (extensionId === ExtensionIDs.CISCO_EXTENSION_SECOND) {
    getMethod = getExtensionSecond;
  }
  let speedDials: ISpeedDialRecord[] = [];
      try {
          let raw_data = JSON.parse(
              (await getMethod()).cisco_value
          );
          speedDials = raw_data.map(fromRaw);
      } catch (e) {
          speedDials = [];
      }
  return speedDials;
}

async function getCiscoExtensionFirstSpeedDials(): Promise<ISpeedDialRecord[]> {
  return getCiscoExtensionSpeedDials(ExtensionIDs.CISCO_EXTENSION_FIRST);
}

async function getCiscoExtensionSecondSpeedDials(): Promise<
  ISpeedDialRecord[]
> {
  return getCiscoExtensionSpeedDials(ExtensionIDs.CISCO_EXTENSION_SECOND);
}

async function fetchSpeedDials() {
  try {
    const [speedDialsFirst, speedDialsSecond] = await Promise.all([
      getCiscoExtensionFirstSpeedDials(),
      getCiscoExtensionSecondSpeedDials(),
    ]);
    const speedDials = [...speedDialsFirst, ...speedDialsSecond];

    // Do something with the speedDials data if needed

    return speedDials;
  } catch (error) {
    // Handle the error if needed
    console.error(error);
    return [];
  }
} 

export async function getSpeedDials(): Promise<ISpeedDialRecord[]> {
  try {
    const speedDials = await fetchSpeedDials();
    window.speedDailHeadVersion = calculateChecksum(speedDials);

    // Extract the ids from the speedDials array
    const idList = speedDials.filter(speedDial => !!speedDial.contactId)
      .map(speedDial => speedDial.contactId);

    // Get the contacts using getUsersByIds method
    const contacts = await MsTeamsSDKAdapter.searchContactsAdapter?.getUsersByIds?.(idList as string[]).catch(error => {
      setLocalStorageItem(SD_ERROR_LIST_MSG, true);
      window.dispatchEvent(new Event("storage"));
      Logger.error(error);
      return [];
    });
   
    // Create a map of contacts using their ids as keys for faster lookups
    const contactsMap = new Map<string, IWebexIntContact>();
    contacts?.forEach(contact => contactsMap.set(contact.id, contact));

    // Map the contacts to ISpeedDialRecord objects 
    const speedDialsWithContactsPromises = speedDials.map(async speedDial => {
      speedDial.isOutlookDeleted = false;
      if (speedDial.contactId) {
        speedDial.id = uuid();
        const contact = contactsMap.get(speedDial.contactId);
        if (contact) {
          return mapIWebexIntContactToISpeedDialRecord(speedDial, contact);
        } else {
          speedDial.isOutlookDeleted = true;
        }
      }
      if (!speedDial.id) { // Generate an ID for speed dials that don't have one
        speedDial.id = 'l_' + uuid();
      }
      return speedDial;
    });
    const speedDialsWithContacts = await Promise.all(speedDialsWithContactsPromises);
    return speedDialsWithContacts;
  } catch (error) {
    Logger.error(`MSTeamsSpeedDialService.getSpeedDials error: ${JSON.stringify(error)}`);
    throw error;
  }
}

// ["work", "mobile", "mail", "other", "sip". 'home']
  // ["Work", "Mobile", "Email", "Other", "SIP"]
  async function mapIWebexIntContactToISpeedDialRecord(speedDial: ISpeedDialRecord, contact: IWebexIntContact): Promise<ISpeedDialRecord> {
    speedDial.displayName = contact.name;
  
    //     //speedDial.photo = await contact.fetchAvatarUrl();
    const phones:IWebexIntCallableEntity[] = contact.phoneNumbers;
    const phone = phones.find(p => p.type === speedDial.phoneType);
    if (phone) {
      speedDial.currentCallAddress = phone.address;
    }
  
    const emails: IWebexIntCallableEntity[] = contact.emailAddresses;
    const email = emails.find(e => e.type === speedDial.phoneType && e.address);
    if (email) {
      speedDial.currentCallAddress = email.address;
    }
    return speedDial;
  }

  async function updateSpeedDials(speedDials: ISpeedDialRecord[], notificationType: string): Promise<void> {
    const halfway = Math.ceil(speedDials.length / 2);
    Promise.all([
      updateCiscoExtensionFirst(JSON.stringify(speedDials.slice(0, halfway).map(toRaw)), notificationType),
      updateCiscoExtensionSecond(JSON.stringify(speedDials.slice(halfway).map(toRaw)), notificationType),
    ]);
  }

export async function addSpeedDial(
  speedDial: ISpeedDialRecord
): Promise<ISpeedDialRecord[]> {
  const speedDials = await getSpeedDials();
  speedDials.push(speedDial);
  await updateSpeedDials(speedDials, SD_NOTIFICATION.SD_ADDED).catch(error => {
      Logger.error(error);
      return [];
    });
  submitMetrics(
    {
      metricEvent: addSpeedDialMetric,
    }
  );
  return speedDials;
}

export async function removeSpeedDial(
  speedDial: any
): Promise<ISpeedDialRecord[]> {
    await updateSpeedDials(speedDial, SD_NOTIFICATION.SD_DELETED);
    submitMetrics(
      {
        metricEvent: removeSpeedDialMetric,
      },
    );
  return speedDial;
}

export async function updateSpeedDial(
  updatedSpeedDials: ISpeedDialRecord[]
): Promise<ISpeedDialRecord[]> {
  // const speedDials = await fetchSpeedDials();
  // const checksumVersion = calculateChecksum(speedDials);
    // data in sync updating speed dial
    await updateSpeedDials(updatedSpeedDials, SD_NOTIFICATION.SD_EDITED);
    submitMetrics(
      {
        metricEvent: editSpeedDialMetric,
      }
    );
  
  // else {
  //   // data not in sync refresh speed dial
  //   setLocalStorageItem(SPEED_DIAL_NOT_UPTO_DATE, true);
  //   window.dispatchEvent(new Event("storage"));
  // }
    return [];
}

export async function reorderSpeedDial(
  speedDial: any
): Promise<ISpeedDialRecord[]> {
    await updateSpeedDials(speedDial, SD_NOTIFICATION.SD_REORDERED);
    submitMetrics(
      {
        metricEvent: moveSpeedDialMetric,
      }
    );
  return speedDial;
}