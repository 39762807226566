import { AlertBannerNext, ButtonSimple } from '@momentum-ui/react-collaboration';
import React from 'react';


type Props = {
  errorMessage: string | undefined;
  onClose?: () => void;
};

/**
 * Banner for displaying empty call history.
 *
 * @param {string} message The message to display to the user
 * @returns {React.Component} React component
 */
export const CommonErrorAlert = ({ errorMessage, onClose = undefined, }: Props) => {
  
  return (
    <AlertBannerNext
    isPilled
    color="error"
    isGrown
    image={<svg xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none">
      <path d="M13.7069 3.80762L10.1923 0.292973C10.0996 0.199806 9.98946 0.125939 9.8681 0.0756499C9.74673 0.0253609 9.6166 -0.000350911 9.48523 3.61692e-06H4.51404C4.38288 -0.000201602 4.25297 0.0255362 4.13179 0.0757354C4.01062 0.125935 3.90057 0.199604 3.80798 0.292504L0.291382 3.8091C0.198744 3.90163 0.125303 4.01156 0.0752776 4.13256C0.0252526 4.25356 -0.00037008 4.38325 -0.000120261 4.51418V9.48586C0.000631576 9.75054 0.105735 10.0042 0.292381 10.1919L3.808 13.7075C3.90051 13.8005 4.01055 13.8743 4.13174 13.9245C4.25293 13.9747 4.38287 14.0003 4.51405 14H9.48573C9.75046 13.9995 10.0043 13.8944 10.1918 13.7075L13.7064 10.1929C13.8938 10.0052 13.9993 9.75103 13.9999 9.48584V4.51466C13.9991 4.24961 13.8938 3.99557 13.7069 3.80762ZM2.95769 4.57375C2.51137 4.12743 2.51137 3.40382 2.95769 2.9575C3.404 2.51119 4.12762 2.51119 4.57393 2.9575L6.99837 5.38194L9.42264 2.95768C9.86895 2.51136 10.5926 2.51136 11.0389 2.95768C11.4852 3.40399 11.4852 4.12761 11.0389 4.57392L8.61461 6.99819L11.0389 9.42248C11.4852 9.86879 11.4852 10.5924 11.0389 11.0387C10.5926 11.485 9.86898 11.485 9.42266 11.0387L6.99837 8.61443L4.57391 11.0389C4.12759 11.4852 3.40398 11.4852 2.95766 11.0389C2.51135 10.5926 2.51135 9.86896 2.95766 9.42265L5.38213 6.99819L2.95769 4.57375Z" fill="#FC8B98" />
    </svg>}
    buttons={[
      <ButtonSimple key="1" onPress={onClose} data-test="vmError-banner-close">
        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
          <path d="M6.70705 6.00001L10.8536 1.85351C10.9 1.80708 10.9368 1.75197 10.9619 1.69131C10.987 1.63065 11 1.56564 11 1.49999C11 1.43433 10.987 1.36932 10.9619 1.30867C10.9368 1.24801 10.9 1.1929 10.8535 1.14648C10.8071 1.10005 10.752 1.06323 10.6913 1.0381C10.6307 1.01298 10.5657 1.00005 10.5 1.00005C10.4344 1.00005 10.3694 1.01299 10.3087 1.03811C10.248 1.06324 10.1929 1.10007 10.1465 1.14649L6.00001 5.29299L1.85351 1.14649C1.80718 1.09963 1.75205 1.0624 1.69128 1.03692C1.63051 1.01145 1.5653 0.998235 1.49941 0.998049C1.43351 0.997863 1.36823 1.0107 1.30732 1.03584C1.24641 1.06097 1.19106 1.09789 1.14447 1.14449C1.09788 1.19108 1.06095 1.24643 1.03583 1.30734C1.0107 1.36826 0.997859 1.43354 0.998049 1.49943C0.998238 1.56532 1.01145 1.63053 1.03693 1.6913C1.06241 1.75207 1.09965 1.8072 1.14651 1.85353L5.29301 6.00003L1.14651 10.1465C1.10008 10.1929 1.06326 10.2481 1.03813 10.3087C1.01301 10.3694 1.00008 10.4344 1.00008 10.5C1.00008 10.5657 1.01301 10.6307 1.03813 10.6914C1.06326 10.752 1.10008 10.8071 1.14651 10.8536C1.19293 10.9 1.24804 10.9368 1.3087 10.9619C1.36936 10.9871 1.43437 11 1.50002 11C1.56568 11 1.63069 10.9871 1.69134 10.9619C1.752 10.9368 1.80711 10.9 1.85354 10.8536L6.00004 6.70706L10.1465 10.8536C10.2403 10.9473 10.3675 11 10.5001 11C10.6326 11 10.7598 10.9473 10.8536 10.8536C10.9473 10.7598 11 10.6326 11 10.5C11 10.3674 10.9473 10.2403 10.8536 10.1465L6.70705 6.00001Z" fill="var(--mds-color-theme-text-warning-normal)" />
        </svg>
      </ButtonSimple>,
    ]}
  >
   {errorMessage}
  </AlertBannerNext>
  );
};
