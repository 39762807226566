import { LoadingSpinner } from '@momentum-ui/react-collaboration';
import { useEffect } from 'react';
import { webexAuth } from '../ms-teams-sdk/service/MsTeamsAuthenticationService';
import './Auth.scss';

/**
 *
 */
export const WebexAuthStartPage = () => {
  useEffect(() => {
    webexAuth();
  }, []);

  return (
    <div className="authorization-page">
      <LoadingSpinner scale={64} />
    </div>
  );
};
