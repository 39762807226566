import { AxiosInstance } from 'axios';
import GraphClientBuilder from './GraphClientBuilder';
import IntegrationClientBuilder from './IntegrationClientBuilder';
import RawClientBuilder from './RawClientBuilder';
import WebexClientBuilder from './WebexClientBuilder';

export class ClientBuilderFactory {
  private static rawClient: AxiosInstance | undefined = undefined;

  private static graphClient: AxiosInstance | undefined = undefined;

  private static webexClient: AxiosInstance | undefined = undefined;

  private static integrationClient: AxiosInstance | undefined = undefined;

  public static async getRawClient(): Promise<AxiosInstance> {
    if (!this.rawClient) {
      this.rawClient = await new RawClientBuilder().build();
    }
    return this.rawClient;
  }

  public static async getGraphClient(): Promise<AxiosInstance> {
    if (!this.graphClient) {
      this.graphClient = await new GraphClientBuilder().build();
    }
    return this.graphClient;
  }

  public static async getWebexClient(): Promise<AxiosInstance> {
    if (!this.webexClient) {
      this.webexClient = await new WebexClientBuilder().build();
    }
    return this.webexClient;
  }

  public static async getIntegrationClient(): Promise<AxiosInstance> {
    if (!this.integrationClient) {
      this.integrationClient = await new IntegrationClientBuilder().build();
    }
    return this.integrationClient;
  }
}
