export function getMultiLineLabel(lineNumber: number | null | undefined): string {
    // Check if lineNumber is null or undefined
    if (lineNumber === null || lineNumber === undefined || isNaN(lineNumber) ) {
      return ''; // Return empty string
    }
  
    // Check if lineNumber is within the expected range (1 to 50)
    if (lineNumber >= 1 && lineNumber <= 50) {
      return `L${lineNumber}`; // Return L1, L2, L3, ..., L50
    }
  
    // If lineNumber is outside the range, return an empty string (optional)
    return '';
  }