import { Logger } from '../../packages/logger/src/Logger';
import { AClientBuilder } from './ClientBuilder';

export default class IntegrationClientBuilder extends AClientBuilder {
  constructor() {
    super(
      `${process.env.JABBER_INTEGRATION_URL}/msteams/${process.env.APP_NAME}`
    );
  }

  addRetryConfiguration(): void {
    Logger.debug('No retry configuration on integration client');
  }

  addAuthorizationInterceptor(): void {
    Logger.debug('No authorization interceptor on integration client');
  }
}
