import { LoadingSpinner } from '@momentum-ui/react-collaboration';
import { useEffect, useState } from 'react';
import { authEnd } from '../ms-teams-sdk/service/MsTeamsAuthenticationService';
import { appReady, permissionGranted } from "../ms-teams-sdk/util/TobarUtils";
import './Auth.scss';

/**
 *
 */
export const AuthEnd = () => {
  const [adminConsent, setAdminConsent] = useState<boolean | null>(null);
  const [error, setError] = useState<string | null>(null);


  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const consent = urlParams.get("admin_consent");
    const errorParam = urlParams.get("error");
    if (consent === "True" && errorParam === null) {
      setAdminConsent(true);
    } else {
      setAdminConsent(false);
    }
    setError(errorParam);
  }, []);

 if (adminConsent === true) {
    return (
      <div className="admin-consent">
        <div className="icon"></div>
        <p className="title">{permissionGranted}</p>
        <p className="message">{appReady}</p>
      </div>
  )}
  else if (error) {
    return (
    <div className="admin-consent">
      <p className="title">{error}</p>
    </div>
    )
  } else {
    return (
      <div className="authorization-page">
        <LoadingSpinner scale={64} />
      </div>
    );
  }
};
