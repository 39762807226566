import { dialog } from '@microsoft/teams-js';
import { useCallback, useContext } from 'react';
import { Logger } from '../../../logger/src/Logger';
import { AdapterContext } from '../contexts/AdapterContext';
import { submitMetrics } from '../services/WebexMetricsService';
import { audioCall, crossLaunchCallMetric, MAKE_A_CALL_LABELS, videoCall } from '../utils/MetricUtils';

export const useMakeCall = () => {
  const ctx = useContext(AdapterContext);

  const makeCall = useCallback(
    (address: string, isVideo?: boolean, label?: string) => {
      submitMetrics(
        {
          metricEvent: crossLaunchCallMetric,
          callMadeSection: `${label}`,
          callType: `${isVideo? videoCall : audioCall}` 
        }
      ).then(() => {
        if (label?.trim() === MAKE_A_CALL_LABELS.ONE_TO_ONE_CHAT || label?.trim() === MAKE_A_CALL_LABELS.CHANNEL) {
          if (dialog.url.isSupported()) {
            Logger.info(`isMSFTDialogSupported() :: Is MSFT Dialog popup closing supported: ${dialog.url.isSupported()}`);
            dialog.url.submit();
          } else {
            Logger.info(`isMSFTDialogSupported() :: Is MSFT Dialog popup closing supported: ${dialog.url.isSupported()}`);
          }
        } else {
          Logger.info('MSFTDialogPopup() :: call not happend through MSFT Dialog popup');
        }
      });
      return ctx?.makeCallAdapter?.makeCall(address, !!isVideo);
    },
    [ctx?.makeCallAdapter?.makeCall]
  );

  return [makeCall];
};
