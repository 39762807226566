import { useEffect, useState } from 'react';
import {
    getLocalStorageItem, removeLocalStorageItem
} from "../../../../packages/logger/src";

export const useLocalStorageNotification = (storageKey: string, duration: number): boolean => {
  const [isShown, setIsShown] = useState<boolean>(false);

  useEffect(() => {
    const handleLocalStorageChange = (event: StorageEvent) => {
      if (getLocalStorageItem(storageKey)) {
        setIsShown(true);
        setTimeout(() => {
          setIsShown(false);
          removeLocalStorageItem(storageKey); // Remove the storage key
        }, duration);
      }
    };

    window.addEventListener('storage', handleLocalStorageChange);

    return () => {
      window.removeEventListener('storage', handleLocalStorageChange);
    };
  }, [storageKey, duration]);

  return isShown;
}; 