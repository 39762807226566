import axios, { AxiosInstance } from 'axios';
import { IAxiosRetryConfig } from 'axios-retry';
import {
  requestLoggerInterceptor,
  responseLoggerInterceptor,
  responseErrorLoggerInterceptor,
} from './Interceptors';

export interface IClientBuilder {
  client: AxiosInstance;
  addLoggingInterceptors(): void;
  addRetryConfiguration(retryConfig: IAxiosRetryConfig): void;
  getClient(): AxiosInstance;
  build(): Promise<AxiosInstance>;
}

export abstract class AClientBuilder implements IClientBuilder {
  client: AxiosInstance;

  constructor(baseURL: string, timeout = 30000) {
    this.client = axios.create({ baseURL, timeout });
  }

  abstract addRetryConfiguration(): void;

  abstract addAuthorizationInterceptor(): void;

  addLoggingInterceptors(): void {
    this.client.interceptors.request.use(requestLoggerInterceptor);
    this.client.interceptors.response.use(
      responseLoggerInterceptor,
      responseErrorLoggerInterceptor
    );
  }

  getClient(): AxiosInstance {
    return this.client;
  }

  async build(): Promise<AxiosInstance> {
    await this.addLoggingInterceptors();
    await this.addRetryConfiguration();
    await this.addAuthorizationInterceptor();
    return this.client;
  }
}
