/**
 *
 * @param options
 */
export function toQueryString(options: any) {
  if (!options) {
    return '';
  }
  const mapKeys = function (key: any) {
    return `${encodeURIComponent(key)}=${encodeURIComponent(options[key])}`;
  };
  return Object.keys(options).map(mapKeys).join('&');
}

export enum GraphURL {
    Extensions = '/v1.0/me/extensions',
    Contacts   = '/v1.0/me/contacts',
    Users = '/v1.0/users'
}