import { t } from "i18next";

export function formatSIPaddress(address: string | null): string {
  if (address && address.includes("sip:")) {
    return address.replace("sip:", "");
  } else {
    return address || ""; // Return an empty string if address is null or doesn't match
  }
}

export function handleUUID(str: string | null | undefined, sessionType: string): string {
  // Check if str is neither undefined nor null
  if (str == null) { // This checks for both null and undefined using non-strict equality
    return "";
  }
  // Check if the string is a version 4 UUID
  const isUUIDv4 = /^[\da-f]{8}-[\da-f]{4}-4[\da-f]{3}-[89ab][\da-f]{3}-[\da-f]{12}$/i.test(str);

  // Regular expression to validate an email address
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Check if the string is an email address
  const isEmail = emailRegex.test(str);

  // Check if the string starts with 'sip:' and ensure the result is a boolean
  const startsWithSip = !!str?.startsWith('sip:');

  if (sessionType === "SPARK" || isUUIDv4 || (isEmail && !startsWithSip)) {
    return "Call on Webex";
  }

  if (startsWithSip) {
    return formatSIPaddress(str);
  }

  // If none of the above conditions are met, return the original string
  return str;
}


export function handlePhoneNumberCheck(str: string | null | undefined) {
  if (str == null) { // This checks for both null and undefined
    return "";
  }
  return str;
}

export function handleNameAndAddressCheck(
  name: string | null | undefined,
  selfCallbackAddress: string | null | undefined,
  otherCallbackAddress: string | null | undefined
): string {
  if ((name == null || name === '') &&
      (selfCallbackAddress == null || selfCallbackAddress === '') &&
      (otherCallbackAddress == null || otherCallbackAddress === '')) {
    // Assuming t is a function that returns a localized string
    return t("calls.call_with_NoName_NoAddress");
  }
  return '';
}