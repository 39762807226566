import { ListNext, LoadingSpinner } from '@momentum-ui/react-collaboration';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { VM_STORAGE_KEYS } from '../../../adapter-interfaces/src';
import { getLocalStorageItem } from '../../../logger/src/LocalStorage';
import { VoicemailItem } from '../components/Voicemail';
import { useMakeCall } from '../hooks/useMakeCall';
import useVoicemails from '../hooks/useVoicemails';
import useWebexClasses from '../hooks/useWebexClasses';
import { voicemailLabel } from '../utils/MetricUtils';
import { EmptyVoiceMails } from './EmptyVoiceMails';
import { VoicemailNotConfigured } from './VoicemailNotConfigured';
import './WebexVoicemail.styles.scss';

export interface IWebexVoicemailProps {
  updateUnreadCount?: (unread: number) => void;
  style?: React.CSSProperties;
  noVoicemailMessage?: string | undefined;
  handleSetIsVmPlayErrorMsg: (newValue: boolean) => void;
  isLocaleGerman?: boolean;
}

export interface IWebexVoicemailHandle {
  refreshVoicemails: () => void;
}

/**
 * @param {string} obj.noVoicemailMessage The message when voicemails is empty
 * @param {React.CSSProperties} obj.style Custom style for overriding this component's CSS
 */

export const WebexVoicemail = forwardRef<
  IWebexVoicemailHandle,
  IWebexVoicemailProps
>(({ updateUnreadCount, style, noVoicemailMessage, handleSetIsVmPlayErrorMsg, isLocaleGerman }, ref) => {
  const { voicemails, loading, deleteVoicemail, readVoicemail, refresh, voicemailSrc, voicemailId, isShowVmPlayError, isShowVmConfigPage } =
    useVoicemails();

  useImperativeHandle(ref, () => ({
    refreshVoicemails() {
      refresh();
    },
  }));

  const [selectedVoicemailId, setSelectedVoicemailId] = useState<string>();
  const { t } = useTranslation('WebexVoicemail');
  const [makeCall] = useMakeCall();
  const [isEmpty, setIsEmpty] = useState<boolean>(false)
  const [isShowVoicemails, setIsShowVoicemails] = useState<boolean>(false)

  useEffect(() => {
    const newValue = isShowVmPlayError ? true : false;
    handleSetIsVmPlayErrorMsg(newValue);
  }, [isShowVmPlayError]);

  useEffect(() => {
    if (updateUnreadCount) {
      updateUnreadCount(
        voicemails.filter((voicemail) => voicemail.unread).length
      );
    }
    const activeTab = getLocalStorageItem(VM_STORAGE_KEYS.VM_TAB);
    const interval = setInterval(() => {
      activeTab  ? refresh() : clearInterval(interval)
      }, VM_STORAGE_KEYS.VM_ONE_MINUTE);
      !activeTab ? clearInterval(interval) : ''
      return () => clearInterval(interval); 
          
  }, [voicemails, loading, updateUnreadCount, refresh]);

  useEffect(() => {
    if (isShowVmConfigPage) {
      setIsShowVoicemails(false);
      setIsEmpty(false);
    } else if (voicemails?.length > 0) {
      setIsShowVoicemails(true);
      setIsEmpty(false);
    } else if (voicemails?.length === 0) {
      setIsEmpty(true);
      setIsShowVoicemails(false);
    }

  }, [voicemails, loading, refresh, isShowVmConfigPage]);
  const [cssClasses] = useWebexClasses('voicemail-widget');

  return (
    <div className={cssClasses} style={style}>
      {loading ? (<LoadingSpinner scale={48} data-testid="loading-spinner" />)
        : (
          <div>
            {(isEmpty && !isShowVmConfigPage) && (
              <div style={{ marginTop: '8rem' }}>
                <EmptyVoiceMails
                  noVoicemailMessage={noVoicemailMessage || t('noVoicemailMessage')}
                  data-testid="no-data-found"
                />
              </div>
            )}
             {isShowVmConfigPage && (
              <div>
              <VoicemailNotConfigured
              voicemailSetupMessage={t('voicemailSetupMessage')}
              voicemailConactAdminMessage={t('voicemailConactAdminMessage')}
               />
              </div>
            )}
            {(isShowVoicemails && !isShowVmConfigPage) && (
              <ListNext listSize={voicemails.length} shouldFocusOnPress={true}>
                {voicemails.map((voicemail, index) => (
                  <VoicemailItem
                    key={index}
                    voicemail={voicemail}
                    itemIndex={index}
                    onPress={() => setSelectedVoicemailId(voicemail.id)}
                    isSelected={selectedVoicemailId === voicemail.id}
                    onDelete={() => deleteVoicemail(voicemail.id)}
                    onRead={() => readVoicemail(voicemail.id, voicemail.unread)}
                    voicemailSrc={voicemail.id === voicemailId ? voicemailSrc : ''}
                    voicemailSrcLoader={voicemail.id === voicemailId ? true : false}
                    useMakeAudioCall={() => makeCall(voicemail.address, false, voicemailLabel)}
                    useMakeVideoCall={() => makeCall(voicemail.address, true, voicemailLabel)}
                    isLocaleGerman={isLocaleGerman}
                  />
                ))}
              </ListNext>
            )}
          </div>
        )}

    </div>
  );
});

WebexVoicemail.defaultProps = {
  updateUnreadCount: undefined,
  style: undefined,
  noVoicemailMessage: undefined,
};
