import { app } from '@microsoft/teams-js';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Logger } from '../src/packages/logger/src/Logger';
import { App } from './App';
import './localization/i18n';
import './style.scss';

Logger.debug(`App Version: ${process.env.APP_VERSION}`);
app.initialize().then(() => app.notifySuccess()).catch((error) => {
  Logger.error(error);
});

const rootElement = document.getElementById('root') as HTMLElement;
const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
