import { TextInput } from "@momentum-ui/react-collaboration";
import { IWebexIntContact } from "@webex/component-adapter-interfaces/dist/esm/src";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { WebexSearchContactsList } from "../WebexSearchContactsList/WebexSearchContactsList";
import { useMakeCall } from "../hooks/useMakeCall";
import { useSearchDropdown } from "../hooks/useSearchDropdown";
import useWebexClasses from "../hooks/useWebexClasses";
import {
  channelLabel,
  channelMembersSearch,
  NUMBER_PAD_FOCUSED,
  SEARCH_INPUT_FOCUSED,
  searchpadLabel,
  STORAGE,
} from "../utils/MetricUtils";
import "./WebexSearchContacts.styles.scss";

export interface IWebexSearchContactsProps {
  label?: string;
  noContactsFoundMessage?: string;
  style?: React.CSSProperties;
  minSearchLength?: number;
  onInputChange?: (input: string) => void;
  onUserSelect?: (user: IWebexIntContact | undefined) => void;
  onDropdownHide?: () => void;
  hideDropdownSource?: boolean;
  theme?: string;
}

export type WebexSearchContactsHandle = {
  appendValueToInput: (toAppend: string) => void;
};

/**
 * Description for this component.
 *
 * @param {IWebexSearchContactsProps} props Props for this component
 * @param props.style optional styles to add
 * @param props.label label to display in the search bar
 * @param props.noContactsFoundMessage text to output when no users are found
 * @param props.minSearchLength minimum text length required before searching
 * @param props.inputId id to make the search input
 */
export const WebexSearchContacts = forwardRef<
  WebexSearchContactsHandle,
  IWebexSearchContactsProps
>(
  (
    {
      label,
      noContactsFoundMessage,
      style,
      minSearchLength,
      onInputChange,
      onUserSelect,
      onDropdownHide,
      hideDropdownSource,
      theme,
    },
    ref
  ) => {
    const [cssClasses, sc] = useWebexClasses("search-contacts-widget");
    const [inputValue, setInputValue] = useState<string>("");
    const { t } = useTranslation("WebexSearchContacts");
    const placeholder = label || t("search");

    const [clickRef, showDropdown] = useSearchDropdown<HTMLDivElement>(
      inputValue,
      minSearchLength!
    );
    const [makeCall] = useMakeCall();
    const dropdownRef = useRef<HTMLDivElement>(null);
    const inputSearchRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => ({
      appendValueToInput(toAppend: string) {
        setInputValue((prev) => prev + toAppend);
      },
    }));

    useEffect(() => {
      if (!showDropdown && onDropdownHide) {
        onDropdownHide();
      }
    }, [showDropdown, onDropdownHide]);

    useEffect(() => {
      if (onInputChange) {
        onInputChange(inputValue);
      }
    }, [inputValue, onInputChange]);

    useEffect(() => {
      const handleStorageChange = () => {
        const storedValue = localStorage.getItem(NUMBER_PAD_FOCUSED);
        if (storedValue !== null) {
          const isNumberPadFocused = JSON.parse(storedValue);
          if (isNumberPadFocused && showDropdown) {
            //need to hide the showDropdown based on ref
            if (dropdownRef.current) {
              dropdownRef.current.style.display = "none";
              localStorage.removeItem(NUMBER_PAD_FOCUSED);
              setInputValue("");
            }
          }
        }
      };

      window.addEventListener(STORAGE, handleStorageChange);

      return () => {
        window.removeEventListener(STORAGE, handleStorageChange);
      };
    }, [showDropdown]);

    useEffect(() => {
      const handleInputSearchStorageChange = () => {
        const storedValue = localStorage.getItem(SEARCH_INPUT_FOCUSED);
        if (storedValue !== null) {
          const isInputSearchFocused = JSON.parse(storedValue);
          if (isInputSearchFocused) {
            //need to show focus on the search input based on ref
            if (inputSearchRef.current) {
              inputSearchRef.current.focus();
              localStorage.removeItem(SEARCH_INPUT_FOCUSED);
            }
          }
        }
      };

      window.addEventListener(STORAGE, handleInputSearchStorageChange);

      return () => {
        window.removeEventListener(STORAGE, handleInputSearchStorageChange);
      };
    }, []);

    const handleEnterKeyPress = (
      event: React.KeyboardEvent<HTMLInputElement>
    ) => {
      if (event.key === "Enter" || event.keyCode === 13) {
        if (inputValue) {
          makeCall(
            inputValue,
            false,
            label == channelMembersSearch ? channelLabel : searchpadLabel
          );
        }
      }
      if (event.key === "Escape" || event.keyCode === 27) {
        setInputValue("");
      }
    };

    return (
      <div className={cssClasses} style={style} ref={clickRef}>
        <TextInput
          className={sc("input")}
          placeholder={placeholder}
          aria-label={placeholder}
          value={inputValue}
          onChange={(value) => setInputValue(value)}
          autoComplete="off"
          onKeyDown={handleEnterKeyPress}
          ref={inputSearchRef}
          clearAriaLabel={t("clearButton")}
        />
        {showDropdown && (
          <div
            className={`${sc("dropdown")}`}
            data-testid="webex-search-contacts-dropdown"
            ref={dropdownRef}
          >
            <WebexSearchContactsList
              searchValue={inputValue}
              noContactsFoundMessage={
                noContactsFoundMessage || t("noContactsFound")
              }
              onUserSelect={onUserSelect}
              hideSource={hideDropdownSource}
              label={label}
              theme={theme}
            />
          </div>
        )}
      </div>
    );
  }
);

WebexSearchContacts.defaultProps = {
  label: undefined,
  noContactsFoundMessage: undefined,
  style: undefined,
  minSearchLength: 1,
  onInputChange: () => {},
  onUserSelect: () => {},
  onDropdownHide: () => {},
  hideDropdownSource: false,
};
