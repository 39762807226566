import { DirectionTypes, DispositionTypes, EndTimeSessionId, ICallHistoryAdapter, ICallHistoryRecord, IWebexCallHistoryResponse, UpdateMissedCallsResponse } from '@webex/component-adapter-interfaces/dist/esm/src';
import { Observable, ReplaySubject, from } from 'rxjs';
import {
  CH_STORAGE_KEYS,
  ERROR_RESPONSE_TEXT,
  RESPONSE_STATUS,
  SESSION_TYPES,
  VM_STORAGE_KEYS
} from '../../../../adapter-interfaces/src';
import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem
} from '../../../../logger/src';
import { listRecentCallEvents, updateMissedCalls } from '../../services/WebexCallHistoryService';
import { processCallingSpecifics } from '../../utils/handleCallingSpecifics';
import { formatSIPaddress, handleUUID, handlePhoneNumberCheck, handleNameAndAddressCheck } from '../../utils/handleUUID';
import { getMultiLineLabel } from '../../utils/handleMultiLineLabel';
import { Logger } from "../../../../logger/src/Logger";


/**
 * @description MS Teams Call History adapter handles making calls to backend
 * and formatting it for display on the client.
 */
export class WebexCallHistoryAdapter implements ICallHistoryAdapter {
  observables$: { [key: string]: ReplaySubject<ICallHistoryRecord[]> } = {};

  private webex_orgId = getLocalStorageItem<string>('webex_orgId') || ''; // Default to empty string if null or undefined

  // Multi-line check, only true if webex_orgId matches the expected value
  private multiLineCheck = (this.webex_orgId === '62559905-4b8a-4201-9464-0e073930d884' || this.webex_orgId === '1eb65fdf-9643-417f-9974-ad72cae0e10f');


  parseServerResponse(
    response: IWebexCallHistoryResponse
  ): ICallHistoryRecord[] {
    let output: ICallHistoryRecord[] = [];

    output = response?.data?.userSessions?.filter((item) => {
      return (
        item.sessionType !== SESSION_TYPES.CMR &&
        item.sessionType !== SESSION_TYPES.SPACEMEETING &&
        item.sessionType !== SESSION_TYPES.EVENTCENTERMEETING
      );
    }).map((value) => {
      const { id, direction, disposition, startTime, endTime, sessionType, durationSeconds } =
        value;
      const record: ICallHistoryRecord = {
        id,
        direction: direction as DirectionTypes,
        disposition: disposition as DispositionTypes,
        startTime,
        endTime,
        sessionType,
        callbackAddress: value?.self?.callbackInfo?.callbackAddress === RESPONSE_STATUS.UNKNOWN ? handlePhoneNumberCheck(value?.other?.callbackAddress) : value?.self?.callbackInfo?.callbackAddress ? handlePhoneNumberCheck(value?.self?.callbackInfo?.callbackAddress) : handlePhoneNumberCheck(value?.other?.callbackAddress),
        name: value?.other?.name ? formatSIPaddress(value?.other?.name) : handleNameAndAddressCheck(value?.other?.name, value?.self?.callbackInfo?.callbackAddress, value?.other?.callbackAddress),
        durationSeconds,
        phoneNumber: value?.self?.callbackInfo?.callbackAddress === RESPONSE_STATUS.UNKNOWN ? handleUUID(value?.other?.callbackAddress, sessionType) : value?.self?.callbackInfo?.callbackAddress ? handleUUID(value?.self?.callbackInfo?.callbackAddress, sessionType) : handleUUID(value?.other?.callbackAddress, sessionType),
        callingSpecific: processCallingSpecifics(value.callingSpecifics),
        isRead: value?.isRead ? true : false,
        lineNumber: this.multiLineCheck ? getMultiLineLabel(value?.self?.ucmLineNumber) : ''
      };
      return record;
    });
    Logger.info(
      `WebexCallHistoryAdapter.listRecentCallEvents(): MultiLine Check: ${this.multiLineCheck}`
    );
    Logger.info(
      `WebexCallHistoryAdapter.listRecentCallEvents(): webex_orgId: ${this.webex_orgId}`
    );
    return output;
  }

  /**
   * I handle updating the subscribed observable.
   * @param {string} ID The ID for the getAll method which updates the observable with new data.
   */
  refresh(ID: string) {
    const data$ = from(listRecentCallEvents());
    data$.subscribe((data) => {
      if (data.statusCode === RESPONSE_STATUS.STATUSCODE_200) {
        removeLocalStorageItem(CH_STORAGE_KEYS.CH_ERROR_LIST_MSG);
        window.dispatchEvent(new Event("storage"));
        Logger.info(
          `WebexCallHistoryAdapter.listRecentCallEvents(): Call History Length: ${data?.data?.userSessions?.length}`
        );
        this.observables$[ID].next(this.parseServerResponse(data));
        // this.observables$[ID].complete();
      } else {
        if (data.statusCode === RESPONSE_STATUS.STATUSCODE_401) {
          setLocalStorageItem(VM_STORAGE_KEYS.WEBEX_INSTANCE_TOKEN_EXPIRED, true);
          window.dispatchEvent(new Event("storage"));
        } else if (data.statusCode >= RESPONSE_STATUS.STATUSCODE_400 && data.statusCode <= RESPONSE_STATUS.STATUSCODE_500) {
          setLocalStorageItem(CH_STORAGE_KEYS.CH_ERROR_LIST_MSG, ERROR_RESPONSE_TEXT.ERROR_400_500);
          window.dispatchEvent(new Event("storage"));
        }
        this.observables$[ID].error(
          new Error(`Could not find call history for ID "${ID}"`)
        );
      }
    });
  }

  getAll(ID: string): Observable<ICallHistoryRecord[]> {
    if (!(ID in this.observables$)) {
      this.observables$[ID] = new ReplaySubject<ICallHistoryRecord[]>();
      this.refresh(ID);
    }
    return this.observables$[ID];
  }

  updateMissedCalls(endTimeSessionIds: EndTimeSessionId[]): Observable<UpdateMissedCallsResponse> {
    return from(updateMissedCalls(endTimeSessionIds));
  }

}
