import { t } from "i18next";
import {
    CALLING_SPECIFIC_REASON,
    ICallingSpecifics
} from '../../../adapter-interfaces/src';

export const processCallingSpecifics = (
    callingSpecifics?: ICallingSpecifics
  ): string => {
    if (
      callingSpecifics &&
      callingSpecifics.redirectionDetails &&
      callingSpecifics.redirectionDetails.reason
    ) {
      const { redirectionDetails } = callingSpecifics;
  
      switch (redirectionDetails.reason) {
        case CALLING_SPECIFIC_REASON.CALLQUEUE:
          if (redirectionDetails.name) {
            return `${t("callSpecific.queue")}: ${redirectionDetails.name}`;
          } else if (redirectionDetails.phoneNumber) {
            return `${t("callSpecific.queue")}: ${redirectionDetails.phoneNumber}`;
          } else if (redirectionDetails.sipUrl) {
            return `${t("callSpecific.queue")}: ${redirectionDetails.sipUrl}`;
          } else {
            return `${t("callSpecific.queue")}: ${t("calls.call_type_unknown")}`;
          }
  
        case CALLING_SPECIFIC_REASON.HUNTGROUP:
          if (redirectionDetails.name) {
            return `${t("callSpecific.huntGroup")}: ${redirectionDetails.name}`;
          } else if (redirectionDetails.phoneNumber) {
            return `${t("callSpecific.huntGroup")}: ${redirectionDetails.phoneNumber}`;
          } else if (redirectionDetails.sipUrl) {
            return `${t("callSpecific.huntGroup")}: ${redirectionDetails.sipUrl}`;
          } else {
            return `${t("callSpecific.huntGroup")}: ${t("calls.call_type_unknown")}`;
          }
  
        case CALLING_SPECIFIC_REASON.BUSY:
        case CALLING_SPECIFIC_REASON.NOANSWER:
        case CALLING_SPECIFIC_REASON.UNAVAILABLE:
        case CALLING_SPECIFIC_REASON.UNCONDITIONAL:
        case CALLING_SPECIFIC_REASON.TIMEOFDAY:
          if (redirectionDetails.name) {
            return `${t("callSpecific.forwadedBy")} ${redirectionDetails.name}`;
          } else if (redirectionDetails.phoneNumber) {
            return `${t("callSpecific.forwadedBy")} ${redirectionDetails.phoneNumber}`;
          } else if (redirectionDetails.sipUrl) {
            return `${t("callSpecific.forwadedBy")}: ${redirectionDetails.sipUrl}`;
          } else {
            return `${t("callSpecific.forwadedBy")} ${t("calls.call_type_unknown")}`;
          }
  
        default:
          return '';
      }
    } else {
      return '';
    }
  };