import { ButtonPill } from "@momentum-ui/react-collaboration";
import { useTranslation } from 'react-i18next';
import { WebexImage } from "./WebexImage";
import './DownloadWebex.scss';
import { FIRST_TIME_DOWNLOAD, appStoreMetric, googlePlayStoreMetric,  } from "../../packages/widgets/src/utils/MetricUtils";
import { setLocalStorageItem } from "../../ms-teams-sdk/util/LocalStorageUtil";
import { navigateToWebexUrl, downloadWebexIos, downloadWebexAndroid, CLIENT_TYPE_IOS, CLIENT_TYPE_IPADOS, CLIENT_TYPE_ANDROID, CLIENT_TYPE_TEAMSROOMSANDROID } from "../../packages/widgets/src/utils/commonUtils";
import { submitMetrics } from "../../packages/widgets/src/services/WebexMetricsService";

export const DownloadWebex = ({clientType, src} : {clientType: string, src : string}) =>  {
    const { t } = useTranslation();
    const downloadWebex = () => {
        let downloadUrl = navigateToWebexUrl;

        if (clientType == CLIENT_TYPE_ANDROID || clientType == CLIENT_TYPE_TEAMSROOMSANDROID){
            downloadUrl = downloadWebexAndroid
            submitMetrics(
              {
                metricEvent: googlePlayStoreMetric,
                downloadRedirectSection: `${src}`
              }
            );
        } else if (clientType == CLIENT_TYPE_IOS || clientType == CLIENT_TYPE_IPADOS){
            downloadUrl = downloadWebexIos;
            submitMetrics(
              {
                metricEvent: appStoreMetric,
                downloadRedirectSection: `${src}`
              }
            );
        }
        openUrlAndRefresh(downloadUrl);
    }

    const webexSignIn = () =>{
        openUrlAndRefresh(navigateToWebexUrl);
    }

    const openUrlAndRefresh = (url: string) => {
        const webexUrl = new URL(url);
        window.open(webexUrl.href);
        setLocalStorageItem(FIRST_TIME_DOWNLOAD, true);
        window.location.reload();
    }
    return (
      <div className='download_page'>

        <div className='download_page-container__mobile'>
          <div className="icon">
            <WebexImage/>
          </div>
          <div className="signInText">
            <p className="title">{ t('downloadWebex') }</p>
          </div>
          <div className="close">
            <ButtonPill onPress={downloadWebex}>
              {t('downloadText')}
            </ButtonPill>
          </div>

          <p className="footer">{t('appExists')} <a className="sign-in-link" onClick={webexSignIn} target="_blank" href='#'>{t('signInText')}</a></p> 
        </div>
    </div>
    );
  };



