import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IWebexCallHistoryHandle,
  IWebexSpeedDialsHandle,
  IWebexVoicemailHandle,
  WebexCallHistory,
  WebexDialpad,
  WebexSpeedDials,
  WebexVoicemail,
} from '../packages/widgets/src';

import { ButtonCircle, PopoverNext as Popover, TextToast } from '@momentum-ui/react-collaboration';
import { useMsTeamsTheme } from "../hooks/useMsTeamsTheme";
import { ISpeedDialRecord } from '@webex/component-adapter-interfaces/dist/esm/src';
import { TabBar, TabBarItem } from '../components/TabBar';
import { TopBar } from '../components/TopBar';
import { makeCrossLaunchCall } from '../ms-teams-sdk/service/CrossLaunchService';
import { getWebexTokens, presenceUserMapping } from '../ms-teams-sdk/service/MsTeamsAuthenticationService';
import {
  getLocalStorageItem,
  removeLocalStorageItem
} from '../ms-teams-sdk/util/LocalStorageUtil';
import { getMSTeamsContext } from '../ms-teams-sdk/util/MsTeamsContextUtil';
import {
  CH_STORAGE_KEYS, VM_STORAGE_KEYS, WEBEX_STORAGE_KEYS
} from '../packages/adapter-interfaces/src';
import { useLocalStorageNotification } from "../packages/widgets/src/hooks/useLocalStorageNotification";
import { initWebex } from "../packages/widgets/src/services/WebexInstanceService";
import { LOCALIZATION_CODES, SD_SUCCESS_NOTICATION } from "../packages/widgets/src/utils/MetricUtils";
import { CommonErrorAlert } from './Alerts/CommonErrorAlert';
import './LandingPage.scss';

/**
 * Main landing page
 *
 * @returns {JSX.Element} React element containing the tab pag
 */
export const LandingPage = (): JSX.Element => {
  useEffect( () => {
    presenceUserMapping();
  },[])
  const [theme] = useMsTeamsTheme();
  const [unreadMissedCalls, setUnreadMissedCalls] = useState<number>();
  const [unreadVoicemails, setUnreadVoicemails] = useState<number>();
  const [isVmPlayErrorMsg, setIsVmPlayErrorMsg] = useState<boolean>(false);
  const [isAddSpeedDialDisabled, setIsAddSpeedDialDisabled] = useState<boolean>(false);
  const [vmListErrMsg, setVmListErrMsg] = useState<string>('');
  const [chListErrMsg, setChListErrMsg] = useState<string>('');
  const [webexInstErrMsg, setWebexInstErrMsg] = useState<string>('');
  const [isShowVMTab, setIsShowVMTab] = useState<any>(true);
  const [isLocaleGerman, setIsLocaleGerman] = useState<boolean>(false);
  const { t } = useTranslation();
  const callHistoryRef = useRef<IWebexCallHistoryHandle>(null);
  const voicemailRef = useRef<IWebexVoicemailHandle>(null);
  const speedDialsRef = useRef<IWebexSpeedDialsHandle>(null);
  const speedDialAddButtonRef = useRef<HTMLButtonElement>(null);
  const userID = 'user1';

  const sdSuccessAdd = useLocalStorageNotification(
    SD_SUCCESS_NOTICATION.SD_SUCCESS_ADD,
    2000
  );
  const sdSuccessEdit = useLocalStorageNotification(
    SD_SUCCESS_NOTICATION.SD_SUCCESS_EDIT,
    2000
  );
  const sdSuccessDelete = useLocalStorageNotification(
    SD_SUCCESS_NOTICATION.SD_SUCCESS_DELETE,
    2000
  );
  const sdSuccessReorder = useLocalStorageNotification(
    SD_SUCCESS_NOTICATION.SD_SUCCESS_REORDER,
    2000
  );

  const reInitWebexInstance = async () => {
    try {
      const webexTokens = await getWebexTokens(false);
      if(!(window?.isWebexInitalized)) {
        initWebex(webexTokens?.access_token as string)
      }
    }
    catch(error) {
      throw error;
    }
  }

  const onHideHandler = () => {
    setIsVmPlayErrorMsg(false)
  };

  const onHideVmListErr = () => {
    setVmListErrMsg('');
    removeLocalStorageItem(VM_STORAGE_KEYS.VM_ERROR_LIST_MSG);
    window.dispatchEvent(new Event("storage"));
  }

  const onHideChListErr = () => {
    setChListErrMsg('');
    removeLocalStorageItem(CH_STORAGE_KEYS.CH_ERROR_LIST_MSG);
    window.dispatchEvent(new Event("storage"));
  }

  const onHideWebexInstErr = () => {
    setWebexInstErrMsg('');
    removeLocalStorageItem(WEBEX_STORAGE_KEYS.WEBEX_INSTANCE_ERROR);
    window.dispatchEvent(new Event("storage"));
  }

  const handleSetIsVmPlayErrorMsg = (newValue: boolean) => {
    setIsVmPlayErrorMsg(newValue);
  }

  const addSpeedDialDisabled = (isDisabled: boolean) => {
    setIsAddSpeedDialDisabled(isDisabled);
  }

  const fetchContextLocale = async () => {
    const context = await getMSTeamsContext();
    if (context?.app?.locale === LOCALIZATION_CODES.DE_DE) {
      setIsLocaleGerman(true)
    }
  };

  const handleRecentCallsTabPress = () => {
    callHistoryRef.current?.refreshCallHistory();
  }

  const handleVoicemailTabPress = () => {
   voicemailRef.current?.refreshVoicemails();
    callHistoryRef.current?.postMissedCallCount();
  } 

  const focusSpeedDialAddButton = () => {
    speedDialAddButtonRef.current?.focus();
    speedDialAddButtonRef.current?.classList.add("md-focus-ring-wrapper", "children");
  }

  useEffect(() => {
    const handleLocalStorageChange = (event: StorageEvent) => {
      const stringValue = getLocalStorageItem<string>(VM_STORAGE_KEYS.VM_ERROR_LIST_MSG);
      if (stringValue) {
        setVmListErrMsg(stringValue);
      } else {
        setVmListErrMsg('');
      }
    };
    window.addEventListener('storage', handleLocalStorageChange);

    return () => {
      window.removeEventListener('storage', handleLocalStorageChange);
    };
  }, []);

  useEffect(() => {
    const handleCHERRORLocalStorageChange = (event: StorageEvent) => {
      const stringValue = getLocalStorageItem<string>(CH_STORAGE_KEYS.CH_ERROR_LIST_MSG);
      if (stringValue) {
        setChListErrMsg(stringValue);
      } else {
        setChListErrMsg('');
      }
    };
    window.addEventListener('storage', handleCHERRORLocalStorageChange);

    return () => {
      window.removeEventListener('storage', handleCHERRORLocalStorageChange);
    };
  }, []);

  useEffect(() => {
    const handleWebexInstLocalStorageChange = (event: StorageEvent) => {
      const stringValue = getLocalStorageItem<string>(WEBEX_STORAGE_KEYS.WEBEX_INSTANCE_ERROR);
      if (stringValue) {
        setWebexInstErrMsg(stringValue);
      } else {
        setWebexInstErrMsg('');
      }
    };
    window.addEventListener('storage', handleWebexInstLocalStorageChange);

    return () => {
      window.removeEventListener('storage', handleWebexInstLocalStorageChange);
    };
  }, []); 

  useEffect(() => {
    const handleWebexInstUnAuthorizeUser = (event: StorageEvent) => {
      const isWebexInstanceTokenExpired = getLocalStorageItem<string>(VM_STORAGE_KEYS.WEBEX_INSTANCE_TOKEN_EXPIRED);
      if (isWebexInstanceTokenExpired) {
        removeLocalStorageItem(VM_STORAGE_KEYS.WEBEX_INSTANCE_TOKEN_EXPIRED);
        //resetting init window check as instance token is expired
        window.isWebexInitalized = false;
        reInitWebexInstance();
        voicemailRef.current?.refreshVoicemails();
        callHistoryRef.current?.refreshCallHistory();
     
      } 
    };
    window.addEventListener('storage', handleWebexInstUnAuthorizeUser);

    return () => {
      window.removeEventListener('storage', handleWebexInstUnAuthorizeUser);
    };
  }, []);

  useEffect(() => {
    const handleVoicemailTab = (event: StorageEvent) => {
      const isShowVMTabValue = getLocalStorageItem<string>(VM_STORAGE_KEYS.IS_HIDE_VM_TAB);
      if (isShowVMTabValue) {
        setIsShowVMTab(false);
      } else {
        setIsShowVMTab(true);
      }
    };
    window.addEventListener('storage', handleVoicemailTab);

    return () => {
      window.removeEventListener('storage', handleVoicemailTab);
    };
  }, []);

  useEffect(() => {
    fetchContextLocale();
  }, [])

  return (
    <div className="landing-page">
      <header className="landing-page__header">
        <TopBar/>
      </header>
      <section className="landing-page__content">
        <TabBar>
          <TabBarItem label={t('dialpad')}>
            <WebexDialpad theme={theme} />
          </TabBarItem>

          <TabBarItem
            label= {t('speedDial')}
            onActiveRightItem={
              <div className='tooltip-wrapper'>
              {!isAddSpeedDialDisabled &&
                <Popover
                    color="primary"
                    delay={[0, 0]}
                    placement="bottom"
                    showArrow={false}
                    trigger="mouseenter"
                    className="tooltip"
                    triggerComponent={
                      <ButtonCircle
                      aria-label={t('label.speed_dial.add')}
                      ghost
                      onPress={() => {
                        speedDialsRef.current?.showSearchSpeedDials();
                      }}
                      size={28}
                      ref={speedDialAddButtonRef}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 12 12" fill="none">
                        <path d="M11 5.5H6.5V1C6.5 0.867392 6.44732 0.740215 6.35355 0.646446C6.25979 0.552678 6.13261 0.5 6 0.5C5.86739 0.5 5.74021 0.552678 5.64645 0.646446C5.55268 0.740215 5.5 0.867392 5.5 1V5.5H1C0.867392 5.5 0.740215 5.55268 0.646447 5.64645C0.552679 5.74021 0.5 5.86739 0.5 6C0.5 6.13261 0.552679 6.25979 0.646447 6.35355C0.740215 6.44732 0.867392 6.5 1 6.5H5.5V11C5.5 11.1326 5.55268 11.2598 5.64645 11.3536C5.74021 11.4473 5.86739 11.5 6 11.5C6.13261 11.5 6.25979 11.4473 6.35355 11.3536C6.44732 11.2598 6.5 11.1326 6.5 11V6.5H11C11.1326 6.5 11.2598 6.44732 11.3536 6.35355C11.4473 6.25979 11.5 6.13261 11.5 6C11.5 5.86739 11.4473 5.74021 11.3536 5.64645C11.2598 5.55268 11.1326 5.5 11 5.5Z" fill="var(--mds-color-theme-text-primary-normal)" fillOpacity="0.95" />
                      </svg>
                    </ButtonCircle>
                    }
                    variant="small"
                  >
                    <p>{t('label.speed_dial.add')}</p>
                </Popover>
                }

              {isAddSpeedDialDisabled &&
                  <Popover
                      color="primary"
                      delay={[0, 0]}
                      placement="bottom"
                      showArrow={false}
                      trigger="mouseenter"
                      className="tooltip"
                      triggerComponent={
                        <ButtonCircle
                        className="btn-disabled"
                        onPress={() => {
                        }}
                        size={28}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 12 12" fill="none">
                          <path d="M11 5.75H6.25V1C6.25 0.933696 6.22366 0.870107 6.17678 0.823223C6.12989 0.776339 6.0663 0.75 6 0.75C5.9337 0.75 5.87011 0.776339 5.82322 0.823223C5.77634 0.870107 5.75 0.933696 5.75 1V5.75H1C0.933696 5.75 0.870107 5.77634 0.823223 5.82322C0.776339 5.87011 0.75 5.9337 0.75 6C0.75 6.0663 0.776339 6.12989 0.823223 6.17678C0.870107 6.22366 0.933696 6.25 1 6.25H5.75V11C5.75 11.0663 5.77634 11.1299 5.82322 11.1768C5.87011 11.2237 5.9337 11.25 6 11.25C6.0663 11.25 6.12989 11.2237 6.17678 11.1768C6.22366 11.1299 6.25 11.0663 6.25 11V6.25H11C11.0663 6.25 11.1299 6.22366 11.1768 6.17678C11.2237 6.12989 11.25 6.0663 11.25 6C11.25 5.9337 11.2237 5.87011 11.1768 5.82322C11.1299 5.77634 11.0663 5.75 11 5.75Z" fill="var(--mds-color-theme-text-primary-normal)" fillOpacity="0.4"/>
                        </svg>
                      </ButtonCircle>
                      }
                      variant="small"
                    >
                      <p>{t('label.speed_dial.max_reached')}</p>
                  </Popover>
                  }
                </div>
            }
          >
            <WebexSpeedDials
              onPress={(item: ISpeedDialRecord) => {
                makeCrossLaunchCall(
                  item?.currentCallAddress as string,
                  item.callType === 'handset'
                );
              }}
              onAddPress={() => {
                speedDialsRef.current?.showSearchSpeedDials();
              }}
              onAudioCallPress={(item: ISpeedDialRecord) => {  
                makeCrossLaunchCall(
                  item?.currentCallAddress as string,
                  true
                );
              }}
              onVideoCallPress={(item: ISpeedDialRecord) => {  
                makeCrossLaunchCall(
                  item?.currentCallAddress as string,
                  false
                );
              }}
              userID={userID}
              ref={speedDialsRef}
              contacts={[]}
              addSpeedDialDisabled={addSpeedDialDisabled}
              focusSpeedDialAddButton={focusSpeedDialAddButton}
              theme={theme}
            />
          </TabBarItem>
        </TabBar>
        <div className='landing-page__wrapperVMCH'>
        {webexInstErrMsg &&
            <div className='landing-page__bannerVM'>
              <CommonErrorAlert
                errorMessage={t('errorWebexInst')}
                onClose={onHideWebexInstErr}
              />
            </div>
          }
          {isVmPlayErrorMsg &&
            <div className='landing-page__bannerVM'>
              <CommonErrorAlert
                errorMessage={t('vmPlayErrorMessage')}
                onClose={onHideHandler}
              />
            </div>
          }
          {vmListErrMsg &&
            <div className='landing-page__bannerVM'>
              <CommonErrorAlert
                errorMessage={t('errorListVM')}
                onClose={onHideVmListErr}
              />
            </div>}
            {chListErrMsg &&
            <div className='landing-page__bannerVM'>
              <CommonErrorAlert
                errorMessage={t('errorListCH')}
                onClose={onHideChListErr}
              />
            </div>
            }
          <TabBar>
            <TabBarItem
              label={t('recentCalls')}
              badgeCount={unreadMissedCalls}
              onTabPress={handleRecentCallsTabPress} 
            >
              <WebexCallHistory
                noHistoryMessage={t('calls.no_calling_history')}
                userID={userID}
                ref={callHistoryRef}
                isLocaleGerman={isLocaleGerman}
                updateMissedCallCount={(unreadCount: number) =>
                  setUnreadMissedCalls(unreadCount)
                } 
              />
            </TabBarItem>
            {isShowVMTab &&
              <TabBarItem
                label={t('voicemail')}
                badgeCount={unreadVoicemails}
                onTabPress={handleVoicemailTabPress}
              >
                <WebexVoicemail
                  handleSetIsVmPlayErrorMsg={handleSetIsVmPlayErrorMsg}
                  updateUnreadCount={(unreadCount: number) =>
                    setUnreadVoicemails(unreadCount)
                  }
                  ref={voicemailRef}
                  isLocaleGerman={isLocaleGerman}
                />
              </TabBarItem>
            }
          </TabBar>
        </div>
      </section>
      <section role="status">
        {sdSuccessAdd && (
          <TextToast
          className='tostCenter'
          text={t('successAdd')}
          aria-live={t('successAdd')}
        />
          )}
          {sdSuccessDelete && (
          <TextToast
          className='tostCenter'
          text={t('successDelete')}
          aria-live={t('successDelete')}
        />
          )}
          {sdSuccessEdit && (
          <TextToast
          className='tostCenter'
          text={t('successEdit')}
          aria-live={t('successEdit')}
        />
          )}
          {sdSuccessReorder && (
          <TextToast
          className='tostCenter'
          text={t('successReorder')}
          aria-live={t('successReorder')}
        />
          )}
      </section>
    </div>
  );
};
