import i18n from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';
import { TeamsLanguageDetector } from './TeamsLanguageDetector';

try {
i18n
  // lazily load languages
  .use(
    resourcesToBackend((lng, ns, callback) => {
      import(`./locales/${lng}/${ns}.json`)
        .then((resources) => callback(null, resources))
        .catch((e) => callback(e, null));
    })
  )
  // detect user language
  .use(TeamsLanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    lowerCaseLng: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });
}catch (error) {
  console.error('Error initializing i18next:', error);
}

  

export default i18n;
