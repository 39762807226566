import memoize from 'memoizee';
import { app } from '@microsoft/teams-js';

/**
 * Returns the MS Teams context object, ensuring that the app has been initialized first
 *
 * @returns {Promise<app.Context>} MS Teams context promise
 */
export const getMSTeamsContext = memoize(async () => {
  await app.initialize();
  return app.getContext();
}, {});

/**
 * Loads the localization language from the microsoft teams context
 *
 * @returns {Promise<string>} localization language code
 */
export async function getLocalizationLanguage(): Promise<string> {
  const context = await getMSTeamsContext();
  return context.app.locale;
}

/**
 * Loads the theme from the microsoft teams context
 *
 * @returns {Promise<string>} teams theme string
 */
export async function getMSTeamsTheme(): Promise<string> {
  const context = await getMSTeamsContext();
  return context.app.theme;
}
