import {
  AvatarNext,
  ButtonPill,
  ContentSeparator,
  ListNext as List,
  ListHeader,
  ListItemBase,
  ListItemBaseSection,
  LoadingSpinner,
  SearchInput,
  Text
} from "@momentum-ui/react-collaboration";
import {
  ISpeedDialRecord,
  IWebexIntContact,
} from "@webex/component-adapter-interfaces/dist/cjs/src";
import React, { useCallback, useReducer, useState } from "react";
import FocusLock from "react-focus-lock";
import { useTranslation } from "react-i18next";
import { ISpeedDialItem } from "../components/SpeedDials";
import { useContactSearch } from "../hooks/useContactSearch";
import useWebexClasses from "../hooks/useWebexClasses";
import { THEME_CLASS, WEBEX_CALLING_THEME } from '../utils/ThemeUtils';

import { v4 as uuid } from "uuid";
import { CALLING_SPEED_DIAL_FOCUS_GROUP } from "../utils/MetricUtils";
import { removeBracketsAndContent } from "../utils/avatarInitials";
import "./WebexSpeedDialSearch.styles.scss";

type ISpeedDialSearchProps = {
  /* Custom classname for component */
  className?: string;
  items?: ISpeedDialRecord[];
  /* Triggered when search input changing */
  onSearch?: (value: string) => void;
  /* Triggered when add button is pressed */
  onAdd?: (item: ISpeedDialItem) => void;
  /* Triggered when search item is pressed */
  onPress?: (item: ISpeedDialItem) => void;
  searchInputRef?: React.RefObject<HTMLInputElement>;
  /* To show the modal header text */
  headerText?: string;
  theme?:string;
};

type State = {
  searchValue: string;
  items: ISpeedDialItem[];
  filteredItems: ISpeedDialItem[];
};

enum ActionTypes {
  FILTER_CONTACTS = "FILTER_CONTACTS",
}

type SearchAction = {
  type: typeof ActionTypes.FILTER_CONTACTS;
  payload: string;
};

type Actions = SearchAction;

const initialState = {
  searchValue: "",
  items: [],
  filteredItems: [],
};

/**
 * Handles sanitizing user input.
 *
 * @param {string} str The input string
 * @returns {string} THe input string sanitized
 */
function sanitizeString(str: string): string {
  const s: string = str.replace(
    /[^a-z\dáéíóúñü .,_-׳״ְֱֲֳִֵֶַָֻּׁー一-\u9FFF]/gim,
    ""
  );
  return s.trim();
}

/**
 * Search component reducer
 *
 * @param {State} state The current state
 * @param {Actions} action The action to perform
 * @returns {State} The updated state
 */
function reducer(state: State, action: Actions): State {
  const { type, payload } = action;

  const searchValue = sanitizeString(payload) as string;

  switch (type) {
    case ActionTypes.FILTER_CONTACTS:
      if (!payload) {
        return {
          ...state,
          searchValue,
          filteredItems: [],
        };
      }
      return {
        ...state,
        searchValue,
        filteredItems: state.items.filter((item: ISpeedDialItem) => {
          const regex = new RegExp(`${searchValue}`, "ig");
          return item?.displayName.match(regex);
        }),
      };
    default:
      throw new Error();
  }
}

type ISpeedDialListItem = {
  item: ISpeedDialItem;
  className?: string;
  onPress?: (item: ISpeedDialItem) => void;
  addLength?: number;
  itemIndex: number;
};
const SpeedDialListItem = ({
  itemIndex,
  addLength,
  item,
  className = undefined,
  onPress = undefined,
}: ISpeedDialListItem) => {
  const { t } = useTranslation("WebexSpeedDials");
  const assignIndex = addLength ? addLength + itemIndex : itemIndex;
  const handlePress = useCallback(() => {
    if (onPress) {
      onPress(item);
    }
  }, [item, onPress]);
  return (
    <ListItemBase
      itemIndex={assignIndex}
      isPadded
      shape="isPilled"
      size={50}
      className={className}
      onPress={handlePress}
    >
      <ListItemBaseSection position="start">
        <AvatarNext
          initials={removeBracketsAndContent(item?.name as string)}
          title={item?.name}
          size={32}
        />
      </ListItemBaseSection>
      <ListItemBaseSection position="middle">
        <Text
          aria-label={item?.name + `,` + t("voiceover.addSpeedDial.listItem")}
        >
          {item?.name}
        </Text>
      </ListItemBaseSection>
    </ListItemBase>
  );
};

/**
 * Speed Dial search component.
 *
 * @param {ISpeedDialSearchProps} obj The props for the component
 * @param {number} obj.items The index of the speed dial
 * @param {string} obj.className The classname for componentn
 * @param {Function} obj.onSearch Triggered when input is searched
 * @param {Function} obj.onAdd Triggered when add button is pressed
 * @param {Function} obj.onPress Triggered when search item is pressed
 * @returns {React.Component} A Search component
 * @class
 */
export const WebexSpeedDialSearch = ({
  items = [],
  className = undefined,
  onSearch = undefined,
  onAdd = undefined,
  onPress = undefined,
  searchInputRef,
  headerText,
  theme,
}: ISpeedDialSearchProps) => {
  const [cssClasses, sc] = useWebexClasses("speed-dial-search", className);
  const { t } = useTranslation("WebexSpeedDials");
  const [focusCount, setFocusCount] = useState(0);

  const [state, dispatch] = useReducer(reducer, { ...initialState, items });
  const [source, filteredContactResponse, isSearching] = useContactSearch(
    state.searchValue
  );
  const filterArray = filteredContactResponse.items;

  const searchContactsData = {
    directory: filterArray[source[0]],
    outlook: filterArray[source[1]],
  };

  const createHeaderStr = t("search.labels.header", {
    searchValue: state.searchValue,
  });

  const handleChange = (e: string) => {
    if (onSearch) {
      onSearch(e);
    }
    dispatch({ type: ActionTypes.FILTER_CONTACTS, payload: e });
  };

  const handlePress = (item: ISpeedDialItem) => {
    item.contactId = item.id;
    item.id = uuid();
    if (onPress) {
      onPress(item);
    }
  };

  /* Not in use */
  //const globalIndex = 0;

  const handleAdd = () => {
    const newItem: ISpeedDialItem = {
      id: "0",
      displayName: state.searchValue,
    };

    if (onAdd) {
      onAdd(newItem);
    }
  };

  const handleOnKeyDown = () => {
    setFocusCount((prevFocusCount) =>
      prevFocusCount === 0 ? 1 : prevFocusCount + 1
    );
  };

  return (
    <FocusLock group={CALLING_SPEED_DIAL_FOCUS_GROUP}>
      <div className={cssClasses}>
        <SearchInput
          autoFocus
          autoComplete="off"
          placeholder={t("voiceover.addSpeedDial.searchLabel")}
          aria-label={
            focusCount === 0
              ? t("webex.calling") +
                "," +
                headerText +
                "," +
                t("voiceover.addSpeedDial.windowDialog") +
                t("voiceover.addSpeedDial.searchLabel")
              : state.searchValue
          }
          name="searchContactsInput"
          onChange={handleChange}
          ref={searchInputRef}
          clearButtonAriaLabel={t("search.labels.clear")}
          className={sc("search-input")}
          onKeyDown={handleOnKeyDown}
        />

        {state.searchValue && (
          <div className={sc("popover")}>
            {isSearching && (
              <LoadingSpinner
                scale={32}
                className={sc("loading")}
                data-testid={`loading-spinner-${source}`}
              />
            )}
            {!isSearching && (
              <div>
                <div className={sc("popover-list")}>
                <div className={`${sc("list-container")} ${ theme === WEBEX_CALLING_THEME.DARK ? THEME_CLASS.DARK : theme === WEBEX_CALLING_THEME.LIGHT ? THEME_CLASS.LIGHT : " " }`}>
                    <List
                      className={sc("list")}
                      id="searchContactsList"
                      listSize={
                        searchContactsData?.directory?.length +
                        searchContactsData?.outlook?.length
                      }
                    >
                      {/* Directory Contacts List */}
                      <div
                        role="list"
                        aria-label={t("search.labels.directory")}
                      >
                        {searchContactsData?.directory?.length > 0 && (
                          <>
                            <ListHeader
                              key="searchContactsDirectoryHeader"
                              className={sc("item-header")}
                            >
                              <ListItemBaseSection position="middle">
                                <Text type="subheader-secondary">
                                  {t("search.labels.directory")}
                                </Text>
                              </ListItemBaseSection>
                            </ListHeader>

                            {searchContactsData?.directory.map(
                              (item: IWebexIntContact | any, index) => (
                                <SpeedDialListItem
                                  itemIndex={index}
                                  key={`search-item-${item.id}`}
                                  className={sc("item")}
                                  onPress={handlePress}
                                  item={item}
                                />
                              )
                            )}
                          </>
                        )}
                      </div>
                      {/* Outlook Contacts List */}
                      <div role="list" aria-label={t("search.labels.outlook")}>
                        {searchContactsData?.outlook?.length > 0 && (
                          <>
                            <ListHeader
                              key="searchContactsOutlookHeader"
                              className={sc("item-header")}
                            >
                              <ListItemBaseSection position="fill">
                                <Text type="subheader-secondary">
                                  {t("search.labels.outlook")}
                                </Text>
                              </ListItemBaseSection>
                            </ListHeader>
                            {searchContactsData?.outlook.map(
                              (item: IWebexIntContact | any, index) => (
                                <SpeedDialListItem
                                  itemIndex={index}
                                  addLength={
                                    searchContactsData?.directory?.length
                                  }
                                  key={`search-item-${item.id}`}
                                  className={sc("item")}
                                  onPress={handlePress}
                                  item={item}
                                />
                              )
                            )}
                          </>
                        )}
                      </div>
                    </List>
                  </div>
                  <ContentSeparator />
                  <div className={sc("button-container")}>
                    <List listSize={1}>
                      <ListHeader
                        key="searchContactsCreateHeader"
                        className={sc("item-header")}
                      >
                        <Text type="subheader-secondary">
                          {createHeaderStr}
                        </Text>
                      </ListHeader>

                      <ButtonPill
                        ghost
                        className={sc("create-button")}
                        key="searchContactsCreateButton"
                        onPress={handleAdd}
                      >
                        <ListItemBase onPress={handleAdd}>
                          <ListItemBaseSection
                            position="start"
                            className={sc("create-text")}
                          >
                            {t("search.labels.create")}
                            <span className={sc("create-label")}>
                              {` '${state.searchValue}'`}
                            </span>
                          </ListItemBaseSection>
                          <ListItemBaseSection position="end">
                            {/* <IconNext name="participant-add" scale={20} /> */}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="16"
                              viewBox="0 0 15 16"
                              fill="none"
                            >
                              <path
                                d="M13 11.5003H11.5V10.0003C11.5 9.8677 11.4473 9.74052 11.3536 9.64675C11.2598 9.55299 11.1326 9.50031 11 9.50031C10.8674 9.50031 10.7402 9.55299 10.6464 9.64675C10.5527 9.74052 10.5 9.8677 10.5 10.0003V11.5003H9C8.86739 11.5003 8.74022 11.553 8.64645 11.6468C8.55268 11.7405 8.5 11.8677 8.5 12.0003C8.5 12.1329 8.55268 12.2601 8.64645 12.3539C8.74022 12.4476 8.86739 12.5003 9 12.5003H10.5V14.0003C10.5 14.1329 10.5527 14.2601 10.6464 14.3539C10.7402 14.4476 10.8674 14.5003 11 14.5003C11.1326 14.5003 11.2598 14.4476 11.3536 14.3539C11.4473 14.2601 11.5 14.1329 11.5 14.0003V12.5003H13C13.1326 12.5003 13.2598 12.4476 13.3536 12.3539C13.4473 12.2601 13.5 12.1329 13.5 12.0003C13.5 11.8677 13.4473 11.7405 13.3536 11.6468C13.2598 11.553 13.1326 11.5003 13 11.5003Z"
                                fill="var(--mds-color-theme-text-primary-normal)"
                                fillOpacity="0.95"
                              />
                              <path
                                d="M8.42334 13.8568C5.89892 14.2552 3.05615 13.8255 1.95117 12.8802C1.8175 12.7833 1.70748 12.6574 1.62935 12.512C1.55122 12.3665 1.507 12.2053 1.5 12.0403C1.5 9.57941 3.78955 7.50031 6.5 7.50031C7.65489 7.50019 8.77773 7.88008 9.69531 8.58136C9.80034 8.6623 9.93321 8.6982 10.0647 8.68117C10.1962 8.66413 10.3155 8.59556 10.3965 8.49054C10.4774 8.38551 10.5133 8.25263 10.4963 8.12114C10.4793 7.98964 10.4107 7.8703 10.3057 7.78936C9.75504 7.3671 9.1374 7.04034 8.47852 6.82272C9.01753 6.40914 9.41339 5.83704 9.61044 5.18684C9.80748 4.53664 9.79582 3.84104 9.57708 3.19782C9.35834 2.55459 8.94352 1.99609 8.39094 1.60081C7.83836 1.20554 7.1758 0.993377 6.4964 0.994143C5.817 0.994909 5.15492 1.20857 4.60323 1.60509C4.05155 2.0016 3.63799 2.56104 3.4207 3.20476C3.20341 3.84847 3.19331 4.5441 3.39183 5.19385C3.59034 5.8436 3.98749 6.41481 4.52744 6.82717C2.21069 7.60049 0.5 9.67272 0.5 12.0403C0.506618 12.3498 0.581942 12.6539 0.72052 12.9306C0.859098 13.2073 1.05746 13.4498 1.30127 13.6405C2.40967 14.5882 4.52393 14.9979 6.521 14.9979C7.20977 14.9997 7.89766 14.9486 8.57861 14.8451C8.6435 14.8349 8.70575 14.812 8.7618 14.7777C8.81785 14.7435 8.8666 14.6985 8.90528 14.6454C8.94396 14.5923 8.9718 14.5321 8.98721 14.4683C9.00262 14.4044 9.00531 14.3382 8.99511 14.2733C8.98492 14.2084 8.96204 14.1461 8.92779 14.0901C8.89354 14.034 8.84858 13.9853 8.79548 13.9466C8.74239 13.9079 8.6822 13.8801 8.61834 13.8647C8.55449 13.8493 8.48823 13.8466 8.42334 13.8568ZM4.25 4.25031C4.25 3.8053 4.38196 3.37028 4.62919 3.00027C4.87643 2.63026 5.22783 2.34187 5.63896 2.17158C6.0501 2.00128 6.5025 1.95672 6.93895 2.04354C7.37541 2.13036 7.77632 2.34465 8.09099 2.65932C8.40566 2.97398 8.61995 3.3749 8.70677 3.81135C8.79358 4.24781 8.74903 4.70021 8.57873 5.11134C8.40843 5.52248 8.12004 5.87388 7.75003 6.12111C7.38002 6.36835 6.94501 6.50031 6.5 6.50031C5.90346 6.49965 5.33154 6.26239 4.90973 5.84058C4.48791 5.41876 4.25065 4.84684 4.25 4.25031Z"
                                fill="var(--mds-color-theme-text-primary-normal)"
                                fillOpacity="0.95"
                              />
                            </svg>
                          </ListItemBaseSection>
                        </ListItemBase>
                      </ButtonPill>
                    </List>
                  </div>
                  {/* {createHeaderStr && (
                  <Text type="subheader-secondary">{createHeaderStr}</Text>
                )} */}
                  {/* Create speed dial action */}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </FocusLock>
  );
};
