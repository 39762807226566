import { ICallForwardSettingValueResponse } from "@webex/component-adapter-interfaces/dist/esm/src";
// import { createCallSettingsClient } from "@webex/web-calling-sdk";
import { createCallSettingsClient } from '@webex/calling';
import { LOGGER, Logger } from "../../../../packages/logger/src";
import { IBusinessNumberResponse } from "../../../adapter-interfaces/src/IBusinessNumber";
import { WORK_TYPE } from "../utils/MetricUtils";
async function initCallSettingslList() {
  const booleanVal = process.env.WEBEX_CF_BOOLEAN_VAL;
  const callForwardBoolVal = (booleanVal == "true" ? true : false);
  const callSettingsClient = createCallSettingsClient((window as any)?.webex, {
    level: LOGGER.INFO,
  },
  callForwardBoolVal
  );
  const myUserDetails: IBusinessNumberResponse = await window?.webex?.people._getMe();
  const businessnum = myUserDetails?.phoneNumbers?.find(phoneNumber => phoneNumber?.type === WORK_TYPE.ITEM_TYPE);
  const businessVal = businessnum?.value ? businessnum?.value.replace(/[+\s-]/g, "") : '';
  Logger.info(`business Num ${businessVal}`);
  const response = await callSettingsClient.getCallForwardAlwaysSetting(businessVal);
  const res = response as ICallForwardSettingValueResponse;
  return res as ICallForwardSettingValueResponse
}

export async function getCallSetting(): Promise<ICallForwardSettingValueResponse> {
  if (window?.isWebexInitalized) {
    return initCallSettingslList();
  } else {
    await new Promise((f) => setTimeout(f, 1000));
    return getCallSetting();
  }
}
