import { LOGGER, Logger } from "../../../../packages/logger/src";
// import { createCallHistoryClient, EVENT_KEYS } from "@webex/web-calling-sdk";
import { COMMON_EVENT_KEYS, createCallHistoryClient } from '@webex/calling';
import { EndTimeSessionId, UpdateMissedCallsResponse } from "@webex/component-adapter-interfaces/dist/esm/src";
import {
  DATE,
  IWebexCallHistoryResponse,
  LIMIT,
  SORT,
  SORT_BY
} from "../../../adapter-interfaces/src";
import { submitMetrics } from "../services/WebexMetricsService";
import { recentCallsListLatencyMetric } from "../utils/MetricUtils";
import { EventType, eventEmitter } from "../utils/eventEmitter";
/**
 *
 */
let makeRegister = true;
async function initRecentCalls(): Promise<IWebexCallHistoryResponse> {
  let recentCallsListLatency;
  const startTime = new Date();
  let endTime;
  let response;

  const callHistoryClient: any = createCallHistoryClient((window as any).webex, {
    level: LOGGER.INFO,
  });
  if (makeRegister) {
    callHistoryClient.on(
      COMMON_EVENT_KEYS.CALL_HISTORY_USER_SESSION_INFO,
      (sessionData: any) => {
        Logger.info(
          `Users recent session data from service: '${sessionData.data.userSessions.userSessions[0]}'`
        );
        eventEmitter.dispatch(EventType.AUTO_REFRESH, "autorefresh");
      }
    );
    callHistoryClient.on(
      COMMON_EVENT_KEYS.CALL_HISTORY_USER_VIEWED_SESSIONS,
      (sessionData: any) => {
        Logger.info(
          `Users read session data from service: '${sessionData.data.userReadSessions.userReadSessions[0]}'`
        );
        eventEmitter.dispatch(EventType.AUTO_REFRESH, "autorefresh");
      }
    );
    makeRegister = false;
  }
  response = await callHistoryClient.getCallHistoryData(
    DATE.DEFAULT,
    LIMIT.DEFAULT,
    SORT.DEFAULT,
    SORT_BY.DEFAULT
  );
  //TODO:: 
  endTime = new Date();
  recentCallsListLatency =
    Math.floor(endTime.getTime() - startTime.getTime()) / 1000;
  submitMetrics(
    {
      metricEvent: recentCallsListLatencyMetric,
      recentCallsListLatency: `${recentCallsListLatency}`,
    }
  );
  return response as unknown as IWebexCallHistoryResponse;

}

export async function listRecentCallEvents(): Promise<IWebexCallHistoryResponse> {
  if (window?.isWebexInitalized) {
    return initRecentCalls();
  } else {
    await new Promise(f => setTimeout(f, 1000));
    return listRecentCallEvents();
  }
}

export async function updateMissedCalls(
  endTimeSessionIds: EndTimeSessionId[]
): Promise<UpdateMissedCallsResponse> {
  const callHistoryClient = createCallHistoryClient((window as any).webex, {
    level: LOGGER.INFO,
  });
  try {
    const response = await callHistoryClient.updateMissedCalls(endTimeSessionIds);
    Logger.info(`WebexCallHistoryService:updateMissedCalls(): done.`);
    // Return the response which should be of type UpdateMissedCallsResponse
    return response as UpdateMissedCallsResponse;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}
