import { Logger } from '../../packages/logger/src/Logger';
import { AClientBuilder } from './ClientBuilder';

export default class RawClientBuilder extends AClientBuilder {
  constructor() {
    super(`${process.env.JABBER_INTEGRATION_URL}`);
  }

  addRetryConfiguration(): void {
    Logger.debug('No retry configuration on raw client');
  }

  addAuthorizationInterceptor(): void {
    Logger.debug('No authorization interceptor on raw client');
  }
}
