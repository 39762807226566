import * as MsTeamsUserService from './MsTeamsUserService';
import { IGraphUser } from './MsTeamsUserService';
import { ClientBuilderFactory } from '../remote/ClientBuilderFactory';
import {GraphURL} from '../util/URLUtils'

/**
 *
 */
export async function listAllContacts(): Promise<
  MsTeamsUserService.IGraphUser[]
> {
  const graphClient = await ClientBuilderFactory.getGraphClient();
  const response = await graphClient.get(
    `${GraphURL.Contacts}?$select=id,givenName,surname,displayName,mobilePhone,emailAddresses,businessPhones&$count=true&$top=50`
  );
  if (response?.status === 200){
    const data = response?.data?.value as MsTeamsUserService.IGraphUser[];
    //Process the data to derive the displayname
    const processedData = data?.map((contact) => {
      if (contact?.displayName) {
        return contact;
      } else {
        const displayName = `${contact?.givenName} ${contact?.surname}`;
        return { ...contact, displayName };
      }
    })
    return processedData;
  } else {
    let error:any = response;
    if (error.response?.status === 404) {
        return [] as IGraphUser[];
    }     
  }
  return Promise.reject(response);
}

/**
 *
 * @param query
 * @param limit
 */
export async function fetchContacts(
  query: string,
  limit = 15
): Promise<IGraphUser[]> {
  const escapedQuery = query
    ? encodeURIComponent(query.replace(/'/g, "''").trim())
    : query;
  const url = `${GraphURL.Contacts}?$select=id,displayName,givenName,surname,mobilePhone,emailAddresses,businessPhones&$count=true&$top=${limit}&$filter=startswith(givenName,'${escapedQuery}') or startswith(surName,'${escapedQuery}') or startswith(displayName,'${escapedQuery}')`;
  const graphClient = await ClientBuilderFactory.getGraphClient();
  const response = await graphClient.get(url);
  if (response?.status === 200){
    const data = response?.data?.value as IGraphUser[];
    //Process the data to derive the displayname
    const processedData = data?.map((contact) => {
      if (contact?.displayName) {
        return contact;
      } else {
        const displayName = `${contact?.givenName} ${contact?.surname}`;
        return { ...contact, displayName };
      }
    })
    return processedData;
  } else {
    let error:any = response;
    if (error.response?.status === 404) {
        return [] as IGraphUser[];
    }     
  }
  return Promise.reject(response);
}

/**
 *
 * @param contactId
 */
export async function getContact(contactId: string): Promise<IGraphUser | void> {
    const url = `${GraphURL.Contacts}/${encodeURIComponent(contactId)}?$select=id,displayName,mobilePhone,emailAddresses,businessPhones`;
    const graphClient = await ClientBuilderFactory.getGraphClient();
    const response = await graphClient.get(url);
    if (response.status === 200) {
        return response.data as IGraphUser;
    } else {
      let error:any = response;
      if (error.response?.status === 404) {
        return;
      }  
    }
    return Promise.reject(response);
}

/**
 * Fetches a contact's avatar using their contact ID
 *
 * @param {string} contactId contact's image to fetch
 * @returns {Promise<string>} image url
 */
export async function fetchContactAvatar(
  contactId: string
): Promise<string | undefined> {
  const url = `${GraphURL.Contacts}/${encodeURIComponent(contactId)}/photo/$value`;
  const graphClient = await ClientBuilderFactory.getGraphClient();
  const response = await graphClient.get(url, {
    responseType: 'blob',
  });
  if (response.status === 200) return URL.createObjectURL(response.data);
  return undefined;
}
