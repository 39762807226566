import { ISpeedDialRecord } from "@webex/component-adapter-interfaces/dist/esm/src";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { SpeedDialForm, SpeedDials } from "../components/SpeedDials";
import useSpeedDials, {
  addSpeedDial,
  clearSpeedDial,
  editSpeedDial,
  removeSpeedDial,
  reorderSpeedDial,
  selectSpeedDial,
  updateSpeedDial,
} from "../hooks/useSpeedDials";
import useWebexClasses from "../hooks/useWebexClasses";
import { WebexSpeedDialSearch } from "../WebexSpeedDialSearch/WebexSpeedDialSearch";

import FocusLock from "react-focus-lock";

import "./WebexSpeedDials.styles.scss";

import { LoadingSpinner } from "@momentum-ui/react-collaboration";
import {
  getLocalStorageItem,
  removeLocalStorageItem,
} from "../../../logger/src/LocalStorage";
import {
  IFormData,
  ISpeedDialEvents,
  SpeedDialErrorModal,
  SpeedDialModal, SpeedDialView
} from "../components/SpeedDials";
import { useLocalStorageErrorNotification } from "../hooks/useLocalStorageErrorNotification";
import { submitMetrics } from "../services/WebexMetricsService";
import {
  CALLING_SPEED_DIAL_FOCUS_GROUP,
  SD_ERROR_LIST_MSG,
  SD_ERROR_NOTIFICATION,
  SD_FAILURE_NOTICATION,
  SD_SUCCESS_NOTICATION,
  SPEED_DIAL_NOT_UPTO_DATE,
  audioCall,
  crossLaunchCallMetric,
  speedDialLabel,
  videoCall,
} from "../utils/MetricUtils";

export interface IWebexSpeedDialsProps extends ISpeedDialEvents {
  userID: string;
  contacts?: ISpeedDialRecord[];
  onSortEnd?: (items?: ISpeedDialRecord[]) => void;
  onAddPress?: () => void;
  style?: React.CSSProperties;
  addSpeedDialDisabled: (isDisabled: boolean) => void;
  listError?: boolean;
  focusSpeedDialAddButton?: () => void;
  theme?:string;
}

export interface IWebexSpeedDialsHandle {
  refreshSpeedDials?: () => void;
  showAddSpeedDial: () => void;
  showSearchSpeedDials: () => void;
}

/**
 * @description The summary of this component.
 * @param {IWebexSpeedDialsProps} param An object of IWebexSpeedDialsProps
 * @param {string} param.userID The current user ID
 * @param {Function} param.onPress Handler to call speed dial item is pressed.
 * @param {Function} param.onSortEnd Handler to call when sorting ends
 * @param {Function} param.onEditPress Function to call when edit action is pressed.
 * @param {Function} param.onRemovePress Function to call when remove action is pressed.
 * @param {Function} param.onVideoCallPress Function to call when video call button pressed.
 * @param {Function} param.onAudioCallPress Function to call when audio call button pressed.
 * @returns {React.Component} WebexSpeedDials component
 */
export const WebexSpeedDials = forwardRef<
  IWebexSpeedDialsHandle,
  IWebexSpeedDialsProps
>(
  (
    {
      userID,
      addSpeedDialDisabled,
      contacts = undefined,
      onPress = undefined,
      onSortEnd = undefined,
      onRemovePress = undefined,
      onEditPress = undefined,
      onAudioCallPress = undefined,
      onVideoCallPress = undefined,
      onAddPress = undefined,
      style = undefined,
      focusSpeedDialAddButton = undefined,
      theme
    },
    ref
  ) => {
    const { t } = useTranslation("WebexSpeedDials");
    const [cssClasses] = useWebexClasses("speed-dial-widget");
    const {
      speedDials,
      selectedItem,
      dispatch,
      selectedIndex,
      loading,
      refresh,
    } = useSpeedDials(userID, contacts);
    const [currentView, setCurrentView] = useState("list");
    const addHeaderText = t("modal.add.header");
    const editHeaderText = t("modal.edit.header");
    const [errorText, setErrorText] = useState<string>("");
    const [searchText, setSearchText] = useState();
    const [speedDialListError, setSpeedDialListError] =
      useState<boolean>(false);
    const searchInputRef = useRef<HTMLInputElement>(null);
    const sdFailureAdd = useLocalStorageErrorNotification(
      SD_FAILURE_NOTICATION.SD_FAILURE_ADD
    );
    const sdFailureDelete = useLocalStorageErrorNotification(
      SD_FAILURE_NOTICATION.SD_FAILURE_DELETE
    );
    const sdFailureEdit = useLocalStorageErrorNotification(
      SD_FAILURE_NOTICATION.SD_FAILURE_EDIT
    );
    const sdFailureReorder = useLocalStorageErrorNotification(
      SD_FAILURE_NOTICATION.SD_FAILURE_REORDER
    );
    const removeSdFailureStorageEvents = () => {
      removeLocalStorageItem(SD_FAILURE_NOTICATION.SD_FAILURE_ADD);
      removeLocalStorageItem(SD_FAILURE_NOTICATION.SD_FAILURE_DELETE);
      removeLocalStorageItem(SD_FAILURE_NOTICATION.SD_FAILURE_EDIT);
      removeLocalStorageItem(SD_FAILURE_NOTICATION.SD_FAILURE_REORDER);
    };

    useImperativeHandle(ref, () => ({
      showAddSpeedDial() {
        setCurrentView(SpeedDialView.ADDVIEW);
      },
      showSearchSpeedDials() {
        setCurrentView(SpeedDialView.SEARCHVIEW);
      },
    }));

    const handleEditPress = (item: ISpeedDialRecord) => {
      editSpeedDial(dispatch, item);
      setCurrentView(SpeedDialView.EDITVIEW);
      if (onEditPress) {
        onEditPress(item);
      }
    };

    const handlePress = (item: ISpeedDialRecord) => {
      selectSpeedDial(dispatch, item);
      if (onPress) {
        if (item?.callType == "video") {
          submitMetrics({
            metricEvent: crossLaunchCallMetric,
            callMadeSection: `${speedDialLabel}`,
            callType: `${videoCall}`,
          });
        } else {
          submitMetrics({
            metricEvent: crossLaunchCallMetric,
            callMadeSection: `${speedDialLabel}`,
            callType: `${audioCall}`,
          });
        }
        onPress(item);
      }
    };

    const handleCancel = () => {
      setCurrentView(SpeedDialView.LIST);
      clearSpeedDial(dispatch);
    };

    const handleSubmit = (data: IFormData) => {
      const index = speedDials.findIndex(
        (e: ISpeedDialRecord) => e.id === data.id
      );
      if (index > -1) {
        updateSpeedDial(dispatch, data);
      } else {
        addSpeedDial(dispatch, data);
      }

      setCurrentView(SpeedDialView.LIST);
    };

    const handleSortEnd = (data: ISpeedDialRecord[]) => {
      if (onSortEnd) {
        onSortEnd(data);
      }
      reorderSpeedDial(dispatch, data);
    };

    const handleAddExisting = (item: ISpeedDialRecord) => {
      selectSpeedDial(dispatch, item);
      setCurrentView(SpeedDialView.SEARCHADDVIEW);
    };

    const handleAddNew = (data: any) => {
      setSearchText(data?.displayName);
      clearSpeedDial(dispatch);
      setCurrentView(SpeedDialView.ADDVIEW);
    };

    const handleRemovePress = (id: string) => {
      if (onRemovePress) {
        onRemovePress(id);
      }
      removeSpeedDial(dispatch, id);
    };

    const handleAudioCallPress = (item: ISpeedDialRecord) => {
      if (onAudioCallPress) {
        submitMetrics({
          metricEvent: crossLaunchCallMetric,
          callMadeSection: `${speedDialLabel}`,
          callType: `${audioCall}`,
        });
        onAudioCallPress(item);
      }
    };
    const handleVideoCallPress = (item: ISpeedDialRecord) => {
      if (onVideoCallPress) {
        submitMetrics({
          metricEvent: crossLaunchCallMetric,
          callMadeSection: `${speedDialLabel}`,
          callType: `${videoCall}`,
        });
        onVideoCallPress(item);
      }
    };

    const usedInPhoneTypes = (contactId: string) => {
      return speedDials
        .filter((item) => item.contactId === contactId)
        .map((item) => item.phoneType) as string[];
    };

    const handleEnterKeyPress = (
      event: React.KeyboardEvent<HTMLButtonElement>
    ) => {
      if (event.key === "Enter" || event.keyCode === 13) {
        setTimeout(() => {
          focusSpeedDialAddButton && focusSpeedDialAddButton();
        }, 200);
      }
    };

    useEffect(() => {
      const isDisabled = speedDials?.length >= 20 ? true : false;
      addSpeedDialDisabled(isDisabled);
    }, [speedDials]);

    useEffect(() => {
      const handleSpeedDialNotUptoDate = (event: StorageEvent) => {
        const isSpeedDialNotUptoDate = getLocalStorageItem<string>(
          SPEED_DIAL_NOT_UPTO_DATE
        );
        if (isSpeedDialNotUptoDate) {
          refresh();
          removeLocalStorageItem(SPEED_DIAL_NOT_UPTO_DATE);
        }
      };
      window.addEventListener("storage", handleSpeedDialNotUptoDate);
      return () => {
        window.removeEventListener("storage", handleSpeedDialNotUptoDate);
      };
    }, []);

    useEffect(() => {
      const handleLocalStorageChange = (event: StorageEvent) => {
        const stringValue = getLocalStorageItem<string>(SD_ERROR_LIST_MSG);
        if (stringValue) {
          setSpeedDialListError(true);
        } else {
          setSpeedDialListError(false);
        }
      };
      window.addEventListener("storage", handleLocalStorageChange);

      return () => {
        window.removeEventListener("storage", handleLocalStorageChange);
      };
    }, []);

    useEffect(() => {
      if (
        sdFailureAdd ||
        sdFailureDelete ||
        sdFailureEdit ||
        sdFailureReorder
      ) {
        setCurrentView(SpeedDialView.ERRORVIEW);
      }
      if (sdFailureAdd) {
        setErrorText(SD_ERROR_NOTIFICATION.SD_CREATE);
      } else if (sdFailureDelete) {
        setErrorText(SD_ERROR_NOTIFICATION.SD_DELETE);
      } else if (sdFailureEdit) {
        setErrorText(SD_ERROR_NOTIFICATION.SD_EDIT);
      } else if (sdFailureReorder) {
        setErrorText(SD_ERROR_NOTIFICATION.SD_REORDER);
      }
    }, [sdFailureAdd, sdFailureDelete, sdFailureEdit, sdFailureReorder]);

    useEffect(() => {
      if (currentView === SpeedDialView.SEARCHVIEW) {
        if (searchInputRef.current) {
          searchInputRef.current.focus();
        }
      }
    }, [currentView, setCurrentView]);

    useEffect(() => {
      const handleSpeedDialAddRefresh = (event: StorageEvent) => {
        const isSdAdd = getLocalStorageItem<string>(
          SD_SUCCESS_NOTICATION.SD_SUCCESS_ADD
        );
        if (isSdAdd) {
          refresh();
        }
      };
      window.addEventListener("storage", handleSpeedDialAddRefresh);

      return () => {
        window.removeEventListener("storage", handleSpeedDialAddRefresh);
      };
    }, []);

    return (
      <div className={cssClasses} style={style}>
        {/*  List View */}
        {loading && <LoadingSpinner scale={48} data-testid="loading-spinner" />}
        {speedDials && !loading && (
          <SpeedDials
            items={speedDials}
            onSortEnd={handleSortEnd}
            onPress={handlePress}
            onRemovePress={handleRemovePress}
            onEditPress={handleEditPress}
            onAudioCallPress={handleAudioCallPress}
            onVideoCallPress={handleVideoCallPress}
            onAddPress={onAddPress}
            listError={speedDialListError}
          />
        )}

        {/*  Search View */}
        {currentView === SpeedDialView.SEARCHVIEW && (
          <FocusLock group={CALLING_SPEED_DIAL_FOCUS_GROUP}>
            <SpeedDialModal
              onCancel={handleCancel}
              headerText={addHeaderText}
              handleEnterKeyPress={handleEnterKeyPress}
            >
              <WebexSpeedDialSearch
                items={contacts}
                onAdd={handleAddNew}
                onPress={handleAddExisting}
                searchInputRef={searchInputRef}
                headerText={addHeaderText}
                theme={theme}
              />
            </SpeedDialModal>
          </FocusLock>
        )}
        {/*  Add View */}
        {currentView === SpeedDialView.ADDVIEW && (
          <FocusLock group={CALLING_SPEED_DIAL_FOCUS_GROUP}>
            <div
              aria-modal="true"
              role="dialog"
              aria-label={t("webex.calling") + ", " + addHeaderText}
            >
              <SpeedDialModal
                onCancel={handleCancel}
                headerText={addHeaderText}
                handleEnterKeyPress={handleEnterKeyPress}
              >
                <SpeedDialForm
                  addText={t("modal.add.label")}
                  cancelText={t("modal.cancel.label")}
                  data={selectedItem}
                  onCancel={handleCancel}
                  onSubmit={handleSubmit}
                  searchText={searchText}
                  handleEnterKeyPress={handleEnterKeyPress}
                  headerText={addHeaderText}
                />
              </SpeedDialModal>
            </div>
          </FocusLock>
        )}
        {/*  SearchAddView View */}
        {currentView === SpeedDialView.SEARCHADDVIEW && (
          <FocusLock group={CALLING_SPEED_DIAL_FOCUS_GROUP}>
            <div
              aria-modal="true"
              role="dialog"
              aria-label={t("webex.calling") + ", " + addHeaderText}
            >
              <SpeedDialModal
                onCancel={handleCancel}
                headerText={addHeaderText}
                handleEnterKeyPress={handleEnterKeyPress}
              >
                <SpeedDialForm
                  addText={t("modal.add.label")}
                  cancelText={t("modal.cancel.label")}
                  onSubmit={handleSubmit}
                  onCancel={handleCancel}
                  data={selectedItem}
                  isContact={selectedItem?.currentCallAddress !== null}
                  usedPhonesList={usedInPhoneTypes(
                    selectedItem?.contactId as string
                  )}
                  inUseText={t("inUse.label")}
                  inUsePlaceHolder={t("inUse.placeholder")}
                  handleEnterKeyPress={handleEnterKeyPress}
                  headerText={addHeaderText}
                />
              </SpeedDialModal>
            </div>
          </FocusLock>
        )}
        {/*  Edit View */}
        {currentView === SpeedDialView.EDITVIEW && (
          <FocusLock group={CALLING_SPEED_DIAL_FOCUS_GROUP}>
            <div
              aria-modal="true"
              role="dialog"
              aria-label={t("webex.calling") + ", " + editHeaderText}
            >
              <SpeedDialModal
                onCancel={handleCancel}
                headerText={editHeaderText}
                isEdit={selectedItem?.currentCallAddress !== null}
              >
                <SpeedDialForm
                  addText={t("modal.edit.label")}
                  cancelText={t("modal.cancel.label")}
                  isEdit={selectedItem?.currentCallAddress !== null}
                  onSubmit={handleSubmit}
                  onCancel={handleCancel}
                  data={speedDials[selectedIndex]}
                  headerText={editHeaderText}
                />
              </SpeedDialModal>
            </div>
          </FocusLock>
        )}
        {/*  Error View */}
        {currentView === SpeedDialView.ERRORVIEW && (
          <FocusLock group={CALLING_SPEED_DIAL_FOCUS_GROUP}>
            <div aria-modal="true" role="alert">
              <SpeedDialErrorModal
                onCancel={handleCancel}
                errorNotification={errorText}
                removeSdFailureStorageEvents={removeSdFailureStorageEvents}
                refresh={refresh}
              ></SpeedDialErrorModal>
            </div>
          </FocusLock>
        )}
      </div>
    );
  }
);

WebexSpeedDials.defaultProps = {
  contacts: undefined,
  onSortEnd: undefined,
};
