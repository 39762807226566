import { IWebexIntContact } from '@webex/component-adapter-interfaces/dist/esm/src';
import { IChannelData } from '../packages/widgets/src/utils/CallBackUrl'
import { WebexMobileDialpad } from '../packages/widgets/src';
import './MobileLandingPage.scss';
import { useTranslation } from 'react-i18next';
import { pages } from '@microsoft/teams-js';
import { getMSTeamsContext } from '../ms-teams-sdk/util/MsTeamsContextUtil';
import { entityId, subPageId } from '../packages/widgets/src/utils/commonUtils';

/**
 * Main landing page
 *
 * @returns {JSX.Element} React element containing the tab pag
 */
export const MobileLandingPage = ({src, user, channelData, clientType}:{src: string, user?: IWebexIntContact[], channelData?: IChannelData, clientType: string}): JSX.Element => {
    const { t } = useTranslation();
    let context: any;
    const fetchMsTeamsContext = async () => {
        context = await getMSTeamsContext();
    };
    fetchMsTeamsContext();
    if (context?.page?.subPageId && context.page.subPageId === subPageId) {
        pages.navigateToApp({
            appId : (process.env.INTEGRATION_APP_ID ? process.env.INTEGRATION_APP_ID : ''),
            pageId : entityId
        })
    }
    return (
        <div className="mobileLanding-page">
        {/* <header className="mobileLanding-page__header">
            <div className='mobileLanding-page__topbar'>
                <p>{t('mobile.banner')}</p>
            </div>
        </header> */}
        <section className="mobileLanding-page__content">
        <WebexMobileDialpad src={src} user={user} channelData = {channelData} clientType = {clientType}></WebexMobileDialpad>
        </section>
        </div>
    );
};
