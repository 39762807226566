import { AxiosError } from 'axios';
import axiosRetry, { exponentialDelay } from 'axios-retry';
import { Logger } from '../../packages/logger/src/Logger';
import { AClientBuilder } from './ClientBuilder';
import { addCrossLaunchAuthorizationInterceptor } from './Interceptors';

/**
 *
 * @param error
 */
function retryCondition(error: AxiosError): boolean {
  // ! linter was causing this function to be <any>, wrapped in inline if
  const needRetry = !!(
    error.code !== 'ECONNABORTED' &&
    error.response &&
    error.response.status === 429
  );
  if (needRetry) {
    Logger.info('WebexClient.retryCondition(): need to retry.');
  }
  return needRetry;
}

export default class WebexClientBuilder extends AClientBuilder {
  constructor() {
    super(`${process.env.JABBER_INTEGRATION_URL}/webex`);
  }

  addRetryConfiguration(): void {
    axiosRetry(this.client, {
      retries: 3,
      retryDelay: exponentialDelay,
      retryCondition,
    });
  }

  addAuthorizationInterceptor(): void {
    this.client.interceptors.request.use(
      addCrossLaunchAuthorizationInterceptor
    );
  }
}
