import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Logger, concise, formatError } from '../../packages/logger/src/Logger';
import {
  getCrossLaunchToken, getMSTeamsToken
} from '../service/MsTeamsAuthenticationService';

/**
 *
 * @param config
 */
export function requestLoggerInterceptor(
  config: AxiosRequestConfig
): AxiosRequestConfig {
  Logger.info(`Start to ${config.method} ${config.baseURL}${config.url}.`);
  return config;
}

/**
 *
 * @param response
 */
export function responseLoggerInterceptor(
  response: AxiosResponse<any, any>
): AxiosResponse<any, any> {
  Logger.info(
    `End to ${response.config.method} ${response.config.baseURL}${response.config.url}.`
  );
  return response;
}

/**
 *
 * @param error
 */
export function responseErrorLoggerInterceptor(error: any): any {
  if (error.config) {
    Logger.error(
      `Fail to ${error.config.method} '${error.config.baseURL}${error.config.url}'. Reason: ${error.message}`
    );
  } else {
    Logger.error(formatError(error));
  }
  // return Promise.reject(error);
  return error;
}

export async function addGraphAuthorizationInterceptor(
  config: AxiosRequestConfig
): Promise<AxiosRequestConfig> {
  try {
    const accessToken = await getMSTeamsToken(false);
    // const accessToken = getLocalStorageItem<string>('msAccessTokenDecrypt')
    // ! This is supposed to be handled by getMSTeamsToken
    if (!accessToken) {
      Logger.error('access token is null');
      return await Promise.reject(new Error('No access token'));
    }
    Logger.info(
      `GraphClient.setAuthorizationHeader(): set Authorization header: 'Bearer ${concise(
        accessToken
      )}'.`
    );
    if (!config.headers) {
      config.headers = {}; // eslint-disable-line
    }
    config.headers.Authorization = `Bearer ${accessToken}`; // eslint-disable-line
    return config;
  } catch (error) {
    Logger.error(
      `GraphClient.setAuthorizationHeader(): got error: '${formatError(
        error
      )}'.`
    );
    // ! This is supposed to be handled by getMSTeamsToken
    // localStorage.clear();
    throw error;
  }
}

export async function addCrossLaunchAuthorizationInterceptor(
  config: AxiosRequestConfig
) {
  // cross launch call token has the same life cycle as access token (Graph API token)
  try {
    const crossLaunchCallToken = await getCrossLaunchToken();
    Logger.info(
      `WebexClient.setAuthorizationHeader(): set Authorization header: 'Bearer ${concise(
        crossLaunchCallToken
      )}'.`
    );
    // ! Headers are option, making sure they exist before setting Authorization Header
    if (!config.headers) {
      config.headers = {}; // eslint-disable-line
    }
    config.headers.Authorization = `Bearer ${crossLaunchCallToken}`; // eslint-disable-line
    return config;
  } catch (error) {
    Logger.error(
      `WebexClient.setAuthorizationHeader(): got error: '${formatError(
        error
      )}'.`
    );
    localStorage.clear();
    throw error;
  }
}

