import {
  ISearchContactsAdapter,
  ISearchContactsAdapterSearchInput,
  ISearchContactsAdapterSearchResponse,
  IWebexIntContact,
} from '@webex/component-adapter-interfaces/dist/esm/src';

export class MsTeamsSearchMessageExtensionAdapter
  implements ISearchContactsAdapter
{
  public chatMembers: IWebexIntContact[] = [];

  getSources(): string[] {
    return ['Channel'];
  }

  search(
    _: ISearchContactsAdapterSearchInput
  ): Promise<ISearchContactsAdapterSearchResponse> {
    const regex = new RegExp(`${_.searchText}`, 'ig');
    const filtered = this.chatMembers.filter((member) =>
      member.name.match(regex)
    );
    return Promise.resolve({
      count: filtered.length,
      items: {
        Channel: filtered,
      },
    });
  }
}
