/**
 * Simple typed wrapper for the localstorage setItem api
 *
 * @param key {string} key to store the value at in local storage
 * @param value {any} value to be stored in local storage
 */
export function setLocalStorageItem(key: string, value: any): void {
  localStorage.setItem(key, JSON.stringify({ value }));
}

export function getLocalStorageItem<T>(key: string): T;
export function getLocalStorageItem<T>(key: string, otherwise: T): T;
/**
 * Simple typed wrapper for the localstorage getItem api
 *
 * @template T
 * @param key {string} key to store the value at in local storage
 * @param otherwise {T} optional default value to get back if the local storage value is undefined
 * @returns {T} value form the key-value pair in local storage or the default value
 */
export function getLocalStorageItem<T>(key: string, otherwise?: T): T | null {
  const data: string | null = localStorage.getItem(key);

  if (data) {
    return JSON.parse(data).value;
  }
  if (otherwise) {
    return otherwise;
  }
  return null;
}

/**
 *
 * @param key
 */
export function removeLocalStorageItem(key: string): void {
  localStorage.removeItem(key);
}
