import React, { useCallback, useEffect, useRef, useState } from 'react';
import { MAKE_A_CALL_KEYS } from '../../../adapter-interfaces/src';
import { getLocalStorageItem } from '../../../logger/src';
import { CallButtons } from '../components//CallButtons';
import { NumberPad } from '../components/NumberPad';
import { useMakeCall } from '../hooks/useMakeCall';
import useWebexClasses from '../hooks/useWebexClasses';
import { dialpadLabel } from '../utils/MetricUtils';
import {
  WebexSearchContacts,
  WebexSearchContactsHandle
} from '../WebexSearchContacts/WebexSearchContacts';
import './WebexDialpad.scss';
type Props = {
  style?: React.CSSProperties;
  theme?:string;
};

/**
 * Full dialpad with searchable input, number pad, and calling buttons
 *
 * @param root0
 * @param root0.style
 * @returns {JSX.Element} React component
 */
export const WebexDialpad = ({ style = undefined, theme }: Props) => {
  const [cssClasses, sc] = useWebexClasses('dialpad-widget');
  const searchContactsRef = useRef<WebexSearchContactsHandle>(null);
  const [callAddress, setCallAddress] = useState<string>('');
  const [callDisabled, setCallDisabled] = useState<boolean>(false);
  const [makeACallToken, setMakeACallToken] = useState<string>('');
  const [makeCall] = useMakeCall();
  const onButtonPress = (value: string) => {
    searchContactsRef.current?.appendValueToInput(value);
  };

  const onInputChange = useCallback(
    (input: string) => {
      setCallDisabled(false);
      setCallAddress(input);
    },
    [setCallDisabled, setCallAddress]
  );

  useEffect(() => {
    const handleLocalStorageChange = (event: StorageEvent) => {
      const stringValue = getLocalStorageItem<string>(MAKE_A_CALL_KEYS.MAKE_A_CALL_TOKEN);
      if (stringValue) {
        setMakeACallToken(stringValue);
      } else {
        setMakeACallToken('');
      }
    };
    window.addEventListener('storage', handleLocalStorageChange);

    return () => {
      window.removeEventListener('storage', handleLocalStorageChange);
    };
  }, []);

  return (
    <div className={cssClasses} style={style}>
      <WebexSearchContacts
        ref={searchContactsRef}
        onInputChange={onInputChange}
        onDropdownHide={() => setCallDisabled(false)}
        onUserSelect={(user) => setCallDisabled(!!user)}
        theme={theme}
      />

      <NumberPad onButtonPress={onButtonPress} />
      <CallButtons
        address={callAddress}
        className={sc('call-buttons')}
        disabled={callDisabled}
        useMakeAudioCall={() => makeCall(callAddress, false, dialpadLabel)}
        useMakeVideoCall={() => makeCall(callAddress, true, dialpadLabel)}
      />
    </div>
  );
};
