import {
	IAdapterContext,
	WebexCallHistoryAdapter,
	WebexCallSettingsAdapter,
	WebexVoicemailAdapter
} from '../packages/widgets/src';
import {
	MsTeamsMakeCallAdapter
} from './MsTeamsMakeCallAdapter';
import {
	MsTeamsSearchContactsAdapter
} from './MsTeamsSearchContactsAdapter';
import {
	MsTeamsSpeedDialAdapter
} from './MsTeamsSpeedDialAdapter';

export const MsTeamsSDKAdapter: IAdapterContext = {
	searchContactsAdapter: new MsTeamsSearchContactsAdapter(),
	callHistoryAdapter: new WebexCallHistoryAdapter(),
	makeCallAdapter: new MsTeamsMakeCallAdapter(),
	voicemailAdapter: new WebexVoicemailAdapter(),
	speedDialsAdapter: new MsTeamsSpeedDialAdapter(),
	callForwardAdapter: new WebexCallSettingsAdapter(),
};