export const navigateToWebexUrl = `https://web.webex.com/spaces`;
export const navigateToWebexDesktopUrl = `webex://`;
export const downloadWebexUrl = 'https://www.webex.com/downloads.html';
export const downloadWebexIos = 'https://apps.apple.com/us/app/webex/id833967564';
export const downloadWebexAndroid = 'https://play.google.com/store/apps/details?id=com.cisco.wx2.android';

export const MS_AUTH_RESPONSE = 'MsAuthResponse';
export const WEBEX_AUTH_RESPONSE = 'WebexAuthResponse';

export const CLIENT_TYPE_IOS = 'ios';
export const CLIENT_TYPE_IPADOS = 'ipados';
export const CLIENT_TYPE_ANDROID = 'android';
export const CLIENT_TYPE_TEAMSROOMSANDROID = 'teamsRoomsAndroid';

export const entityId = 'webexCallTab';
export const subPageId = 'mobileDialpad';
