/* eslint-disable camelcase */
import { extractTextBetweenDoubleDashes, processArray } from '../../ms-teams-sdk/util/MsTeamsContextUtil';
import { setLocalStorageItem } from '../../packages/logger/src/LocalStorage';
import { Logger } from '../../packages/logger/src/Logger';
import { SD_ERROR_LIST_MSG, SD_FAILURE_NOTICATION, SD_NOTIFICATION, SD_SUCCESS_NOTICATION } from "../../packages/widgets/src/utils/MetricUtils";
import { ClientBuilderFactory } from '../remote/ClientBuilderFactory';
import { GraphURL } from '../util/URLUtils';
export interface IGraphExtension {
  '@odata.context': string;
  '@odata.type': string;
  id: string;
  extensionName: string;
  cisco_value: string;
}

export enum ExtensionIDs {
  CISCO_EXTENSION_FIRST = 'CISCO_EXTENSION_FIRST',
  CISCO_EXTENSION_SECOND = 'CISCO_EXTENSION_SECOND',
  // Remove later: earlier these extension were added so need to delete
  CISCO_EXTENSION_THIRD = 'CISCO_EXTENSION_THIRD',
  CISCO_EXTENSION_FOURTH = 'CISCO_EXTENSION_FOURTH'
}

export async function init() {
    return Promise.all([
        // Remove later: earlier these extension were added so need to delete
        deleteIfExists(ExtensionIDs.CISCO_EXTENSION_THIRD),
        deleteIfExists(ExtensionIDs.CISCO_EXTENSION_FOURTH)]).then(function(values) {
        return Promise.all([
            createIfNotExists(ExtensionIDs.CISCO_EXTENSION_FIRST),
            createIfNotExists(ExtensionIDs.CISCO_EXTENSION_SECOND)]);
    });
}

export function needInit(error: any) {
    return error.response && error.response.status && error.response.status == 404;
}

async function getExtensionById(extensionId: string): Promise<IGraphExtension> {
    const graphClient = await ClientBuilderFactory.getGraphClient();
    const response = await graphClient.get(`${GraphURL.Extensions}/${extensionId}`);
    if (response.status === 200) {
        return response.data as IGraphExtension;
    } else {
        setLocalStorageItem(SD_ERROR_LIST_MSG, true);
        window.dispatchEvent(new Event("storage"));
        return Promise.reject(response);
    }
}

async function getAndInitExtentionById(extensionId: string): Promise<IGraphExtension> {
    return getExtensionById(extensionId).catch(function(error: any) {
		if (needInit(error)) {
			return init().then(function() {
				return getExtensionById(extensionId);
			});
		}
		return Promise.reject(error);
	});
}
// Check if org has admin consent 
export async function checkAdminConsent(): Promise<Boolean> {
    const graphClient = await ClientBuilderFactory.getGraphClient();
    const response = await graphClient.get(`${GraphURL.Extensions}/${ExtensionIDs.CISCO_EXTENSION_FIRST}`).catch((error) => {
        return error?.response;
});
    if (response?.response?.status === 403) {
        return false;
    } else {
        return true;
    } 
}

/**
 *
 */
export async function getExtensionFirst(): Promise<IGraphExtension> {
  return getAndInitExtentionById(ExtensionIDs.CISCO_EXTENSION_FIRST);
}

/**
 *
 */
export async function getExtensionSecond(): Promise<IGraphExtension> {
  return getAndInitExtentionById(ExtensionIDs.CISCO_EXTENSION_SECOND);
}

async function updateCiscoExtensionById(
  extensionId: string,
  cisco_value: string,
  notificationType: string
): Promise<void> {
  const graphClient = await ClientBuilderFactory.getGraphClient();
  const response =  await graphClient.patch(`${GraphURL.Extensions}/${extensionId}`, {
    cisco_value,
  });
  if (response.status === 204) {
        notificationSuccess(notificationType);
        return;
    } else {
        notificationFailure(notificationType);
        return Promise.reject(response);
    }
}

async function updateAndCiscoExtensionById(
    extensionId: string,
    cisco_value: string,
    notificationType: string
): Promise<void> {
    return updateCiscoExtensionById(extensionId, cisco_value, notificationType).catch(function (error) {
        if (needInit(error)) {
            return init().then(function () {
                return updateCiscoExtensionById(extensionId, cisco_value, notificationType);
            });
        }
        return Promise.reject(error);
    });
}

export async function updateCiscoExtensionFirst(
  cisco_value: string,
  notificationType: string
): Promise<void> {
  await updateCiscoExtensionById(
    ExtensionIDs.CISCO_EXTENSION_FIRST,
    cisco_value,
    notificationType
  );
}

export async function updateCiscoExtensionSecond(
  cisco_value: string,
  notificationType: string
): Promise<void> {
  await updateCiscoExtensionById(
    ExtensionIDs.CISCO_EXTENSION_SECOND,
    cisco_value,
    notificationType
  );
}

async function create(extensionId: string) {
    const graphClient = await ClientBuilderFactory.getGraphClient();
    const data = {
        "@odata.type": "microsoft.graph.openTypeExtension",
        extensionName: extensionId,
        "cisco_value": JSON.stringify([])
    };
    return await graphClient.post(`${GraphURL.Extensions}`, data).then(function (response) {
        return "";
    }).catch(function (error: any) {
        Logger.error(
            `MSTeamsExtensionService.create error: ${JSON.stringify(error)}`
          );
    })
}

async function exists(extensionId: string) {
    return getExtensionById(extensionId).then(function () {
        return true;
    }).catch(function (error: any) {
        if (error.response.status == 404) {
            return false;
        }
        return Promise.reject(error);
    });
}

async function createIfNotExists(extensionId: string) {
  return exists(extensionId).then(function(exists: any) {
      if (!exists) {
          return create(extensionId);
      }
  });
}

export function fromRaw(rawValue: any) {
    // custom speed dial
    if (rawValue.startsWith("{")) {
        let parsedRawData = JSON.parse(rawValue);
        if(!parsedRawData.phoneType) {
            const emailPattern = new RegExp(/[a-z\d._%+-]+@[a-z\d.-]+\.[a-z]{2,4}$/);
            if(emailPattern.test(parsedRawData.currentCallAddress)) {
                parsedRawData.phoneType = "mail";
            } else {
                parsedRawData.phoneType = "work";
            }
        }
        if(parsedRawData.displayName) {
            const names: string[] = parsedRawData.displayName.split(/\s+/);
            const isNameContainsComma = names?.some(element => element.startsWith(','));
            if(isNameContainsComma) {
                const [array1, array2] = processArray(names);
                parsedRawData.givenName = array1.join(' ');
                parsedRawData.surname = array2.join(' ');
            }
            else {
                if(parsedRawData.displayName.match(' ')) {
                    parsedRawData.givenName = parsedRawData.displayName.substring(0, parsedRawData.displayName.lastIndexOf(" ")).trim();
                    parsedRawData.surname = parsedRawData.displayName.split(' ').pop().trim();
                } else {
                    parsedRawData.givenName = parsedRawData.displayName;
                    parsedRawData.surname = '';
                }
            }
        } 
        return parsedRawData;
    }

    // --mo stands for mobile, --wo stands for business phone, --ma stands for mail
    for (let type of ["work", "mobile", "mail", "other", "sip", "home"]) {
    // --ha stands for handset, --vi stands for video
        for (let callType of ["handset", "video"]) {
            if (rawValue.startsWith(callType.substring(0, 2) + "--") && rawValue.endsWith("--" + type.substring(0, 2))) {
                const id = extractTextBetweenDoubleDashes(rawValue);
                return { contactId: id, phoneType: type , callType: callType };
            }
        }
        if(rawValue.endsWith("--" + type.substring(0, 2))) {
            return { contactId: rawValue.substring(0, rawValue.length - 4), phoneType: type };
        }
    }
    // handle legacy data which doesn't have address type, use business phone by default.
    return { contactId: rawValue, phoneType: "work", callType: "handset" };
}

export function toRaw(value: any) {
    // { id: xxx, phoneType: "work" } => xxx--wo (except id starts with 'local_')
    // { displayName: xxx, primaryCallAddress: yyy} => "{ displayName: xxx, primaryCallAddress: yyy}"
    value.phoneType = value.phoneType == 'Email' || value.phoneType == 'email'? 'mail' : value.phoneType;
    value.callType = value.callType === undefined ? value.callType = 'handset' : value.callType;
    return value.contactId
        ? value.callType.substring(0, 2) + "--" + value.contactId + "--" + value.phoneType.substring(0, 2)
        : JSON.stringify(removeEmpty(value));
}

async function deleteIfExists(extensionId: string) {
  return exists(extensionId).then(function(exists) {
      if (exists) {
          return deleteExtension(extensionId);
      }
  });
}

async function deleteExtension(extensionId: string) {
  const graphClient = await ClientBuilderFactory.getGraphClient();
  const url = `${GraphURL.Extensions}/${extensionId}`;
  return await graphClient.delete(url);
}

function removeEmpty(obj: any) {
    const newObj:any = {};
    Object.keys(obj).forEach(function(k) {
        if (obj[k] && typeof obj[k] === "object") {
            const value = removeEmpty(obj[k]);
            if (Object.keys(value).length != 0) {
                newObj[k] = removeEmpty(obj[k]);
            }
        } else if (obj[k] != null && obj[k] != undefined) {
            newObj[k] = obj[k];
        }
    });
    const { id, displayName, callType, phoneType, currentCallAddress } = newObj;
    const extractedObj = { id, displayName, callType, phoneType, currentCallAddress };
    return extractedObj;
}

function notificationSuccess(notificationType: string) {
    switch(notificationType){
        case SD_NOTIFICATION.SD_ADDED:
        setLocalStorageItem(SD_SUCCESS_NOTICATION.SD_SUCCESS_ADD, true);
        window.dispatchEvent(new Event("storage"));
        break;
        case SD_NOTIFICATION.SD_DELETED:
        setLocalStorageItem(SD_SUCCESS_NOTICATION.SD_SUCCESS_DELETE, true);
        window.dispatchEvent(new Event("storage"));
        break;
        case SD_NOTIFICATION.SD_EDITED:
        setLocalStorageItem(SD_SUCCESS_NOTICATION.SD_SUCCESS_EDIT, true);
        window.dispatchEvent(new Event("storage"));
        break;
        case SD_NOTIFICATION.SD_REORDERED:
        setLocalStorageItem(SD_SUCCESS_NOTICATION.SD_SUCCESS_REORDER, true);
        window.dispatchEvent(new Event("storage"));
        break;
    }
}

    function notificationFailure(notificationType: string) {
        switch(notificationType){
            case SD_NOTIFICATION.SD_ADDED:
            setLocalStorageItem(SD_FAILURE_NOTICATION.SD_FAILURE_ADD, true);
            window.dispatchEvent(new Event("storage"));
            break;
            case SD_NOTIFICATION.SD_DELETED:
            setLocalStorageItem(SD_FAILURE_NOTICATION.SD_FAILURE_DELETE, true);
            window.dispatchEvent(new Event("storage"));
            break;
            case SD_NOTIFICATION.SD_EDITED:
            setLocalStorageItem(SD_FAILURE_NOTICATION.SD_FAILURE_EDIT, true);
            window.dispatchEvent(new Event("storage"));
            break;
            case SD_NOTIFICATION.SD_REORDERED:
            setLocalStorageItem(SD_FAILURE_NOTICATION.SD_FAILURE_REORDER, true);
            window.dispatchEvent(new Event("storage"));
            break;
        }
}