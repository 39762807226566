import {
	MicrosoftTeamsSDK
} from '../ms-teams-sdk';
import {
	transformGraphUser
} from '../ms-teams-sdk/util/MsTeamsGraphUserUtil';
import {
	ISearchContactsAdapter,
	ISearchContactsAdapterSearchInput,
	ISearchContactsAdapterSearchResponse,
	IWebexIntContact
} from '../packages/adapter-interfaces/src';
import { IGraphUser } from '../ms-teams-sdk/service/MsTeamsUserService';

enum SourceNames {
	OUTLOOK = 'outlook contacts',
		DIRECTORY = 'directory contacts',
}

enum SourceTypes {
	OUTLOOK = 'outlook',
		DIRECTORY = 'directory',
}

export class MsTeamsSearchContactsAdapter implements ISearchContactsAdapter {
	getSources(): string[] {
		return [SourceNames.DIRECTORY, SourceNames.OUTLOOK];
	}

    getSourceType(contactId: string): SourceTypes {
        // use id length to detect if the contact is directory contact or outlook contact
        return contactId.length == 36 ? SourceTypes.DIRECTORY : SourceTypes.OUTLOOK;
    }

	async search(
		criteria: ISearchContactsAdapterSearchInput
	): Promise < ISearchContactsAdapterSearchResponse > {
		const [directoryUsers, contacts] = await Promise.all([
			MicrosoftTeamsSDK.user.fetchDirectoryUsers(criteria.searchText),
			MicrosoftTeamsSDK.contact.fetchContacts(criteria.searchText),
		]);
		return {
			count: directoryUsers?.length + contacts?.length,
			items: {
				[SourceNames.DIRECTORY]: directoryUsers.map((graphUser) =>
					transformGraphUser(graphUser, () =>
						MicrosoftTeamsSDK.user.fetchDirectoryUserAvatar(graphUser.id)
					)
				),
				[SourceNames.OUTLOOK]: contacts.map((graphUser) =>
					transformGraphUser(graphUser, () =>
						MicrosoftTeamsSDK.contact.fetchContactAvatar(graphUser.id)
					)
				),
			},
		};
	}

	async getOutlookContact(contactId: string): Promise<IWebexIntContact | void> {
		const graphUser = MicrosoftTeamsSDK.contact.getContact(contactId);
		if (graphUser != undefined) {
			return graphUser.then((graphUser) => {
				if (graphUser != undefined) {
					return transformGraphUser(graphUser, () =>
						MicrosoftTeamsSDK.contact.fetchContactAvatar(graphUser.id)
					)
				}
			}
			);

		}
	}

    async getDirectoryContact(userId: string): Promise<IWebexIntContact | void>  {
        const graphUser = MicrosoftTeamsSDK.user.getUserByID(userId);
        if (graphUser != undefined) {
            return graphUser.then((graphUser) =>
                transformGraphUser(graphUser, () =>
                    MicrosoftTeamsSDK.user.fetchDirectoryUserAvatar(graphUser.id)
                ));
        }
    }

    async getContactDetailById(userId: string): Promise<IWebexIntContact | void>  {
        if (this.getSourceType(userId) == SourceTypes.OUTLOOK) {
            return this.getOutlookContact(userId);
        }
        return this.getDirectoryContact(userId);
    }

	async getUsersByIds(userIds: string[]): Promise<IWebexIntContact[]> {
		const [directoryIds, outlookIds] = userIds.reduce(
			([dirIds, outIds], id) => {
				const type = this.getSourceType(id);
				return type === SourceTypes.DIRECTORY
					? [[...dirIds, id], outIds]
					: [dirIds, [...outIds, id]];
			},
			[[], []] as [string[], string[]]
		);

		const [contactUsers, directoryUsers] = await Promise.all([
			this.getFilterOutLookContacts(outlookIds),
			this.getDirectoryContacts(directoryIds),
		]);

		const bothOutlookandDirectoryUsers = [...contactUsers, ...directoryUsers].filter(Boolean);

		return bothOutlookandDirectoryUsers;

	}

	async getDirectoryContacts(userIds: string[]): Promise<IWebexIntContact[]> {
		const users = await MicrosoftTeamsSDK.user.getUsersByIds(userIds);
		return users.map((graphUser) =>
			transformGraphUser(graphUser, () =>
				MicrosoftTeamsSDK.user.fetchDirectoryUserAvatar(graphUser.id)
			)
		);
	}

	async getFilterOutLookContacts(outLookIds: string[]): Promise<IWebexIntContact[]> {
		const finalList: IWebexIntContact[] = [];
		if (outLookIds.length > 0) {
			// Load all contacts
			const allContacts = await MicrosoftTeamsSDK.contact.listAllContacts();
			const userMap: Record<string, IGraphUser> = {};

			// Create a map of contacts by their id
			for (const contact of allContacts) {
				userMap[contact.id] = contact;
			}

			for (const contactId of outLookIds) {
				if (userMap[contactId]) {
					const contact = userMap[contactId];
					const transformedContact = transformGraphUser(
						contact,
						async () => await MicrosoftTeamsSDK.contact.fetchContactAvatar(contact.id)
					);
					finalList.push(transformedContact);
				} else {
					const outlookContact = await this.getOutlookContact(contactId);
					if (outlookContact) {
						finalList.push(outlookContact);
					}
				}
			}
		}

		return finalList;

	}
}