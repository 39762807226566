/**
 *
 * @param error
 */
export function isTokenExpired(error: any) {
  try {
    return error.response.data.error.code === 'InvalidAuthenticationToken';
  } catch (e) {
    return false;
  }
}

/**
 *
 * @param error
 */
export function isTokenRevoked(error: any) {
  try {
    return error && error.type && error.reason === 'Unauthorized';
  } catch (e) {
    return false;
  }
}
