import { ISearchContactsAdapterSearchResponse } from '@webex/component-adapter-interfaces/dist/esm/src';
import { useCallback, useContext, useState } from 'react';
import { getMSTeamsContext } from '../../../logger/src/WebexContext';
import { AdapterContext } from '../contexts/AdapterContext';
import { useDebouncedEffect } from './useDebouncedEffect';
// import { Logger } from '@webex-int/logger';

export enum StatusCode{
  SUCCESS = 200
 }
/**
 * Handles the searching of contacts, relaying the react state
 *
 * @param {string} searchText text to search for in contacts
 * @returns {[ISearchContactsAdapterSearchResponse, boolean]} array containing search response and loading boolean respectively
 */
export const useContactSearch: (
  _: string
) => [string[], ISearchContactsAdapterSearchResponse, boolean] = (
  searchText: string
) => {
    const [items, setItems] = useState<ISearchContactsAdapterSearchResponse>({
      items: {},
      count: 0,
    });
    const [searching, setSearching] = useState<boolean>(false);
    const ctx = useContext(AdapterContext);
    const sources = ctx?.searchContactsAdapter?.getSources() || [];

    const getMSuserId = async () => {
      const context = await getMSTeamsContext();
      const userId = context.user?.id;
      return userId;
    }

    const searchContacts = useCallback(
      async (searchTextValue: string) => {
        if (!ctx?.searchContactsAdapter) {
          console.error('context does not contain field searchContactsAdapter');
          return;
        }
        setSearching(true);
        try {
          // Added condition to stop searching the empty field
          if (searchTextValue) {
            const data = await ctx.searchContactsAdapter.search({
              searchText: searchTextValue,
            });
                        
            const msuserID = await getMSuserId();

            const respItems = data?.items;
            let respCount = 0;
            sources.map(source => {
              respItems[source] = respItems[source]?.filter(res => res?.id !== msuserID);
              respCount = respCount + respItems[source].length;
            })
            data.items = respItems;
            data.count = respCount;
            
            setItems(data);
          }
        } catch (e) {
          // Logger.error(
          //   `Contatc search error case: '${e}'.`
          // );
        } finally {
          setSearching(false);
        }
      },
      [ctx?.searchContactsAdapter]
    );

    useDebouncedEffect(
      () => searchContacts(searchText),
      [searchText, searchContacts],
      StatusCode.SUCCESS,
      () => setSearching(true)
    );

    return [sources, items, searching];
  }; 